import React, { useState } from 'react';

import Label from '@components/inputs/text/Label';
import { UserFormStates, useUserJourneyContext } from '@context/UserJourneyContext';
import { countrySpecificCurrency} from '@utils/misc/functions';
import { useNavigate } from 'react-router';
import InfoBox from '@components/cards/messageBox/InfoBox';
import Button from '@components/buttons/Button/Button';
import { NavBar } from '@components/navigation/NavBar/NavBar';
import SubscriptionService from '@services/subscription/SubscriptionService';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import CircularSpinner from "@components/spinners/Circular/Circular";
import Warning from '@components/modal/info/Warning';

/**
 * View intended to be integrated into the Cancellation form.
 * 
 * All inputs are stored into the corresponding section of the Cancellation context.
 * 
 * All inputs default values are taken from the context.
 */
export default function CancellationReview() {

    const { formState, updateFormState } = useUserJourneyContext();
    const { driverDetails, cancellationDetails, personalInfo, addressInfo, carInfo, contractDetails } = formState;
    const msalContext = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const navigate = useNavigate();

    const renderChargeSection = (label: any, value: any) => (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>
                <Label text={label} styleCfg={{ bold: true }} />
            </div>
            <p style={{ margin: 0 }} >{value || ''}</p>
        </div>
    );

    const buttonContainerStyle = {
        marginTop: '5vh',
    };

    const onClickClose = () => {
        navigate(`/customer/${driverDetails.country}/${cancellationDetails.contactId}/overview`)
    }

    const CreditForUnusedDatesInfoBox = () => (
        <div style={{ margin: '1.5vh 3.5vw' }}>
            <InfoBox message='Credit for unused days will be refunded to customer if applicable in due course' />
        </div>
    );

    const AdditionalChargesInfoBox = () => (
        <div style={{ margin: '1.5vh 3.5vw' }}>
            <InfoBox message='Additional charges for excess Kms and infringements may be charged subsequently if applicable' />
        </div>
    );

    const uploadCancellationInfo = async () => {

        setIsUploading(true)

        const payload = {
            contact_id: cancellationDetails.contactId,
            plan: cancellationDetails.cancellationPlanType,

            cancellation_type: cancellationDetails.cancellationType,
            cancellation_category: cancellationDetails.cancellationCategory,
            cancellation_sub_category: cancellationDetails.cancellationSubCategory,

            customer_returning_date: cancellationDetails.customerReturnDate,
            cancellation_add_restriction_to_account: cancellationDetails.cancellationAddRestrictionToAccount,

            cancellation_additional_info: cancellationDetails.cancellationAdditionalInfo,

            cancellation_date: cancellationDetails.cancellationDate,

            first_name: personalInfo.firstName,
            middle_name: personalInfo.middleName,
            last_name: personalInfo.lastName,
            email: personalInfo.email,
            street: addressInfo.street,
            city: addressInfo.city,
            province: addressInfo.state,
            postcode: addressInfo.postcode,

            vehicle_id: carInfo.vehicleId,
            fleetio_status_id: cancellationDetails.fleetioStatusId,
            odometer: cancellationDetails.endingKms,

            addons: cancellationDetails.addons,
            notice_charges: cancellationDetails.noticePeriodCharge,
            term_charges: cancellationDetails.totalAcceptanceRemarketingFee,
            acceptance_and_remarketing_fee: cancellationDetails.acceptanceAndRemarketing,
            registration: carInfo.registrationPlate,
            total_ancillary_charges: cancellationDetails.totalAncillaryCharges,
            vehicle_depreciation_fee: cancellationDetails.vehicleDepreciationFee,
            total_charges: cancellationDetails.totalCancellationCharges,
            ph_charges: cancellationDetails.phCharges,

            subscription_id: contractDetails.subscriptionId,
            fleetio_status: cancellationDetails.fleetioStatusName,

            hub: cancellationDetails.hub,
        }

        const data =  await SubscriptionService.putCancellationDetails(
            contractDetails.subscriptionId,
            payload,
            driverDetails.country,
            { msalContext, isAuthenticated },
        );

        if (data) {
            updateFormState(UserFormStates.cancellationDetails,
                { fleetioUrl: data.fleetioLink, zohoUrl: data.zohoLink, chargebeeUrl: data.chargebeeURL })
            navigate(`/cancellation/${driverDetails.country}/${contractDetails.subscriptionId}/confirmation`);
        }
        else {
            setIsUploading(false);
            setError(true)
        }
        return
    };

    const renderUploading = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', margin: '15vh' }}>
                <CircularSpinner />
            </div>
        );
    };

    const renderReview = () => {
        return (<>
            <p>Please review ensuring all key details are correct ahead of processing cancellation</p>

        <div style={{ margin: '2.5vh 0vw' }}>
            {renderChargeSection(`First name`, personalInfo.firstName)}
            {renderChargeSection(`Last name`, personalInfo.lastName)}
            {renderChargeSection(`Email`, personalInfo.email)}
            {renderChargeSection(`Address`, addressInfo.street + ', ' + addressInfo.city + ', ' + addressInfo.postcode)}
        </div>

        <div style={{ margin: '2.5vh 0vw' }}>
            {renderChargeSection(`Make`, carInfo.make)}
            {renderChargeSection(`Model`, carInfo.model)}
            {renderChargeSection(`Year`, carInfo.year)}
            {renderChargeSection(`Colour`, carInfo.colour)}
            {renderChargeSection(`Registration plate`, carInfo.registrationPlate)}
            {renderChargeSection(`VIN`, carInfo.vin)}
            {renderChargeSection(`Body type`, carInfo.bodyType)}
        </div>

                <div style={{margin: '2.5vh 0vw'}}>
                    {renderChargeSection('Cancellation Type', cancellationDetails.cancellationType)}
                    {renderChargeSection(`Cancellation Reason - Outcome`, cancellationDetails.cancellationCategory)}
                    {renderChargeSection(`Cancellation Reason - Reason`, cancellationDetails.cancellationSubCategory)}
                    <div>
                        <Label text={"Additional Comments"} styleCfg={{bold: true}}/>
                    </div>
                    <div style={{overflowWrap: 'break-word'}}>
                        <p style={{margin: 0}}>{cancellationDetails.cancellationAdditionalInfo || ''}</p>
                    </div>
                    {renderChargeSection(`Customer returning date`, cancellationDetails.customerReturnDate || "N/A")}
                    {renderChargeSection(`Add restriction to account`, cancellationDetails.cancellationAddRestrictionToAccount || "N/A")}

                    {renderChargeSection(`Notice given`, cancellationDetails.noticeGiven || "N/A")}
                    {renderChargeSection(`Cancellation date`, cancellationDetails.cancellationDate)}
                </div>
                <div style={{margin: '2.5vh 0vw'}}>
                    {renderChargeSection(`Total ancillary fee (${countrySpecificCurrency(driverDetails.country)})`, cancellationDetails.totalAncillaryCharges || "0")}
            {renderChargeSection(`Payment holiday charge (${countrySpecificCurrency(driverDetails.country)})`, cancellationDetails.phCharges || "0")}
            {renderChargeSection(`Notice period charge (${countrySpecificCurrency(driverDetails.country)})`, cancellationDetails.noticePeriodCharge || "0")}
            {renderChargeSection(`Minimum period charge (${countrySpecificCurrency(driverDetails.country)})`, cancellationDetails.totalAcceptanceRemarketingFee || "0")}
            {renderChargeSection(`Total due today (${countrySpecificCurrency(driverDetails.country)})`, cancellationDetails.totalCancellationCharges || "0")}
        </div>

        <CreditForUnusedDatesInfoBox />

        <AdditionalChargesInfoBox />

        <div style={buttonContainerStyle}>
            <Button label={"Submit"} onClickFunc={uploadCancellationInfo}></Button>
        </div>
    </>
        );
    };

    return (<>
        <div style={{ margin: '2.5vh 0vw' }} >
            <NavBar pageTitle="Review" />
        </div>

        {isUploading ? renderUploading() : renderReview()}

        {error && 
            <Warning 
            message= 'Something went wrong'
            additionalInfo='We’re sorry, we were unable to process your request. Please try again later and if the issue persists, please log a ticket with IT Helpdesk.' 
            onClickClose={() => onClickClose()}/>}

        </>
    )
};
