import React, { CSSProperties, ChangeEvent, useEffect, useState } from 'react';
import DropDownMenu, { IChoice } from '@components/inputs/DropDownMenu';
import Label from '@components/inputs/text/Label';
import { UserFormStates, useUserJourneyContext } from '@context/UserJourneyContext';
import { TableRow } from '@components/Tables/addAncillaryTable';
import Button from '@components/buttons/Button/Button';
import InfoModalContainer from '@components/modal/Container'

interface IDropDownModal {
  onClose: () => void;
  options: IChoice[];
  onSelection: (selectedOptions: TableRow) => void; // Updated to accept an array of TableRow
}

const DropDownModal: React.FC<IDropDownModal> = ({ onClose, options, onSelection }) => {

  const { formState, updateFormState } = useUserJourneyContext();

  const [showQuantityDropdown, setShowQuantityDropdown] = useState<boolean>(true);
  const [disableEditableAddonPrice, setDisableEditableAddonPrice] = useState<boolean>(true);

  const formatOptions = (options: any[]): IChoice[] => {
    return options.map((option) => ({
      value: option.price,
      label: option.name,
      enabled: true,
    }));
  };

  const formattedOptions = formatOptions(options);

  function getPricingModel(selectedText: string, addons: any) {
    for (const option of addons) {
      if (option.name === selectedText) {
        return option.pricingModel;
      }
    }
    return null;
  }

  const handleItemSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    //cannot update Chargebee with item quanitities for addons with "flat_fee" pricing model
    const selectedText = event.target.options[event.target.selectedIndex].text;
    const selectedValue = parseFloat(event.target.value);
    const addons = options
    // If addon has fixed price then set value and display value then allow user to edit value
    if (getPricingModel(selectedText, addons) === "flat_fee") {
      setShowQuantityDropdown(false)
      updateFormState(UserFormStates.cancellationDetails, { addonId: selectedText });
      updateFormState(UserFormStates.cancellationDetails, { addonPrice: selectedValue });
      updateFormState(UserFormStates.cancellationDetails, { addonQuantity: 1 });
      setDisableEditableAddonPrice(false);
    }
    // Else only display the value and do not allow the field to be editable
    else {
      setShowQuantityDropdown(true)
      updateFormState(UserFormStates.cancellationDetails, { addonId: selectedText });
      updateFormState(UserFormStates.cancellationDetails, { addonPrice: selectedValue });
      setDisableEditableAddonPrice(true)
    }
    // If addon has a unit price of 0 then allow CSR to edit price
    if (selectedValue == 0) {
      setDisableEditableAddonPrice(false)
    }
  };

  const handleQuantitySelect = (event: ChangeEvent<HTMLSelectElement>) => {
    updateFormState(UserFormStates.cancellationDetails, { addonQuantity: parseInt(event.target.value) })
  };

  const formatSelectedOptions = () => {

    const updatedOptions: TableRow = {
      item: formState.cancellationDetails.addonId,
      quantity: formState.cancellationDetails.addonQuantity,
      unit: formState.cancellationDetails.addonPrice,
      total: (formState.cancellationDetails.addonPrice * formState.cancellationDetails.addonQuantity) || 0, // Use '0' as the default value if selectedOption or value is undefined
    };
    return updatedOptions
  }

  // This function uses the onSelection callback to update the table in the CancellationCharges page with the selected option from the dropdown.
  // After the option is selected and passed to the table the context is wiped and the modal is closed.
  const handleSubmit = () => {
    onSelection(formatSelectedOptions());
    updateFormState(UserFormStates.cancellationDetails, { addonId: "" })
    updateFormState(UserFormStates.cancellationDetails, { addonPrice: 0 })
    updateFormState(UserFormStates.cancellationDetails, { addonQuantity: 0 })
    onClose();
  };

  function handleAddonPriceChange(event: ChangeEvent<HTMLInputElement>): void {
    let newAddonPrice = Number(event.target.value)
    updateFormState(UserFormStates.cancellationDetails, { addonPrice: newAddonPrice })
  }

  const unitPriceInputStyles: CSSProperties = {
    height: '2.5vh',
    border: '1px solid #C4C4C4',
    borderRadius: '10px',
    minWidth: '50%',
};

  return (
    <InfoModalContainer>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
          <h3 style={{ color: '#800080', margin: '0', textAlign: 'center', flex: '1' }}>Add ancillary charges</h3>
          <button onClick={onClose} style={{ border: 'none', background: 'none', cursor: 'pointer', fontSize: '20px' }}>X</button>
        </div>

        <table style={{ width: '100%', marginTop: '2.5vh', textAlign: 'left' }}>
          <tbody>
            <tr style={{ height: '2.5vw' }}>
              <td>
                <Label text="Item" />
              </td>
              <td>
                <DropDownMenu
                  menuName="dropdown1"
                  testId="dropdown1"
                  defaultVal={undefined}
                  required={true}
                  height="2.5vh"
                  choices={formattedOptions}
                  onSelect={(event: any) => handleItemSelect(event)}
                />
              </td>
            </tr>

            {showQuantityDropdown &&
              <tr style={{ height: '2.5vw' }}>
                <td>
                  <Label text="Quantity" />
                </td>
                <td>
                  <DropDownMenu
                    menuName="dropdown2"
                    testId="dropdown2"
                    defaultVal={undefined}
                    required={true}
                    height="2.5vh"
                    choices={Array.from({ length: 100 }, (_, index) => ({ value: String(index + 1), label: String(index + 1), enabled: true }))}
                    onSelect={(event: any) => handleQuantitySelect(event)}
                  />
                </td>
              </tr>
            }

            <tr style={{ height: '2.5vw' }}>
              <td>
                <Label text="Unit price ($)" />
              </td>
              <td>
                <input
                  style={unitPriceInputStyles}
                  type="number"
                  required
                  value={formState.cancellationDetails.addonPrice}
                  onChange={handleAddonPriceChange}
                  data-testid="addonPriceInput"
                  disabled= {disableEditableAddonPrice} //only allow user to edit if allowed
                ></input>
              </td>
            </tr>
          </tbody>
        </table>
        <div style={{ margin: '2.5vh 0vw' }}>
          <Button label="Add charge" testId="add-charge-button" onClickFunc={handleSubmit} />
        </div>
      </div>
    </InfoModalContainer>
  );
};

export default DropDownModal;
