import React, { ChangeEvent, CSSProperties, useEffect, useState } from 'react';

import { UserFormStates, useUserJourneyContext } from '@context/UserJourneyContext';
import { PanelItem } from '@components/panels/PanelItems';
import { IPlan, PlanService } from '@services/SubscriptionPlans';
import { ContactOwnersService } from '@services/ContactOwners';
import { addNextYearsWeeks, countrySpecificCurrency, countrySpecificDistanceUnit, dateToISOFormat, getTimeDifference } from '@utils/misc/functions';
import Label from '@components/inputs/text/Label';
import DatePicker from '@components/inputs/DateInput';
import DropDownMenu, { IChoice } from '@components/inputs/DropDownMenu';
import useServiceHook from '@hooks/useServiceHook';
import Elliptical from '@components/spinners/Elliptical/Elliptical';
import { textBoxStyle } from '@utils/styles/textboxStyle';
import { BreadcrumbProps } from 'SmartOpsHome/breadcrumbObject';
import { Country } from '@utils/constants/localisation';

export default function FlexiAgreementSchedule({ breadcrumbObject }: BreadcrumbProps) {
    const todaysDate = dateToISOFormat(new Date());  // YYYY-mm-dd
    const dropDownPleaseSelect = { label: '- please select -', value: '', enabled: false };

    const { formState, updateFormState } = useUserJourneyContext();
    const { driverDetails, contractDetails, carInfo, splendInitiatives } = formState;
    const defaultSetupFee = driverDetails.country == Country.AU ? "275" : "199"
    const [setupFee, setSetupFee] = useState<string>(contractDetails.planSetupFee != null ? contractDetails.planSetupFee : defaultSetupFee);
    const [executionDate, setExecutionDate] = useState<string>(contractDetails.executionDate || todaysDate);
    const [subsStartDate, setSubsStartDate] = useState<string>(contractDetails.subsStartDate || todaysDate);
    const [additionalDriverFee, setAdditionalDriverFee] = useState<string>(contractDetails.additionalDriverFee)


    // Set default value for the insurance from context, if there is one.
    const insuranceOptions = [
        { label: 'To be organised by Splend', value: 'Splend', enabled: true },
        { label: 'To be organised by Hirer', value: 'Hirer', enabled: true }
    ];
    const defaultInsurance = insuranceOptions[0]

    const distanceAllowancePerWeekText = driverDetails.country == Country.AU ? "Weekly distance allowance" : "Permitted mileage per week" 

    const additionalMileagChargeText = driverDetails.country == Country.AU ? "Additional km charge" : "Additional mileage charge"

    // If a plan is found in the context, set it as default, being the user's last selection
    let defaultPlan = contractDetails.planId
        ? { label: contractDetails.planName, value: contractDetails.planId, enabled: true }
        : dropDownPleaseSelect;

    const [loading, error, errMessage, availablePlans] = useServiceHook(
        PlanService.getPlans,
        [{ plan_type: driverDetails.plan, state: carInfo.hub, term: 0, weekly_distance_allowance: splendInitiatives.weeklyKilometreAllowance}, driverDetails.country]
    );

    //Retrieves all available zoho users for each country. 
    const [userLoading, , , availableContactOwners] = useServiceHook(
        ContactOwnersService.getContactOwners,
        [driverDetails.country],
        true,
    );

    //Matches only on the name returned from zoho user email as some users log into smart ops with a .com email
    // but have a .com.au email stored in Zoho
    const emailMinusSplendDomain = (email: string) => {
        const emailString = email.toLowerCase()
        if (emailString.includes('splend.com')) {
            let splitEmail = emailString.split('@')[0]
            return splitEmail.replace(/-uk|\.uk/g, ''); 
        }
        return email
    }
    let dropdown = []
    let dropdownDefault: Partial<IChoice | undefined> = undefined
    let dropdownDefaultID: string = ""

    // set default contact owner from availableContactOwner list
    if (availableContactOwners) {
        const defaultContactOwnerIndex = availableContactOwners.findIndex((index:any) => emailMinusSplendDomain(index.email) === emailMinusSplendDomain(contractDetails.contactOwnerEmail))
        const selectedContact = availableContactOwners[defaultContactOwnerIndex]
        dropdownDefaultID = selectedContact.id
        dropdownDefault = {label: selectedContact.label, value: selectedContact.id, enabled: true}
        dropdown = availableContactOwners.map((item: any) => ({
            value: item.id,
            label: item.label,
            enabled: true
        }));
    }

    // TODO: find a way to update cusotmer email on contact owner change. Currently we have no use for it but we should aim to keep values consistent
    // with selected contact owner
    const onContactOwnerChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        updateFormState(UserFormStates.contractDetails, { contactOwner: e.currentTarget.options[e.currentTarget.selectedIndex].label, contactOwnerId: e.currentTarget.value });
    }

    const handleAdditionalDriverInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAdditionalDriverFee(filterForOnlyNumbers(event))
    };

    const handleSetupFeeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSetupFee(filterForOnlyNumbers(event))
    };

    const filterForOnlyNumbers = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        // Remove non-numeric characters
        value = value.replace(/[^0-9]/g, '');
        return value;
    }

    useEffect(() => { // runs only at first page render
        // if no executionDate, take it from state, which should've been set to today
        contractDetails.executionDate === '' &&
            updateFormState(UserFormStates.contractDetails, { executionDate });

        // if no subsStartDate, take it from state, which should've been set to today
        contractDetails.subsStartDate === '' &&
            updateFormState(UserFormStates.contractDetails, { subsStartDate });

        updateFormState(UserFormStates.contractDetails, { planSetupFee: setupFee });
    }, []);

    useEffect(  // update context's subsStartDate field upon state update
        () => updateFormState(UserFormStates.contractDetails, { subsStartDate }),
        [subsStartDate]
    );

    useEffect(  // update context's executionDate field upon state update
        () => updateFormState(UserFormStates.contractDetails, { executionDate }),
        [executionDate]
    );

    useEffect(  // update context's planSetupFee when user types new value for setup fee
        () => updateFormState(UserFormStates.contractDetails, { additionalDriverFee: additionalDriverFee }),
        [additionalDriverFee]
    );

    useEffect(  // update context's planSetupFee when user types new value for setup fee
        () => updateFormState(UserFormStates.contractDetails, { planSetupFee: setupFee }),
        [setupFee]
    );

    useEffect( // save default insurance to context 
        () => updateFormState(UserFormStates.contractDetails, { insuranceCover: defaultInsurance.value }),
        []
    );

    const onPlanChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const selectedPlan = availablePlans.filter((item: IPlan) => item.id === e.target.value)[0];
        if (contractDetails.contactOwnerId === ""){
            updateFormState(UserFormStates.contractDetails, {contactOwnerId: dropdownDefaultID})
            }
        updateFormState(
            UserFormStates.contractDetails,
            {
                planId: selectedPlan.id,
                planName: selectedPlan.name,
                planWeeklyFee: selectedPlan.weeklyHireFee.toFixed(2),
                // planSetupFee can be reset via state update below
                planMinimumPeriod: selectedPlan.minimumPeriodWeeks,
                planWeeklyDistAllowance: selectedPlan.weeklyDistAllowance,
                planAdditionalDistCharge: selectedPlan.additionalDistCostPerUnit,
            }
        );
        // setSetupFee(defaultSetupFee);  // de-comment if setupFee needs to be reset at every plan change
    };

    useEffect(() => {
        breadcrumbObject['Agreement schedule']['Execution Date'] = contractDetails.executionDate
        breadcrumbObject['Agreement schedule']['Subscription Start Date'] = contractDetails.subsStartDate
        breadcrumbObject['Agreement schedule']['Plan Length'] = contractDetails.planLen.toString()
        breadcrumbObject['Agreement schedule']['Chargebee Plan'] = contractDetails.planName
        breadcrumbObject['Agreement schedule']['Total Time Spent on Journey'] = getTimeDifference(breadcrumbObject['Vehicle assignment']['Customer Started Journey'], new Date())
    }, [contractDetails])

    const divStyles: CSSProperties = {
        margin: '2.5vh 0vw',
    };

    return (<>
        <div style={divStyles}>
        <p style={{ margin: '2.5vh 0' }}>Please ensure the key terms for the customer are correct.</p>

            <Label text='Contact Owner/Authorised Signatory' requiredFieldInd={true} />
            {userLoading === false || userLoading === null
                ? <DropDownMenu
                    menuName={'contactOwnerDropMenu'}
                    defaultVal={dropdownDefault}
                    required={true}
                    choices={dropdown}
                    onSelect={(event: ChangeEvent<HTMLSelectElement>) => onContactOwnerChange(event)}
                />
                : <Elliptical />
            }
        </div>
        <div style={divStyles}>
            <Label text='Contract execution date' requiredFieldInd={true} />
            <DatePicker
                name='contractExeDate'
                defaultVal={executionDate}
                min={todaysDate}
                required={true}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => setExecutionDate(e.currentTarget.value)}
            />
        </div>
        <div style={divStyles}>
            <Label text='Subscription start date' requiredFieldInd={true} />
            <DatePicker
                name='contractStartDate'
                defaultVal={subsStartDate}
                min={todaysDate}
                required={true}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => setSubsStartDate(e.currentTarget.value)}
            />
        </div>
        <div style={divStyles} >
            <Label text='Plan' requiredFieldInd={true}/>
            {loading === false || loading === null
                ? <DropDownMenu
                    menuName='planDropMenu'
                    defaultVal={defaultPlan}
                    required={true}
                    choices={availablePlans
                        ? [defaultPlan].concat(availablePlans.map((item: any) => ({ label: item.name, value: item.id, enabled: true })))
                        : [defaultPlan]
                    }
                    onSelect={(e: ChangeEvent<HTMLSelectElement>) => onPlanChange(e)}
                />
                : <div style={{ height: '5vh' }}><Elliptical /></div>
            }
        </div>
        <div style={{ margin: '2.5vh 0vw' }}>
            {splendInitiatives.splendInitiative != "Dynamic Pricing" &&<PanelItem
                header='Minimum period (weeks)' headerTextStyleCfg={{ bold: true }}
                value={contractDetails.planMinimumPeriod || ''} />
            }
            <PanelItem
                header={`Weekly hire fee (${countrySpecificCurrency(driverDetails.country)})`} headerTextStyleCfg={{ bold: true }}
                value={contractDetails.planWeeklyFee || ''} />
            <PanelItem
                header={`${distanceAllowancePerWeekText} (${countrySpecificDistanceUnit(driverDetails.country)})`} headerTextStyleCfg={{ bold: true }}
                value={contractDetails.planWeeklyDistAllowance || ''} />
            <PanelItem
                header={`${additionalMileagChargeText} (${countrySpecificCurrency(driverDetails.country)})`} headerTextStyleCfg={{ bold: true }}
                value={contractDetails.planAdditionalDistCharge || ''} />
        </div>
        <div style={divStyles}>
            <Label text={`Setup fee (${countrySpecificCurrency(driverDetails.country)})`} requiredFieldInd={true} />
            <input
                type='text'
                style={textBoxStyle}
                name='fee'
                value={setupFee}
                required={true}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleSetupFeeInput(e)}
                
            />
        </div>
        <div style={divStyles}>
            <Label text={`Additional driver fee if applicable (${countrySpecificCurrency(driverDetails.country)})`} requiredFieldInd={true} />
            <input
                type='text'
                style={textBoxStyle}
                name='additionalDriverFee'
                value={additionalDriverFee}
                required={true}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleAdditionalDriverInput(e)}
            />
        </div>
        <div style={divStyles}>
            <Label text='Comprehensive car insurance' />
            <DropDownMenu
                menuName='planLenDropMenu'
                defaultVal={defaultInsurance}
                required={true}
                choices={insuranceOptions}
                onSelect={(e: ChangeEvent<HTMLSelectElement>) =>
                    updateFormState(UserFormStates.contractDetails, { insuranceCover: e.currentTarget.value })}
            />
        </div>
    </>);

};

