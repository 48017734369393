// This refresh checker (which also applies to any action including leaving the page) is only used to display a dialog box and receive the user input and is not
// attached to any hooks or futher action. The way this is currently utilised is that the component (currently 
// used only for the onboarding form) is responsible for creating the hook and the condition for which the hook
// should run.

// Example steps for using this to perform a redirect on refreshing a page are as follows:
// To use this functionality import the event listeners into the desired files
// Create a hook which uses a value which should be present to continue with the form
// If the value does not exist then redirect back to the homepage

const handleBeforeUnload = (event: any) => {
    // Custom logic to handle the refresh
    // Display a confirmation message or perform necessary actions
    const confirmationMessage = 'Are you sure you want to leave?';
    event.returnValue = confirmationMessage;
    return confirmationMessage;
  };
  
  export const addBeforeUnloadListener = () => {
    window.addEventListener('beforeunload', handleBeforeUnload);
  };
  
  export const removeBeforeUnloadListener = () => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
  };