import { getWithIDToken, IMSTokenParams } from "@utils/http/MSAuthAPI";

// We can use alternateHost to point to localhost for testing
//const alternateHost = 'http://localhost:5000';
const alternateHost = '';

interface IUserAccessService {
    paymentHolidayUserAccess(IDTokenParams: IMSTokenParams): Promise<boolean>
}

const UserAccessService: IUserAccessService = {
    async paymentHolidayUserAccess(IDTokenParams: IMSTokenParams): Promise<boolean> {
        let userAccess: any = sessionStorage.getItem('paymentholidayuseraccess')
        if (userAccess === null) {
            console.log('getting user access from API')

            const resp = await getWithIDToken(
                `${alternateHost}/v1/user-access/payment-holiday`,
                IDTokenParams
            );

            userAccess = resp.data;
            sessionStorage.setItem('paymentholidayuseraccess', JSON.stringify(userAccess))
        }
        else {
            console.log('getting user access from sessionStorage')
            userAccess = JSON.parse(userAccess);
        }

        return userAccess
    }
}

export default UserAccessService;