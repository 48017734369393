// enums.ts
export enum PlanOptions {
    SplendFlexiOwn = 'Splend Flexi-Own',
    SplendFlexi = 'Splend Flexi',
    SplendRentToOwnUsedVehicle = 'Splend Rent to Own - Used Vehicle',
    RAVCreditHire = 'RAV Credit Hire',
    RAVSharecover = 'RAV Sharecover',
    RAVShortTermRental = 'RAV Short Term Rental',
    CancellationShareCover = 'RAV Share Cover',
    CancellationFlexiOwn = 'Flexi Own',

    UKFlexiOwn = 'Flexi-own',
    UKFlexi = 'Splend Rent',
    UKRentToOwnUsed = 'Pre-Loved Flexi-Own'
}

export enum splendInitiativesOptions {
    DynamicPricing = 'Dynamic Pricing'
}