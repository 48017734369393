import React, { useEffect, useState } from 'react';
import removeIcon from '@assets/images/removeButton.png';
import { countrySpecificCurrency } from '@utils/misc/functions';
import { useUserJourneyContext } from '@context/UserJourneyContext';

export interface TableRow {
    item: string;
    quantity: number;
    unit: number;
    total: number;
}

interface DynamicTableProps {
    data: TableRow[];
    onRemoveRow: (index: number) => void;
}

const DynamicTable: React.FC<DynamicTableProps> = ({ data, onRemoveRow }) => {
    const [tableData, setTableData] = useState<TableRow[]>(data);
    const { formState } = useUserJourneyContext();
    const { driverDetails } = formState;

    useEffect(() => {
        setTableData(data);
    }, [data]);


    const handleRemoveRow = (index: number, event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
        event.preventDefault();
        const updatedData = [...tableData];
        updatedData.splice(index, 1);
        setTableData(updatedData);
        onRemoveRow(index);  // Call the onRemoveRow function provided in props
      };

    const isTableEmpty = () => {
        return tableData.length === 0;
    };


    return (
        <>
            {!isTableEmpty() && (
                <table style={{ width: '100%', margin: '2.5vw 0', textAlign: 'center' }}>
                    <thead>
                        <tr>
                            <th>Item</th>
                            <th>Quantity</th>
                            <th>Unit({countrySpecificCurrency(driverDetails.country)})</th>
                            <th>Total({countrySpecificCurrency(driverDetails.country)})</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((row: TableRow, index: number) => (
                            <tr key={index}>
                                <td>{row.item}</td>
                                <td>{row.quantity}</td>
                                <td>{row.unit}</td>
                                <td>{row.total.toFixed(2)}</td>
                                <td>
                                    <img
                                        src={removeIcon}
                                        alt="Remove"
                                        onClick={(event) => handleRemoveRow(index, event)}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </>
    );
};


export default DynamicTable;
