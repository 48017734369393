import React, { CSSProperties } from 'react';
import { PanelItem, IPanelItem } from './PanelItems';


export interface ISubscriptionDetailPanel {
    rentalAgreement: string
    startDate: string
    registration: string
    plan: string
    termLength?: number | string | React.ReactNode
    make: string
    model: string
    email: string
    noticeGiven?: string
    testId?: string
};


export const SubscriptionDetailsPanel = (props: ISubscriptionDetailPanel) => {
    const subscriptionInfo: IPanelItem[] = [
        { header: 'Subscription name', headerTextStyleCfg: { bold: true }, value: props.rentalAgreement },
        { header: 'Start date', headerTextStyleCfg: { bold: true }, value: props.startDate },
        { header: 'Registration', headerTextStyleCfg: { bold: true }, value: props.registration },
        { header: 'Plan', headerTextStyleCfg: { bold: true }, value: props.plan },
        { header: 'Term length', headerTextStyleCfg: { bold: true }, value: props.termLength },
        { header: 'Make', headerTextStyleCfg: { bold: true }, value: props.make },
        { header: 'Model', headerTextStyleCfg: { bold: true }, value: props.model },
        { header: 'Email', headerTextStyleCfg: { bold: true }, value: props.email },
    ];

    const style: CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        padding: '3.5vh 2vw',
    };

    return (
        <div style={style} data-testid={props.testId || 'subscriptionInfoPanel'}>
            {subscriptionInfo.map((itemProps, index) => <PanelItem key={`infoPanelItem-${index}`} {...itemProps} />)}
        </div>
    )
};