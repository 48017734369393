import React from "react";


interface ILabelTextStyleCfg {
    largeFont?: boolean
    bold?: boolean
};


interface ILabel {
    text: string,
    requiredFieldInd?: boolean,
    styleCfg?: ILabelTextStyleCfg
    testId?: string
};


export default function Label({ text, requiredFieldInd, styleCfg, testId }: ILabel) {

    const styles = {
        fontSize: styleCfg?.largeFont ? '20px' : '18px',
        fontWeight: styleCfg?.bold ? 'bold' : 'normal',
    };

    return (<>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <label style={styles} data-testid={testId || 'label-test-id'}>
                {text}
            </label>
            {requiredFieldInd && <label style={{ ...styles, color: '#FF0000' }}>*</label>}
        </div>
    </>
    );
};
