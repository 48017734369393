import React from 'react';
import { useParams, useNavigate } from "react-router-dom";

import SimpleMenu from "@components/navigation/SimpleMenu/SimpleMenu";

export default function UpdateCustomerDetailsMenu() {
    const { custId, country } = useParams() as { custId: string; country: string; };
    const navigate = useNavigate();

    return (
        <SimpleMenu
            name='update-customer-details-menu'
            menuItems={[
                {
                    label: 'Update personal details',
                    onClickFunc: () => console.log('Go to vehicle exchange'),
                    isDisabled: true
                },
                {
                    label: 'Add/update additional driver',
                    onClickFunc: () => navigate(`/update-customer-details/${country}/${custId}`),
                    isDisabled: country === "GB" ? true : false
                },
            ]}
        />
    )
};
