import React, { CSSProperties, MouseEventHandler } from "react";


interface IUnderlinedBtn {
    text: string
    onClickFn?: MouseEventHandler
};


export default ({ text, onClickFn }: IUnderlinedBtn) => {
    const styles: CSSProperties = {
        cursor: 'pointer',
        color: '#6F217B',
        backgroundColor: 'transparent',
        textDecoration: 'underline',
        border: 'none',
        padding: '0',
    };

    return (
        <button style={styles} onClick={onClickFn}>
            {text}
        </button>
    );
};
