import React, { ChangeEvent, CSSProperties, useEffect, useState } from 'react';
import { UserFormStates, useUserJourneyContext } from '@context/UserJourneyContext';
import { ContactOwnersService } from '@services/ContactOwners';
import { checkIfValidFleetioOdometerUpdate, dateToISOFormat, emailMinusSplendDomain } from '@utils/misc/functions';
import Label from '@components/inputs/text/Label';
import DatePicker from '@components/inputs/DateInput';
import DropDownMenu, { IChoice } from '@components/inputs/DropDownMenu';
import useServiceHook from '@hooks/useServiceHook';
import Elliptical from '@components/spinners/Elliptical/Elliptical';
import { BreadcrumbProps } from 'SmartOpsHome/breadcrumbObject';
import { NavBar } from '@components/navigation/NavBar/NavBar';
import Button from '@components/buttons/Button/Button';
import { useNavigate, useParams } from 'react-router';
import { OnboardingService } from '@services/journeys/Onboarding';
import { Country } from '@utils/constants/localisation';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useAPIError } from '@context/ServerErrorContext';
import { removeBeforeUnloadListener } from '@utils/refreshCatcher';
import CircularSpinner from "@components/spinners/Circular/Circular";
import ModalContainer from '@components/modal/Container';
import WarningModal from '@components/modal/WarningModal';
import { SubscriptionService } from '@services/index';
import { VehicleService } from '@services/Vehicles/Vehicles';

export default function TestDriveEndDetails() {
    const todaysDate = dateToISOFormat(new Date());  // YYYY-mm-dd

    const { formState, updateFormState } = useUserJourneyContext();
    const { driverDetails, contractDetails, testDriveDetails, addressInfo, personalInfo, carInfo } = formState;
    const [contractExecutionDate, setContractExecutionDate] = useState<string>(todaysDate);
    const [loading, setLoading] = useState<Boolean>(false);
    const [canSubmit, setCanSubmit] = useState<Boolean>(true)
    const navigate = useNavigate();

    const msalContext = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const { setErr, removeErr } = useAPIError();

    const generateSetTimeSlots = (): IChoice[] => {
        const timeSlots: IChoice[] = [];
        for (let hour = 7; hour <= 22; hour++) {
          for (let minute of [0, 30]) {
            const hourStr = hour < 10 ? `0${hour}` : `${hour}`;
            const minuteStr = minute === 0 ? '00' : '30';
            const label = `${hourStr}:${minuteStr}`;
            const value = `${hourStr}:${minuteStr}`;
            timeSlots.push({ value, label, enabled: true });
          }
        }
        return timeSlots;
      };
      
    const timeSlots: IChoice[] = generateSetTimeSlots();

    const [currentTimeSlot, setCurrentTimeSlot] = useState<IChoice>(timeSlots[0]);

    // I thought of having these as common reusable function however there are no other places in the codebase we currently use them so 
    // it was decided we would leave them in the component. The times were chosen based on realistic times and were not a part of any
    // business requirements. If used again then would recommend making a utils function
    const getCurrentTimeSlot = (): IChoice => {
        const now = new Date();
        const currentHour = now.getHours();
        const currentMinute = now.getMinutes();
        const currentTimeStr = `${currentHour < 10 ? `0${currentHour}` : `${currentHour}`}:${currentMinute === 0 ? '00' : '30'}`;

        // Find the nearest time slot
        let nearestTimeSlot: IChoice | null = null;
        let minDifference: number | null = null;

        for (const slot of timeSlots) {
            const slotHour = parseInt(slot.value.split(':')[0]);
            const slotMinute = parseInt(slot.value.split(':')[1]);
            const difference = Math.abs(currentHour - slotHour) * 60 + Math.abs(currentMinute - slotMinute);

            if (minDifference === null || difference < minDifference) {
                minDifference = difference;
                nearestTimeSlot = slot;
            }
        }

        // If nearestTimeSlot is still null, return the first time slot as fallback
        return nearestTimeSlot || timeSlots[0];
    };

    useEffect(() => {
        setCurrentTimeSlot(getCurrentTimeSlot());
    }, []);

    //Retrieves all available zoho users for each country. 
    const [userLoading, , , availableContactOwners] = useServiceHook(
        ContactOwnersService.getContactOwners,
        [driverDetails.country],
        true,
    );

    

    let dropdown = []
    let dropdownDefault: Partial<IChoice | undefined> = undefined
    let dropdownDefaultID: string = ""

    // set default contact owner from availableContactOwner list
    if (availableContactOwners) {
        const defaultContactOwnerIndex = availableContactOwners.findIndex((index: any) => emailMinusSplendDomain(index.email) === emailMinusSplendDomain(contractDetails.contactOwnerEmail))
        const selectedContact = availableContactOwners[defaultContactOwnerIndex]
        dropdownDefaultID = selectedContact.id
        dropdownDefault = { label: selectedContact.label, value: selectedContact.id, enabled: true }
        dropdown = availableContactOwners.map((item: any) => ({
            value: item.id,
            label: item.label,
            enabled: true
        }));
    }

    useEffect(() => {
        updateFormState(UserFormStates.contractDetails, {contactOwnerId: dropdownDefaultID})
    }, [dropdownDefaultID])

    // TODO: find a way to update customer email on contact owner change. Currently we have no use for it but we should aim to keep values consistent
    // with selected contact owner
    const onContactOwnerChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        updateFormState(UserFormStates.contractDetails, { contactOwner: e.currentTarget.options[e.currentTarget.selectedIndex].label, contactOwnerId: e.currentTarget.value });
    }

    useEffect(() => {
        updateFormState(UserFormStates.contractDetails, { executionDate: contractExecutionDate });
        updateFormState(UserFormStates.testDriveDetails, { timeOut: currentTimeSlot.value });

    }, [contractExecutionDate, currentTimeSlot])

    const onTimeSlotChange = (e: ChangeEvent<HTMLSelectElement>): void => {
        const selectedValue = e.target.value;
        const selectedTimeSlot = timeSlots.find(slot => slot.value === selectedValue);
        if (selectedTimeSlot) {
            updateFormState(UserFormStates.testDriveDetails, { timeBack: selectedTimeSlot.value });
        }
    };

    const convertDateForZohoSign = (date: string) => {
        return new Date(date).toLocaleDateString('en-GB', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        })
    }

    const textBoxStyle: CSSProperties = {
        height: '5vh',
        borderRadius: '5px',
        border: '1px solid #C4C4C4',
        fontSize: '18px',
        justifyContent: 'left',
        margin: '1vh 0vw',
        boxSizing: 'border-box',
        width: '50%',
        left: '28px',
    };

    const divStyles: CSSProperties = {
        margin: '2.5vh 0vw',
    };

    const buttonContainerStyle = {
        marginTop: '5vh',
    };

    const [vehicleDetailsLoading, vehicleError, vehicleErrMsg, vehicleDetails] = useServiceHook(
        VehicleService.getVehicleById,
        [carInfo.id, driverDetails.country]
    );

    useEffect(()=> {
        if (vehicleDetails){
        updateFormState(UserFormStates.carInfo, { odometer: vehicleDetails.current_meter_value });
        }
    }, [vehicleDetails])

    const endTestDrive = async () => {

        setLoading(true)

        const payload = {
            prefill_data: {
                field_text_data: {
                    plan: driverDetails.plan, // needs to be added to context in journey
                    driver_licence_number: driverDetails.driversLicenceNumber,
                    licence_expiry: driverDetails.driversLicenceExpiry,
                    dob: driverDetails.dateOfBirth,
                    last_name: personalInfo.lastName,
                    first_name: personalInfo.firstName,
                    address: addressInfo.street,
                    postcode: addressInfo.postcode,
                    phone: personalInfo.mobile,
                    vehicle: testDriveDetails.vehicle,
                    registration: carInfo.registrationPlate,
                    endTestDrive: contractDetails.executionDate + " " + testDriveDetails.timeOut,
                    email: personalInfo.email,
                    sales_consultant: contractDetails.contactOwner,
                },
                field_boolean_data: {},
                field_date_data:{
                    dob: convertDateForZohoSign(driverDetails.dateOfBirth),
                    licence_expiry: convertDateForZohoSign(driverDetails.driversLicenceExpiry),
                }
            },
            sales_consultant_id: contractDetails.contactOwnerId,
            contact_id: driverDetails.crmId,
            vehicle_id: carInfo.id,
            odometer: carInfo.odometer,
            vehicle: testDriveDetails.vehicle,
            Test_Drive_End: contractDetails.executionDate + " " + testDriveDetails.timeOut,
            potential_id: testDriveDetails.potentialId,
        }

        SubscriptionService.endTestDrive(
            payload,
            testDriveDetails.potentialId,
            testDriveDetails.contactId,
            Country[driverDetails.country],
            { msalContext, isAuthenticated }
        ).then((resp) => {
            updateFormState(UserFormStates.testDriveDetails, { zohoUrl: resp.zohoLink});
            updateFormState(UserFormStates.testDriveDetails, { fleetioUrl: resp.fleetioLink });
            updateFormState(UserFormStates.testDriveDetails, { vehicle: resp.vehicle });
            removeBeforeUnloadListener(); // end event listener at the end of the form
            navigate('../end-booking')
        }).catch((err) => {
            // Display an error modal - by acknowledging the error and clicking Ok btn, the user is sent to homepage
            setErr(
                true,
                {
                    msg: 'Error with onboarding.',
                    UI: <WarningModal message={"Something went wrong"} additionalInfo={`We’re sorry, we were unable to process your request.  
                    We will investigate the issue and in the meantime, please onboard the customer via an alternative method.`} onClickClose={() => { removeErr(); navigate('/'); }} />
                }
            );
            setLoading(false);
        });
    };

    const onEndingKmsChange = (e: any) => {
        const inputValue = e.target.value;
        const minValue = vehicleDetails.current_meter_value;
    
    // Validate if the input value is greater than or equal to the minimum value
    checkIfValidFleetioOdometerUpdate(minValue, inputValue) ? updateFormState(UserFormStates.carInfo, { odometer: inputValue }) : undefined
    setCanSubmit(checkIfValidFleetioOdometerUpdate(minValue, inputValue))
    }

    return (<>

        {loading && <ForegroundLoadingSpinner />}

        <div style={{ margin: '2.5vh 0vw' }} >
            <NavBar pageTitle="Test drive details" />
        </div>
        <div><strong>Step 5</strong></div>
        <div style={divStyles}>
            <p style={{ margin: '2.5vh 0' }}></p>
            <Label text='Sales Consultant' requiredFieldInd={true} />
            {userLoading === false || userLoading === null
                ? <DropDownMenu
                    menuName={'contactOwnerDropMenu'}
                    defaultVal={dropdownDefault}
                    required={true}
                    choices={dropdown}
                    onSelect={(event: ChangeEvent<HTMLSelectElement>) => onContactOwnerChange(event)}
                />
                : <Elliptical />
            }
        </div>

        <div style={divStyles}>
            <Label text='Contract execution date' requiredFieldInd={true} />
            <DatePicker
                name='testDriveStartDate'
                defaultVal={todaysDate}
                min={todaysDate}
                required={true}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => setContractExecutionDate(e.currentTarget.value)}
            />
        </div>

        <div style={divStyles} >
            <Label text='Time back' requiredFieldInd={true} />
            <DropDownMenu
                menuName='timeSlotMenu'
                defaultVal={currentTimeSlot}
                required={true}
                choices={timeSlots}
                onSelect={(e: ChangeEvent<HTMLSelectElement>) => onTimeSlotChange(e)}
            />
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', margin: '2.5vh 0vw' }}>
            <Label text={'Ending kms'} requiredFieldInd={true} />
            {vehicleDetailsLoading === false ? (
            <>
            {canSubmit == false && 
            <p style={{ color: 'red', fontSize: 'smaller' }}>Entered value must be greater than or equal to {vehicleDetails.current_meter_value}</p>}
            <input
                style={textBoxStyle}
                name='endingKms'
                defaultValue={vehicleDetails.current_meter_value}
                min={vehicleDetails.current_meter_value}
                required={true}
                onChange={onEndingKmsChange}
            />
            </>) : (
            <div style={{ height: '5vh' }}>
                <Elliptical />
            </div>
        )}
        </div>

        <div style={buttonContainerStyle}>
            <Button label={"Submit"} onClickFunc={canSubmit ? () => endTestDrive() : undefined}></Button>
        </div>

    </>);

};

const ForegroundLoadingSpinner = () => (
    <ModalContainer stylesCfg={{ windowTransparency: true }} >
        <div style={{ display: 'flex', justifyContent: 'center', margin: '15vh' }}>
            <CircularSpinner />
        </div>
    </ModalContainer>
);