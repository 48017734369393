import React from 'react';

import AddressDetails from '@views/journeys/AddressDetails';
import { NavBar } from '@components/navigation/NavBar/NavBar';
import Button from '@components/buttons/Button/Button';
import { useNavigate } from 'react-router';

export default function TestDriveAddressDetails() {

    const navigate = useNavigate();

    return (<>
        <NavBar pageTitle='Address details'/>
        <AddressDetails />
        <div style={{ margin: '2.5vh 0' }}>
            <Button label={"Next"} onClickFunc={() => navigate(`../licence`)}/>
        </div>

    </>);
};
