import { Country } from '@utils/constants/localisation';
import { IMSTokenParams } from '@utils/http/MSAuthAPI';
import { readFileBase64 } from '@utils/file';


export interface ILicenseFilePayload {
    name: string;
    content: string;

    toJSON(): object;
}

export class LicenseFilePayload implements ILicenseFilePayload {
    name: string;
    content: string;

    constructor(name: string, content: string) {
        this.name = name;
        this.content = content;
    }

    static async fromFile(file: File) {
        const fileContents64 = await readFileBase64(file);

        if (fileContents64) {
            return new LicenseFilePayload(
                file.name,
                fileContents64,
            );
        }
    }

    toJSON(): object {
        return {
            filename: this.name,
            content: this.content,
        };
    }
}

export interface IAdditionalDriverPayload {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    licenseNumber: string;
    licenseState: string;
    licenseExpiry: string;
    contactId: string;
    licenseFront?: LicenseFilePayload;
    licenseBack?: LicenseFilePayload;

    toJSON(): object;
}

export class AdditionalDriverPayload implements IAdditionalDriverPayload {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    licenseNumber: string;
    licenseState: string;
    licenseExpiry: string;
    contactId: string;
    licenseFront?: LicenseFilePayload;
    licenseBack?: LicenseFilePayload;

    constructor(
        firstName: string,
        lastName: string,
        dateOfBirth: string,
        licenseNumber: string,
        licenseState: string,
        licenseExpiry: string,
        contactId: string,
        licenseFront?: LicenseFilePayload,
        licenseBack?: LicenseFilePayload,
    ) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.dateOfBirth = dateOfBirth;
        this.licenseNumber = licenseNumber;
        this.licenseState = licenseState;
        this.licenseExpiry = licenseExpiry;
        this.contactId = contactId;
        this.licenseFront = licenseFront;
        this.licenseBack = licenseBack;
    }

    toJSON(): object {
        return {
            first_name: this.firstName,
            last_name: this.lastName,
            dob: this.dateOfBirth,
            licence_number: this.licenseNumber,
            licence_state: this.licenseState,
            licence_expiry: this.licenseExpiry,
            contact_id: this.contactId,
            files: {
                DLBack: this.licenseBack?.toJSON(),
                DLFront: this.licenseFront?.toJSON(),
            },
        };
    }
}
