import React, { CSSProperties } from 'react';

import { TestDriveSummaryCard, ITestDriveSummaryItem } from '@components/cards/TestDriveSummary/TestDriveSummary';


interface ITestDriveList {
    keyName: string 
    items: ITestDriveSummaryItem[]
}


export default function TestDriveList({ keyName, items }: ITestDriveList) {

    const styles: CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        borderBottom: 'thin solid lightgrey'
    };
    
    return (
        <div style={styles}>
            {items.map((itemProps, index) => (
                <TestDriveSummaryCard key={`${keyName}-${index}`}
                    {...itemProps}
                />
            ))}
        </div>
    );
};
