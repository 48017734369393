import React, { CSSProperties } from "react";


interface IDatePicker {
    name: string
    defaultVal?: string
    required?: boolean
    onChange?: Function
    max?: string
    min?: string
};


export default function DatePicker({ name, defaultVal, max, min, required, onChange }: IDatePicker) {

    const styles: CSSProperties = {
        height: '5vh',
        border: '1px solid #C4C4C4',
        borderRadius: '10px',
        minWidth: '50%',
    };

    return (
        <input
            style={styles}
            type='date'
            required={required}
            name={name}
            defaultValue={defaultVal}
            onChange={(event) => onChange ? onChange(event) : {}}
            max={max}
            min={min}
            data-testid={name}
        />
    );
};


