import React, { CSSProperties } from 'react';

import { SubscriptionSummaryCard, ISubscriptionSummaryItem } from '../../cards/SubscriptionSummary/SubscriptionSummary';


interface ISubscriptionsList {
    keyName: string  // Must be unique from technical reasons
    items: ISubscriptionSummaryItem[]  // maybe it should be a generic SubscriptionCard type, allowing more custom card options
}


export default function SubscriptionsList({ keyName, items }: ISubscriptionsList) {

    const styles: CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        borderBottom: 'thin solid lightgrey'
    };
    
    return (
        <div style={styles}>
            {items.map((itemProps, index) => (
                <SubscriptionSummaryCard key={`${keyName}-${index}`}
                    {...itemProps}
                />
            ))}
        </div>
    );
};
