import React, { MouseEventHandler } from 'react';
import { IPanelItem, PanelItem } from '../PanelItems';
import { WarningBox } from '@components/cards/messageBox';
import UnderlinedBtn from '@components/buttons/UnderlinedBtn';


interface IPaymentHolidayItem {
    id: string
    startDate: string
    noWeeks: string
    deletable: boolean
    onDeleteClick: MouseEventHandler
    warningMsg: string
};


export interface IPaymentHoliday {
    holidays: IPaymentHolidayItem[]
    testId?: string
};


export function PaymentHoliday(props: IPaymentHoliday) {

    const paymentHoliday: IPanelItem[][] = props.holidays.map(item => {
        return [
            { header: 'Start Date', value: item.startDate },
            { header: 'Number of weeks scheduled', value: item.noWeeks }
        ]
    });

    return (<>
        {paymentHoliday.map((panel, index) => (
            <div style={{ paddingTop: '1.5vh' }} key={`paymentHolidayPanel-${index}`}>
                {panel.map((item, innerIndex) => <PanelItem key={`infoPanelItem-${index}-${innerIndex}`} {...item} />)}
                {props.holidays[index].deletable
                    ? <UnderlinedBtn key={`deleteBtn-${index}`} text='Delete' onClickFn={props.holidays[index].onDeleteClick} />
                    : (props.holidays[index].warningMsg
                        && <WarningBox key={`warningBox-${index}`} message={props.holidays[index].warningMsg} />)
                }
            </div>
        ))}
    </>);
};
