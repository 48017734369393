import { Country } from '@utils/constants/localisation';
import { IMSTokenParams, postWithIdToken } from '@utils/http/MSAuthAPI';
import { AdditionalDriverPayload } from './AdditionalDriver.types';

export interface IAdditionalDriverService {
    sendDetails(subscriptionId: string, country: Country, payload: AdditionalDriverPayload, IDTokenParams: IMSTokenParams): Promise<any>;
}

const AdditionalDriverService: IAdditionalDriverService = {
    async sendDetails(subscriptionId: string, country: Country, payload: AdditionalDriverPayload, IDTokenParams: IMSTokenParams): Promise<any> {
        let data = undefined;
        try {
            const resp = await postWithIdToken(
                `v1/subscriptions/${subscriptionId}/additional_driver`,
                IDTokenParams,
                { country },
                { ...payload.toJSON() },
            );
            if (resp.status === 200) {
                data = resp.data
            }
        }
        catch (error) {
            console.log('Error:', error);
        }
        return data;
    },
};

export default AdditionalDriverService;
