import Button from '@components/buttons/Button/Button';
import Checkbox from '@components/inputs/Checkbox';
import {NavBar} from '@components/navigation/NavBar/NavBar';
import {Country} from '@utils/constants/localisation';
import {useUserJourneyContext} from '@context/UserJourneyContext';
import React, {CSSProperties, useState} from 'react';
import {useNavigate, useParams} from 'react-router';
import {ravCreditHirePlanNames, ravShareCoverPlanNames} from "@utils/constants/products";

/**
    Step 1 of Additional Driver Journey. This is meant to show a different message when the selected
    subscription is of type "Credit Hire" hence the conditional rendering in the list.
 */
export default function AdditionalDriverInfo() {

    const { custId, country } = useParams() as { custId: string; country: Country; };

    const [isChecked, setIsChecked] = useState(false);

    const { formState } = useUserJourneyContext();
    const { additionalDriver } = formState;

    const navigate = useNavigate();

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const listStyle: CSSProperties = {
        justifyContent: 'left',
        margin: '2.5vh 2vw'
    };

    const onButtonClick = () => {
        //TO DO: create next component to navigate to onClick
        navigate(`/update-customer-details/${country}/${custId}/details`)
    }

    const confirmCheckboxStyle: CSSProperties = {
        fontWeight: '700'
    };

    return (<>
        <NavBar pageTitle="Additional driver" />
        <div style={{ margin: '2.5vh 2vw' }}>
            <p><strong>Step 1</strong></p>
            <div />
            <div style={listStyle}></div>
            <p>Please note, if the customer would like to add a additional driver, they would have to:</p>

            <li>Learn relevant terms and conditions in our contract </li>

            <li>Confirm that the additional driver will not be driving rideshare</li>

            {ravCreditHirePlanNames.includes(additionalDriver.plan) || ravShareCoverPlanNames.includes(additionalDriver.plan)
                ?
                <li>Know that they will have to pay additional $9 per day fee (Ex GST)</li>
                :
                <li>Know that they will have to pay additional $20 per week fee</li>
            }

            <li>Supply a copy of the driving licence for the additional driver</li>

        </div>
        <div style={{ margin: '2.5vh 2vw' }}>
            <Checkbox
                label="The customer agrees to the above terms"
                onChange={handleCheckboxChange}
                id="confirmed"
                customStyle={confirmCheckboxStyle}    
            />
        </div>

        <div style={{
            bottom: '5vh',
            textAlign: 'center',
            opacity: isChecked ? 1 : 0.5,
            pointerEvents: isChecked ? 'auto' : 'none',
            margin: '2.5vh 2vw'
        }}>
            <Button label='Next' onClickFunc={onButtonClick} disabled={!isChecked}/>
        </div>
    </>
    );
};
