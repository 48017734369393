/**
 * SimpleMenu Component
 * Copyright Splend 2023
 * 
 * The component acts as the main tool to use to create any menu. Use the menuItems
 * property to populate with the props' required for each menu item. That will describe
 * the Icon (if there is one) and the label applied, as well as the where to go on
 * clicking the menu item.
 * 
 * This component ensures each time a menu is created it is homogenous and follows
 * the same style and pattern. Even if multiple menu groups are deployed within a view.
 */
import React, { CSSProperties, MouseEvent, MouseEventHandler } from 'react';
import ArrowRightPurple from '@assets/images/arrow_right.svg';

export type SimpleMenuItemProps = {
    altTag?: string,
    arrowTestId?: string,
    isDisabled?: boolean, // If set, the text colour will be greyed out and the item won't respond to click events
    iconSrc?: string,
    iconTestId?: string,
    label: string,
    labelTestId?: string,
    onClickFunc?: MouseEventHandler<HTMLDivElement>,
    testId?: {[item: string]: string},
};

const SimpleMenuItem = (props: SimpleMenuItemProps) => {
    const { altTag, iconSrc, isDisabled } = props;

    const onItemClick = (event: MouseEvent<HTMLDivElement>) => {
        event.preventDefault();

        if (!isDisabled && props.onClickFunc) {
            props.onClickFunc(event);
        }
    };

    const style: CSSProperties = {
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
        borderBottom: 'thin solid lightgrey',
        cursor: isDisabled ? 'default': 'pointer',
        display: 'inline-flex',
        margin: 0,
        padding: '1vh 2vw',
    };

    const labelStyle: CSSProperties = {
        color: isDisabled ? '#A5A5A5': '#000000',
        flexGrow: 1,
        margin: iconSrc ? '0 2vw': '0.1vh 0.5vw',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        userSelect: 'none',
        whiteSpace: 'nowrap',
    };

    const iconStyle: CSSProperties = {
        margin: '0 0.5vw',
        userSelect: 'none',
    };

    return (
        <div
            style={style}
            onClick={onItemClick}
            data-testid={props.testId || 'menuItem'}
        >
            {iconSrc && <img
                style={iconStyle}
                alt={altTag || 'Menu Icon'}
                src={iconSrc}
                data-testid={props.iconTestId || 'itemIcon'}
            />}
            <p style={labelStyle} data-testid={props.labelTestId || 'label'}>{props.label}</p>
            <img
                style={iconStyle}
                alt={'Right Arrow'}
                src={ArrowRightPurple}
                data-testid={props.arrowTestId || 'arrowIcon'}
            />
        </div>
    );
};

export type SimpleMenuProps = {
    menuItems: SimpleMenuItemProps[], // Collection of metadata to render menu items
    name: string, // Must be unique! Used to key each menu item
    testId?: string,
};

export default (props: SimpleMenuProps) => {
    const style: CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        borderTop: 'thin solid lightgrey', // FIXME: Upon theming, ensure the first child menu item has this
    };

    return (
        <div style={style} data-testid={props.testId || 'menu'}>
            {props.menuItems.map((itemProps, index) => <SimpleMenuItem key={`${props.name}${index}`} {...itemProps} />)}
        </div>
    );
};
