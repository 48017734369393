import React, { useState, useEffect } from "react";
import InfoModalContainer from "../Container";
import ConfirmationBox from "@components/cards/messageBox/ConfirmationBox";
import Button from '@components/buttons/Button/Button';
import { useMsal } from '@azure/msal-react';

import useInterval from "@hooks/useIntervalHook";

interface Props {
  sendValueToParent?: (value: boolean) => void;
}

export default function AutoLogoutWarning({ sendValueToParent }: Props) {
    const msalContext = useMsal();
    const [timer, setTimer] = useState(60);
    const [showWarningValue, setShowWarningValue] = useState(true); // Controls whether to show the warning box

    const logoutAction = async () => {
        // Ensure no interaction is in progress before logging out
        await msalContext.instance.handleRedirectPromise();

        // Local logout only (not directed to logout from MS Auth, server side)
        // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/logout.md
        msalContext.instance.logoutRedirect({
            onRedirectNavigate: (url) => {
                return false;
            }
        });
    };

    const cancelAction = () => {
      setTimer(60);
      setShowWarningValue(false); // Hide the AutoLogoutWarning component
      if (sendValueToParent) {
        sendValueToParent(false);
      }
    };

  const intervalCallback = () => {
      setTimer((prevTimer) => prevTimer - 1);
  };

  // Use the useInterval hook unconditionally
  useInterval(intervalCallback, showWarningValue ? 1000 : null);

    useEffect(() => {
      if (timer === 0) {
          logoutAction();
      }
  }, [timer, showWarningValue]);

    return (
        <>
            {showWarningValue && (
                <InfoModalContainer>
                    <ConfirmationBox message={'Session Timeout'} />
                    <p>{`Your session is about to expire due to inactivity. You will be logged out in ${timer} seconds.`}</p>
                    <Button label="Log out" onClickFunc={logoutAction} />
                    <Button label="Continue" onClickFunc={cancelAction} />
                </InfoModalContainer>
            )}
        </>
    );
};
