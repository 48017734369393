import { getWithIDToken, IMSTokenParams } from "@utils/http/MSAuthAPI";


export default async (
    custId: string,
    country: string,
    IDTokenParams: IMSTokenParams
): Promise<any> => {

    return await getWithIDToken(
        `/v1/customers/${custId}`,
        IDTokenParams,
        { country: country }
    );
};
