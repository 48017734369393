import { Country } from "@utils/constants/localisation";
import { Payload } from "@utils/http/AxiosAPI";
import { IMSTokenParams, getWithIDToken, postWithIdToken } from "@utils/http/MSAuthAPI";
import { PlanPayload } from "@views/journeys/OnboardingFormPayloadFactory";

interface IObtainSignUrl {
    sign_url: string
};

interface IObtainCustomerOverviewUrl {
    url: string
};


export interface IRedisKey {
    make: string
    model: string
    year: string
    licencePlate: string
    fleetioLink: string
    zohoLink: string
    email: string
    missingKey?: boolean
};


interface IOnboardingService {
    obtainPotentialRedirectURL(potential_id: string, country: Country, IDTokenParams: IMSTokenParams): Promise<IObtainCustomerOverviewUrl>
    obtainCustomerSignUrl(payload: Payload | PlanPayload, country: Country, IDTokenParams: IMSTokenParams): Promise<IObtainSignUrl>,
    obtainCSRSignUrl(payload: Payload, country: Country, IDTokenParams: IMSTokenParams): Promise<IObtainSignUrl>,
    postRedisKey(key: string, country: Country, IDTokenParams: IMSTokenParams): Promise<IRedisKey>,
};


export const OnboardingService: IOnboardingService = {

    async obtainPotentialRedirectURL(potentialID: string, country: Country, IDTokenParams: IMSTokenParams): Promise<IObtainCustomerOverviewUrl> {
        const resp = await getWithIDToken(
            `v1/potentials/${potentialID}/contact_url?country=${country}`,
            IDTokenParams,
            { country: country, potential_id: potentialID }
        );
        return resp.data;
    },

    async obtainCustomerSignUrl(payload: Payload | PlanPayload, country: Country, IDTokenParams: IMSTokenParams): Promise<IObtainSignUrl> {
        const resp = await postWithIdToken(
            'v1/signatures/obtain-customer',
            IDTokenParams,
            { country },
            payload
        );
        return resp.data;
    },

    async obtainCSRSignUrl(payload: Payload, country: Country, IDTokenParams: IMSTokenParams): Promise<IObtainSignUrl> {
        const resp = await postWithIdToken(
            'v1/signatures/obtain-csr',
            IDTokenParams,
            { country },
            payload
        );
        return resp.data;
    },

    async postRedisKey(key: string, country: Country, IDTokenParams: IMSTokenParams) {
        const resp = await postWithIdToken(
            'v1/signatures/process',
            IDTokenParams,
            { country, key },
        );

        const processedData = {
            year: resp.data.year,
            make: resp.data.make,
            model: resp.data.model,
            licencePlate: resp.data.license_plate,
            vehicle: resp.data.vehicle,
            fleetioLink: resp.data.fleetio_url,
            zohoLink: resp.data.zoho_url,
            email: resp.data.email,
            missingKey: resp.data.missing_key
        };

        return processedData;
    },
};




