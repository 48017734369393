export interface IChoice {
    value: string
    label?: string
    enabled: boolean
};

const plans: IChoice[] = [
    { label: 'Tesla Model Y', value: '4', enabled: true },
    { label: 'Polestar 2 - Standard Range', value: '4', enabled: true  },
    { label: 'Polestar 2 - Long Range', value: '4', enabled: true  },
    { label: 'MG4 Excite', value: '4', enabled: true  },
    { label: 'Tesla Model 3', value: '4', enabled: true  },
    { label: 'Tesla Model Y', value: '5', enabled: true  },
    { label: 'Polestar 2 - Standard Range', value: '5', enabled: true  },
    { label: 'Polestar 2 - Long Range', value: '5', enabled: true  },
    { label: 'MG4 Excite', value: '5', enabled: true  },
    { label: 'Tesla Model 3', value: '5', enabled: true  },
];

export default plans;