import React, { CSSProperties, useEffect, useState } from 'react';

import DatePicker from '@components/inputs/DateInput';
import DropDownMenu, { IChoice } from '@components/inputs/DropDownMenu';
import Label from '@components/inputs/text/Label';
import { UserFormStates, useUserJourneyContext } from '@context/UserJourneyContext';
import { Country } from '@utils/constants/localisation';
import { BreadcrumbProps } from 'SmartOpsHome/breadcrumbObject';
import { NavBar } from '@components/navigation/NavBar/NavBar';
import Button from '@components/buttons/Button/Button';
import { useNavigate } from 'react-router';

export default function TestDriveLicence() {

    const provinceByCountry: { [key in Country]: string[] } = {
        'GB': ['LDN'],
        'AU': ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'VIC', 'TAS', 'WA']
    };

    const { formState, updateFormState } = useUserJourneyContext();
    const { driverDetails } = formState;
    
    const [minimumAge, setMinimumAge] = useState('')
    const [maxDriverLicence, setMaxDriverLicence] = useState('')

    const dropDownPleaseSelect: IChoice = { label: '- please select -', value: '', enabled: false };

    const currentDate = new Date();

    const navigate = useNavigate();

    const onDriverLicenceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateFormState(UserFormStates.driverDetails, { driversLicenceNumber: e.target.value });
    };
    const onStateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateFormState(UserFormStates.driverDetails, { province: e.target.value });
    };
    const onExpiryDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateFormState(UserFormStates.driverDetails, { driversLicenceExpiry: e.target.value });
    };
    const onDateOfBirthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateFormState(UserFormStates.driverDetails, { dateOfBirth: e.target.value });
    };

    const getDateString = function (date: Date) {
        const year = date.getFullYear().toString();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return (`${year}-${month}-${day}`)
    }

    // The minimum age a driver can be is 23 years old. This applies to both AU & UK
    const getMinimumAge = function () {
        const minDate = new Date(
            currentDate.getFullYear() - 23,
            currentDate.getMonth(),
            currentDate.getDate()
        );
        const date = getDateString(minDate)
        setMinimumAge(date);
    }

    // The maximum length of a driver's licence before it expires is 10 years, 11 is
    // used for safety. Applies to both AU & UK
    const getMaximumDriverLicenceExpiry = function () {
        const maxDate = new Date(
            currentDate.getFullYear() + 11,
            currentDate.getMonth(),
            currentDate.getDate()
        );
        const date = getDateString(maxDate)
        setMaxDriverLicence(date);
    }

    useEffect(() => {
        getMinimumAge();
        getMaximumDriverLicenceExpiry();
    }, []);

    const textBoxStyle: CSSProperties = {
        height: '5vh',
        borderRadius: '5px',
        border: '1px solid #C4C4C4',
        fontSize: '18px',
        justifyContent: 'left',
        margin: '1vh 0vw',
        boxSizing: 'border-box',
        width: '50%',
        left: '28px',
    };

    const buttonContainerStyle = {
        marginTop: '5vh',
    };

    return (<>

        <NavBar pageTitle="Driver licence" />
        
        <div style={{ margin: '2.5vh 0vw' }}>
            <p>Please review the customer’s <strong>driver licence</strong> details, ensuring that each field is populated with the correct data. </p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', margin: '2.5vh 0vw' }}>
            <Label text={'Driver licence number'} requiredFieldInd={true} />
            <input
                style={textBoxStyle}
                name='driverLicenceNumber'
                defaultValue={driverDetails.driversLicenceNumber}
                required={true}
                onChange={onDriverLicenceChange}
            />
        </div>
        <div style={{ margin: '2.5vh 0vw' }}>
            <Label text={driverDetails.country === 'GB' ? 'City' : 'State'} requiredFieldInd={true} />
            <DropDownMenu
                menuName='stateDropMenu'
                defaultVal={
                    provinceByCountry[driverDetails.country]?.includes(driverDetails.province)
                        ? { label: driverDetails.province, value: driverDetails.province }
                        : dropDownPleaseSelect
                }
                required={true}
                choices={[
                    dropDownPleaseSelect,
                    ...(provinceByCountry[driverDetails.country] || []).map((item: any) => ({
                        label: item,
                        value: item,
                        enabled: true,
                    }))
                ]}
                onSelect={onStateChange}
            />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', margin: '2.5vh 0vw', width: '50%' }}>
            <Label text={'Expiry date'} requiredFieldInd={true} />
            <DatePicker
                name='expiryDate'
                max={maxDriverLicence}
                defaultVal={driverDetails.driversLicenceExpiry}
                required={true}
                onChange={onExpiryDateChange} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', margin: '2.5vh 0vw', width: '50%' }}>
            <Label text={'Date of birth'} requiredFieldInd={true} />
            <DatePicker
                name='dateOfBirth'
                defaultVal={driverDetails.dateOfBirth}
                required={true}
                max={minimumAge}
                onChange={onDateOfBirthChange} />
        </div>

        <div style={buttonContainerStyle}>
            <Button label={"Next"} onClickFunc={() => navigate(`../start`)}></Button>
        </div>
    </>
    );
}