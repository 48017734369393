import React, { CSSProperties } from 'react';
import { PanelItem, IPanelItem } from './PanelItems';


export interface ICustomerDetailPanelProps {
    firstName: string,
    lastName: string,
    email: string,
    crmId: string,
    memberStatus: string,
    testId?: string,
};


export default (props: ICustomerDetailPanelProps) => {
    const customerInfo: IPanelItem[] = [
        { header: 'First name', headerTextStyleCfg: { bold: true }, value: props.firstName },
        { header: 'Last name', headerTextStyleCfg: { bold: true }, value: props.lastName },
        { header: 'Email', headerTextStyleCfg: { bold: true }, value: props.email },
        { header: 'CRM ID', headerTextStyleCfg: { bold: true }, value: props.crmId },
        { header: 'Contact status', headerTextStyleCfg: { bold: true }, value: props.memberStatus },
    ];

    const style: CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        padding: '2vh 2vw',
    };

    return (
        <div style={style} data-testid={props.testId || 'customerInfoPanel'}>
            {customerInfo.map((itemProps, index) => <PanelItem key={`infoPanelItem-${index}`} {...itemProps} />)}
        </div>
    )
};
