import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';

import VehicleDetails from "@components/panels/VehicleDetails";
import DropDownMenu, { IChoice } from "@components/inputs/DropDownMenu";
import Label from "@components/inputs/text/Label";
import useServiceHook from "@hooks/useServiceHook";
import Elliptical from "@components/spinners/Elliptical/Elliptical";
import { IVehicle, VehicleService } from "@services/Vehicles/Vehicles";
import { UserFormStates, useUserJourneyContext } from "@context/UserJourneyContext";
import { Country } from "@utils/constants/localisation";
import { NavBar } from "@components/navigation/NavBar/NavBar";
import { hubs } from "@utils/constants/hubs";
import Button from "@components/buttons/Button/Button";

export default function TestDriveVehicleAssignment() {

    const noVehicleDetails: IVehicle = {
        id: '', make: '', model: '', year: '', colour: '',
        license_plate: '', vin: '', body_type: '', odometer: 0,
    };

    // Get existing info available in various places (route, context, etc)
    const { country } = useParams() as { country: Country };
    const { formState, updateFormState, resetFormState } = useUserJourneyContext();
    const { carInfo, driverDetails } = formState;

    // Flag used to prevent service to run via dedicated hook until all necessary parameters are set,
    // this way preventing multiple calls. If info is available through context, then the hook can run.
    const [allowServiceRun, setAllowServiceRun] = useState<boolean>(!!(driverDetails.plan && carInfo.hub));

    // hub is defaulted to the value from the context, if any; if not, then
    // set the first value from the available hubs.
    const [hub, setHub] = useState<IChoice>(
        carInfo.hub
            ? hubs[Country[country]].filter(item => item.value === carInfo.hub)[0]
            : hubs[Country[country]][0]
    );

    // At rendering:
    // 1. set the context's plan value with the value of state - plan.
    // 2. set the context's hub with value of state - hub.
    // At this stage the plan and the hub have been set inside the context 
    // and as states so the service can be triggered safely, so last step is
    // setting the flag that allows the service to run -> having it false before,
    // it prevents the service to run twice, the first time being with the
    // hub parameter not set, resulting into a backend fail
    useEffect(
        () => {
            updateFormState(UserFormStates.driverDetails, { country: country });
            updateFormState(UserFormStates.carInfo, { hub: hub.value });
            setAllowServiceRun(true);

            if (carInfo.make) {
                updateFormState(UserFormStates.carInfo, {
                    id: carInfo.id, make: carInfo.make, model: carInfo.model,
                    year: carInfo.year, colour: carInfo.colour, registrationPlate: carInfo.registrationPlate, vin: carInfo.vin,
                    bodyType: carInfo.bodyType, odometer: carInfo.odometer
                })
            }
            else { noVehicleDetails }
        },
        []
    );

    const navigate = useNavigate();

    // Vehicle state - initialised with the values from the context if any,
    // otherwise with the vehicle object which denotes no vehicle selected
    const [selectedVehDetails, setSelectedVehDetails] = useState<IVehicle>(
        carInfo.make
            ? {
                id: carInfo.id,
                make: carInfo.make,
                model: carInfo.model,
                year: String(carInfo.year),
                colour: carInfo.colour,
                license_plate: carInfo.registrationPlate,
                vin: carInfo.vin,
                body_type: carInfo.bodyType,
                odometer: carInfo.odometer,
            }
            : noVehicleDetails
    );

    const [loading, error, errMessage, vehicles] = useServiceHook(
        // "test_drive" is used as the vehicle_type so the vehicles returned are available for Flexi Own and Flexi.
        // This is handled by the BE.
        VehicleService.getVehicles, [{ vehicle_type: "Test Drive", hub: hub.value }, Country[country]],
        allowServiceRun,
        [carInfo.hub]  // <= dependency for the hook 
    );

    const onSelectHub = (event: ChangeEvent<HTMLSelectElement>) => {
        // Each time a new hub is selected, available vehicles
        // for it will be retrieved, meaning that the user has
        // to pick one again.
        // The reset is done first because the carInfo context
        // is used bellow for a set operation (so the reset would've
        // overridden the set one).
        setSelectedVehDetails(noVehicleDetails);
        resetFormState(UserFormStates.carInfo);

        // When a hub is selected, store it into the state and to the context.
        // The hub state value is a dependency of the service hook, triggering a
        // server call.
        setHub(hubs[Country[country]].filter(item => item.value === event.target.value)[0]);
        updateFormState(UserFormStates.carInfo, { hub: event.target.value });
    };

    const onSelectVeh = (event: ChangeEvent<HTMLSelectElement>) => {
        // When a car is selected on the drop down menu
        // 1. update the car details state so they will be displayed
        // to the user
        // 2. update the context, so veh details will be used later
        // 3. display the odometer value
        const vehicleId = event.currentTarget.value;
        const carDetails: IVehicle = vehicles.filter((item: IVehicle) => item.id === vehicleId)[0];
        if (!carDetails) {
            setSelectedVehDetails(noVehicleDetails);
            resetFormState(UserFormStates.carInfo);
            return;
        }
        setSelectedVehDetails(carDetails);
        updateFormState(UserFormStates.carInfo, {
            id: carDetails.id,
            make: carDetails.make,
            model: carDetails.model,
            year: Number(carDetails.year),
            colour: carDetails.colour,
            registrationPlate: carDetails.license_plate,
            vin: carDetails.vin,
            bodyType: carDetails.body_type,
            odometer: carDetails.odometer,
        });
    };


    return (<>
        <NavBar pageTitle={"Test drive vehicle assignment"} backAction={undefined} />
        <form style={{ margin: '2.5vh 2.5vw' }} onSubmit={() => navigate(``)} >
            <p><strong>Step 1</strong></p>
            <div style={{ display: 'flex', flexDirection: 'column', margin: '2.5vh 0vw' }}>
                <Label
                    text={'Please select the state the customer is onboarding on:'}
                    styleCfg={{ largeFont: false, bold: false }}
                    testId="onboarding-label"
                />
                <DropDownMenu
                    menuName={'hub'}
                    defaultVal={hub}
                    required={true}
                    choices={hubs[Country[country]]}
                    onSelect={onSelectHub}
                    testId="drop-down-menu-testid-hub"
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', margin: '2.5vh 0vw' }}>
                <Label
                    text={'Please assign a vehicle to the member:'}
                    styleCfg={{ largeFont: false, bold: false }}
                    testId="assign-vehicle-label"
                />
                {loading === false || loading === null  // not null and not false means at least 1 req was made
                    ? <DropDownMenu
                        menuName={'vehicle'}
                        required={true}
                        testId="drop-down-menu-testid-vehicle"
                        defaultVal={carInfo.make && vehicles
                            ? {
                                label: selectedVehDetails.license_plate,
                                value: selectedVehDetails.id,
                                enabled: true
                            }
                            : undefined
                        }
                        choices={vehicles
                            ? vehicles.map((item: IVehicle) => ({
                                label: item.license_plate + ' - ' + item.make + ' ' + item.model,
                                value: item.id,
                                enabled: true
                            }))
                            : []
                        }
                        // key={vehicles.value}
                        onSelect={(event: ChangeEvent<HTMLSelectElement>) => onSelectVeh(event)}
                    />
                    : <div style={{ height: '5vh' }}><Elliptical /></div>
                }
            </div>
            <p style={{ margin: '2.5vh 0' }}>
                If a vehicle is not available for assignment in the dropdown please raise with your Ops Lead.
            </p>
            <VehicleDetails
                make={selectedVehDetails.make}
                model={selectedVehDetails.model}
                year={selectedVehDetails.year}
                color={selectedVehDetails.colour}
                licensePlate={selectedVehDetails.license_plate}
                vin={selectedVehDetails.vin}
                bodyType={selectedVehDetails.body_type}
                startingMiles={selectedVehDetails.odometer}
            />
            <div style={{ margin: '2.5vh 0' }}>
                <Button label={"Next"} onClickFunc={() => navigate(`../personal`)}/>
            </div>
        </form>
    </>
    );
};