/**
 * Reads in the contents of a file encoded
 * in base 64.
 * @param file The file to read
 * @returns Promise that resolves with undefined
 *          or base 64 encoded string.
 */
export function readFileBase64(file: File) {
    return new Promise<any>(resolve => {
        let reader = new FileReader();
        reader.onload = () => {
            const baseURL = reader.result;
            resolve(baseURL?.toString().split(',')[1]);
        };

        reader.readAsDataURL(file);
    });
}
