import React from 'react';

import Label from '@components/inputs/text/Label';
import { UserFormStates, useUserJourneyContext } from '@context/UserJourneyContext';
import { textBoxStyle } from '@utils/styles/textboxStyle';

/**
 * View intended to be integrated into the Flexi Own onboarding form.
 *
 * All inputs are stored into the corresponding section of the Flexi Own context.
 *
 * All inputs default values are taken from the context. All information
 * related to the driver should've been loaded by the time the user gets
 * to this page. If not, it is not a critical issue, but the user has
 * to manually write all of them.
 */
export default function FlexiOwnEmergencyContacts() {

    const { formState, updateFormState } = useUserJourneyContext();
    const { emergencyContactInfo } = formState;

    const onEmergencyContactInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch (e.currentTarget.name) {
            case 'primaryName':
                updateFormState(UserFormStates.emergencyContactInfo, { primaryName: e.currentTarget.value });
                break;
            case 'primaryAddress':
                updateFormState(UserFormStates.emergencyContactInfo, { primaryAddress: e.currentTarget.value });
                break;
            case 'primaryPhone':
                updateFormState(UserFormStates.emergencyContactInfo, { primaryPhone: e.currentTarget.value });
                break;
            case 'primaryRelationship':
                updateFormState(UserFormStates.emergencyContactInfo, { primaryRelationship: e.currentTarget.value });
                break;
            case 'secondaryName':
                updateFormState(UserFormStates.emergencyContactInfo, { secondaryName: e.currentTarget.value });
                break;
            case 'secondaryAddress':
                updateFormState(UserFormStates.emergencyContactInfo, { secondaryAddress: e.currentTarget.value });
                break;
            case 'secondaryPhone':
                updateFormState(UserFormStates.emergencyContactInfo, { secondaryPhone: e.currentTarget.value });
                break;
            case 'secondaryRelationship':
                updateFormState(UserFormStates.emergencyContactInfo, { secondaryRelationship: e.currentTarget.value });
                break;
        }
    };

    return (<>
        <div style={{ margin: '2.5vh 0vw' }}>

            <p>Please provide details of two emergency contacts. </p>

        </div>

        <div style={{ margin: '2.5vh 0vw' }}>

            <Label text={'Name'} requiredFieldInd={true} />
            <input
                style={textBoxStyle}
                name='primaryName'
                defaultValue={emergencyContactInfo.primaryName}
                required={true}
                onChange={onEmergencyContactInfoChange}
            />

        </div>

        <div style={{ margin: '2.5vh 0vw' }}>

            <Label text={'Address'} requiredFieldInd={true} />
            <input
                style={textBoxStyle}
                name='primaryAddress'
                defaultValue={emergencyContactInfo.primaryAddress}
                required={true}
                onChange={onEmergencyContactInfoChange}
            />

        </div>

        <div style={{ margin: '2.5vh 0vw' }}>

            <Label text={'Phone'} requiredFieldInd={true} />
            <input
                style={textBoxStyle}
                name='primaryPhone'
                defaultValue={emergencyContactInfo.primaryPhone}
                required={true}
                onChange={onEmergencyContactInfoChange}
            />

        </div>

        <div style={{ margin: '2.5vh 0vw' }}>

            <Label text={'Relationship to you'} requiredFieldInd={true} />
            <input
                style={textBoxStyle}
                name='primaryRelationship'
                defaultValue={emergencyContactInfo.primaryRelationship}
                required={true}
                onChange={onEmergencyContactInfoChange}
            />

        </div>

        <div style={{ margin: '2.5vh 0vw' }}>
            <p><strong>Alternative Emergency Contact</strong> (who does not reside with you) </p>
        </div>

        <div style={{ margin: '2.5vh 0vw' }}>

            <Label text={'Name'} />
            <input
                style={textBoxStyle}
                name='secondaryName'
                defaultValue={emergencyContactInfo.secondaryName}
                onChange={onEmergencyContactInfoChange}
            />

        </div>

        <div style={{ margin: '2.5vh 0vw' }}>

            <Label text={'Address'} />
            <input
                style={textBoxStyle}
                name='secondaryAddress'
                defaultValue={emergencyContactInfo.secondaryAddress}
                onChange={onEmergencyContactInfoChange}
            />

        </div>

        <div style={{ margin: '2.5vh 0vw' }}>

            <Label text={'Phone'} />
            <input
                style={textBoxStyle}
                name='secondaryPhone'
                defaultValue={emergencyContactInfo.secondaryPhone}
                onChange={onEmergencyContactInfoChange}
            />

        </div>


        <div style={{ margin: '2.5vh 0vw' }}>

            <Label text={'Relationship to you'} />
            <input
                style={textBoxStyle}
                name='secondaryRelationship'
                defaultValue={emergencyContactInfo.secondaryRelationship}
                onChange={onEmergencyContactInfoChange}
            />
        </div>

    </>
    );
};
