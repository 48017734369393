import { getWithIDToken, IMSTokenParams, postWithIdToken, putWithIdToken} from "@utils/http/MSAuthAPI";
import {Country} from '@utils/constants/localisation';
import {ISubscriptionDetailPanel} from '@components/panels/SubscriptionDetailsPanel';
import {ISubscriptionSummaryItem} from '@components/cards/SubscriptionSummary/SubscriptionSummary';


type YYYY = string;
type MM = string;
type DD = string;
type DateFormat = `${YYYY}-${MM}-${DD}`;


/**
 * Interface SHALL be updated each time a new method is added.
 */
interface ISubscriptionService {
    getSubscriptions(customerId: string, country: Country, IDTokenParams: IMSTokenParams): Promise<ISubscriptionSummaryItem[]>,
    getSubscriptionById(subscriptionId: string, country: Country, IDTokenParams: IMSTokenParams): Promise<ISubscriptionDetailPanel | {}>,
    getAdditionalDriverSubscriptionById(subscriptionId: string, country: Country, IDTokenParams: IMSTokenParams): Promise<ISubscriptionDetailPanel | {}>,
    getCancellationCharges(qParams: object, subscriptionId: string, fleetioVehicleID:string, IDTokenParams: IMSTokenParams): Promise<any>,
    getAddons(qParams: object, IDTokenParams: IMSTokenParams): Promise<any>,
    putCancellationDetails(subscriptionId: string, payload: any, country: Country, IDTokenParams: IMSTokenParams): Promise<any>,
    getTestDrives(customerId: string, country: Country, IDTokenParams: IMSTokenParams): Promise<any>,
    endTestDrive(payload: any, potential_id: string, customerId: string, country: Country, IDTokenParams: IMSTokenParams): Promise<any>,

}


// TODO: enhance service with error handling methods; maybe an interface?
const SubscriptionService: ISubscriptionService = {

    /**
     * Given a customer id and a country code, call the backend API to get all subscriptions hold by the customer.
     * A customer can have from 0 to S subscriptions, so the retrieved list can be empty as well.
     *
     * @param customerId
     * @param country
     * @returns List comprising a set of attributes per subscriptions
     */
    async getSubscriptions(customerId: string, country: Country, IDTokenParams: IMSTokenParams) {
        const convertDateFormat = (dateValue: DateFormat) => (
            new Date(dateValue).toLocaleString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })
        );

        const resp = await getWithIDToken(
            `/v1/customers/${customerId}/subscriptions`,
            IDTokenParams,
            { country: country }
        );
        if (resp.status === 500) {
            return [];
        }
        const processedData = resp.data.map((subs: any) => {
            return {
                subsId: subs.id,
                startDate: convertDateFormat(subs.startDate),
                carDetails: `${subs.Make || '-'} ${subs.Model || '-'}`,
                registration: subs.Vehicle_Registration || '-',
                status: subs.Subscription_Status,
                plan: subs.Plan || '-',
                noticeGiven: subs.Date_Submitted_Notice,
                vehicleId: subs.Fleetio_Vehicle_ID
            }
        });
        return processedData;
    },

    /**
     * Given a subscription id, retrieve a set of attributes for it.
     *
     * @param subscriptionId
     * @param country
     * @returns Object holding the subscription's attributes
     */
    async getSubscriptionById(subscriptionId: string, country: Country, IDTokenParams: IMSTokenParams) {
        const convertDateFormat = (dateValue: DateFormat) => (
            new Date(dateValue).toLocaleString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })
        );

        const resp = await getWithIDToken(
            `/v1/subscriptions/${subscriptionId}`,
            IDTokenParams,
            { country: country }
        );
        if (resp.status === 500) {
            return {};
        }
        const processedData = {
            rentalAgreement: resp.data.Rental_Agreement_Number1,
            startDate: convertDateFormat(resp.data.startDate),  // backendAPI field denoting start date (Zoho field propagated)
            registration: resp.data.Vehicle_Registration,
            plan: resp.data.Plan,
            termLength: resp.data.Flexi_Own_plan_length_in_years,
            make: resp.data.Make,
            model: resp.data.Model,
            email: resp.data.Email,
            noticeGiven: resp.data.Date_Submitted_Notice,
            vehicleId: resp.data.Fleetio_Vehicle_ID
        };
        return processedData;
    },

        /**
     * Given a subscription id, retrieve a set of attributes for the additional driver journey.
     *
     * @param subscriptionId
     * @param country
     * @returns Object holding the subscription's attributes
     */
        async getAdditionalDriverSubscriptionById(subscriptionId: string, country: Country, IDTokenParams: IMSTokenParams) {
    
            const resp = await getWithIDToken(
                `/v1/subscriptions/${subscriptionId}`,
                IDTokenParams,
                { country: country }
            );
            if (resp.status === 500) {
                return {};
            }
            const processedData = {
                firstName: resp.data.Additional_Driver_First_Name,
                lastName: resp.data.Additional_Driver_Last_Name,
                licenceNumber: resp.data.Additional_Driver_License_Number,
                licenceState: resp.data.Additional_Driver_State,
                expiryDate: resp.data.Additional_Driver_License_Expiry_Date,
                dob: resp.data.Additional_Driver_Date_of_Birth,

            };
            
            return processedData;
        },

    
    async getCancellationCharges(qParams: object, subscriptionId: string, fleetioVehicleID, IDTokenParams: IMSTokenParams) {
        const resp = await getWithIDToken(
            `/v1/subscriptions/${subscriptionId}/cancellation-charges`,
            IDTokenParams,
            { ...qParams}
        );
        if (resp.status === 500) {
            return {};
        }

        let processedData = {
            state: resp.data['state'],
            dailyFee: resp.data['daily_fee'],
            weeklyFee: resp.data['weekly_fee'],
            noticePeriodCharges: resp.data["notice_period_charges"]["charge"] || 0,
            noticeDaysToCharge: resp.data['notice_period_charges']['days_to_charge'] || 0,
            minimumTermCharges: resp.data["minimum_term_charges"]["charge"] || 0,
            acceptanceAndRemarketing: resp.data["minimum_term_charges"]["charge"] || 0,
            drivenDistance: resp.data["minimum_term_charges"]["driven_distance"] || 0,
            minimumDaysToCharge: resp.data['minimum_term_charges']['days_to_charge'] || 0,
            startOdometer: resp.data["minimum_term_charges"]["start_odometer"] || 0,
            finishOdometer: resp.data["minimum_term_charges"]["finish_odometer"] || 0,
            usageCharge: resp.data["minimum_term_charges"]["usage_charge"] || 0,
            adminFee: resp.data["minimum_term_charges"]["admin_fee"] || 0,
            phWeeksTaken: 0,
            phTotalContribution: 0,
            phTotalCharge: 0,
        };
        if (resp.data["payment_holiday_charges"]){
            processedData.phWeeksTaken = resp.data["payment_holiday_charges"]['number_of_weeks_taken'];
            processedData.phTotalContribution = resp.data["payment_holiday_charges"]["total_contributions"];
            processedData.phTotalCharge = resp.data["payment_holiday_charges"]["charge"];
            }
                
        return processedData;
    },

    async getAddons(qParams: object, IDTokenParams: IMSTokenParams) {
        const resp = await getWithIDToken(
            `/v1/addons`,
            IDTokenParams,
            { ...qParams}
        );
        if (resp.status === 500) {
            return {};
        }

        const processedData = resp.data.map((record: any) => ({
            id: record.id || record.addon?.id,
            name: record.name || record.addon?.name,
            price: record.price || record.addon?.price,
            pricingModel: record.pricing_model || record.addon.pricing_model
          }));
          return processedData;
    },

    async putCancellationDetails(subscriptionId: string, payload: any, country: Country, IDTokenParams: IMSTokenParams) {
        const resp = await putWithIdToken(
            `v1/subscriptions/${subscriptionId}/cancel`,
            IDTokenParams,
            { country },
            payload
        );
        //return necessary info
        const processedData = {
            fleetioLink: resp.data.fleetio_url,
            zohoLink: resp.data.zoho_url,
            chargebeeURL: resp.data.cb_url,
        };

        return processedData;
    },

    /**
     * Given a customer id and a country code, call the backend API to get all potentials held by the customer.
     * A customer can have from 0 to S potentials, so the retrieved list can be empty as well.
     *
     * @param customerId
     * @param country
     * @returns List comprising a set of attributes per potential
     */
    async getTestDrives(customerId: string, country: Country, IDTokenParams: IMSTokenParams) {

        const resp = await getWithIDToken(
            `/v1/customers/${customerId}/potentials`,
            IDTokenParams,
            { country: country }
        );
        if (resp.status === 500) {
            return [];
        }
        const processedData = resp.data.map((potential: any) => {
            return {
                contactRole: potential.Contact_Role,
                id: potential.id,
                endDatetime: potential.Test_Drive_End,
                startDatetime: potential.Test_Drive_Start,
                vehicle: potential.Test_Drive_Vehicle,
                registration: potential.Test_Drive_Vehicle_Registration,
                vehicleId: potential.Fleetio_Vehicle_ID,
            }
        });
        return processedData;
    },

    async endTestDrive(payload: any, customerId: string, potentialId: string, country: Country, IDTokenParams: IMSTokenParams) {
        const resp = await postWithIdToken(
            `v1/customers/${customerId}/potentials/${potentialId}`,
            IDTokenParams,
            { country },
            payload
        );
        //return necessary info
        const processedData = {
            fleetioLink: resp.data.fleetio_link,
            zohoLink: resp.data.zoho_link,
            vehicle: resp.data.vehicle,
        };

        return processedData;
    },
};


export default SubscriptionService;
