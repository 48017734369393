import { putWithIdToken, deleteWithIdToken, getWithIDToken, IMSTokenParams } from "@utils/http/MSAuthAPI";

type YYYY = string;
type MM = string;
type DD = string;
type DateFormat = `${YYYY}-${MM}-${DD}`;

// We can use alternateHost to point to localhost for testing
//const alternateHost = 'http://localhost:5000'; 
const alternateHost = ''; 


export interface IStartDate {
    startDate: string
    enabled: boolean
    weeksAllowed: number
}

export interface IPaymentHolidaySummary {
    subscriptionId : string,
    country : string,
    holidayEarnRateInWeeks : number,
    holidayEarnFrequencyInWeeks : number
}

export interface IPaymentHolidayEntitlement {
    entitlementDate : string,
    availableWeeks : number
}

export interface IPaymentHolidayBooking {
    id : string
    startDate : string
    noWeeks : number
    delete : boolean
    inFuture : boolean
}

export interface IBooking {
    weeksAllowed: number,
    startDates: IStartDate[],
    eligibilityReportUrl: string,
    warningMsg?: string,
    infoMsg?: string
}

export interface IPaymentHolidayDetails{
    entitlements : IPaymentHolidayEntitlement[],
    holidays : IPaymentHolidayBooking[],
    summary : IPaymentHolidaySummary,
    booking : IBooking
}

interface IDeletePaymentHolidayBooking {
    input :     {
                    subscriptionId: string,
                    country: string,
                    startDate: string,
                    noWeeks: number
                },

    deleted:    {
                    holidayDate: string,
                    bookedBy: string,
                    bookedDateTime: string
                }[]

}

interface ICreatePaymentHolidayBooking {
    input:  {
                subscriptionId: string,
                country: string,
                startDate: string,
                noWeeks: number
            },

    scheme: {
                maxWeeksAllowed: number,
                maxWeeksAllowedTimePeriod: number
            },

    created:    {
                    holidayDate: string,
                    bookedBy: string,
                    bookedDateTime: string
                }[],

    conflicts:  {
                    startDate: string,
                    noWeeks: number
                }[]
}

interface IPaymentHolidayService {
    getPaymentHolidayDetails(subscriptionId: string, country: string, IDTokenParams: IMSTokenParams): Promise<IPaymentHolidayDetails>,
    deletePaymentHolidayBooking(subscriptionId: string, country: string, startDate: string, noWeeks: number, IDTokenParams: IMSTokenParams): Promise<IDeletePaymentHolidayBooking>,
    createPaymentHolidayBooking(subscriptionId: string, country: string, startDate: string, noWeeks: number, IDTokenParams: IMSTokenParams, subscriptionStartDate?: string, subscriptionTermLength?: number, useName?: string): Promise<ICreatePaymentHolidayBooking>
}

const parseDate = (date: DateFormat) => (
    new Date(date).toLocaleString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })
)

const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const dateUTC = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    return dateUTC.toISOString().split('T')[0];
}

const PaymentHolidayService : IPaymentHolidayService = {
    async getPaymentHolidayDetails(subscriptionId: string, country: string, IDTokenParams: IMSTokenParams): Promise<IPaymentHolidayDetails> {
        const params: { country: string } = { country: country };

        const resp = await getWithIDToken(
            `${alternateHost}/v1/subscriptions/${subscriptionId}/payment-holiday`,
            IDTokenParams,
            params
        );
                
        if (resp.status === 500) {
            throw new Error('Server error');
        }

        const rawData : any = resp.data;
        rawData.holidays = rawData.holidays.map((item: { startDate: DateFormat; }) => ({ ...item, startDate: parseDate(item.startDate) }));
        rawData.entitlements = rawData.entitlements.map((item: { entitlementDate: DateFormat; }) => ({ ...item, entitlementDate: parseDate(item.entitlementDate) }));
        rawData.booking.startDates = rawData.booking.startDates.map((item: {startDate: DateFormat;}) => ({ ...item, startDate: parseDate(item.startDate)}));
        
        return rawData;
    },

    async deletePaymentHolidayBooking(subscriptionId: string, country: string, startDate: string, noWeeks: number, IDTokenParams: IMSTokenParams): Promise<any> {
        const resp = await deleteWithIdToken(
            `${alternateHost}/v1/subscriptions/${subscriptionId}/payment-holiday`,
            IDTokenParams,
            { country, startDate: formatDate(startDate), noWeeks },
        );
   
        return resp.data;
    },

    async createPaymentHolidayBooking(subscriptionId: string, country: string, startDate: string, noWeeks: number, IDTokenParams: IMSTokenParams, userName?: string): Promise<any> {
        const params: { country: string, 
                        startDate: string,
                        noWeeks: number,
                        userName?: string 
                    } = {   country: country,
                            startDate: formatDate(startDate),
                            noWeeks: noWeeks
                     };
        userName ? params.userName = userName : undefined

        const resp = await putWithIdToken(
            `${alternateHost}/v1/subscriptions/${subscriptionId}/payment-holiday`,
            IDTokenParams,
            params
        );
        
        resp.data.conflicts = resp.data.conflicts.map((item: { startDate: DateFormat; }) => ({ ...item, startDate: parseDate(item.startDate) }));
        resp.data.nextAvailableDate ? resp.data.nextAvailableDate = parseDate(resp.data.nextAvailableDate) : undefined;
 
        return resp.data;
    }
}   

export default PaymentHolidayService;