import React, { CSSProperties, useState, useEffect } from 'react';

import Label from '@components/inputs/text/Label';
import { UserFormStates, useUserJourneyContext } from '@context/UserJourneyContext';
import { dateToISOFormat } from '@utils/misc/functions';
import DatePicker from '@components/inputs/DateInput';
import { NavBar } from '@components/navigation/NavBar/NavBar';
import { useNavigate, useParams } from 'react-router';
import Button from '@components/buttons/Button/Button';
import useServiceHook from '@hooks/useServiceHook';
import { SubscriptionService } from '@services/index';
import Circular from '@components/spinners/Circular/Circular';
import DropDownMenu, { IChoice } from '@components/inputs/DropDownMenu';
import { Country } from '@utils/constants/localisation';
import { textBoxStyle } from '@utils/styles/textboxStyle';
/**
 * View intended to be integrated into the Flexi Own onboarding form.
 * 
 * All inputs are stored into the corresponding section of the Flexi Own context.
 * 
 * All inputs default values are taken from the context. All information 
 * related to the driver should've been loaded by the time the user gets
 * to this page. If not, it is not a critical issue, but the user has 
 * to manually write all of them.
 */
export default function AdditionalDriverPersonalDetails() {

    const { formState, updateFormState } = useUserJourneyContext();
    const { custId, country } = useParams() as { custId: string; country: Country; };
    const navigate = useNavigate();

    const [allRequiredFieldsFilled, setAllRequiredFieldsFilled] = useState(false);
    const [minimumAge, setMinimumAge] = useState('');
    const [maximumAge, setMaximumAge] = useState('');
    const [maxDriverLicence, setMaxDriverLicence] = useState('');

    const { additionalDriver, driverDetails } = formState;

    const currentDate = new Date();

    const provinceByCountry: { [key in Country]: string[] } = {
        'GB': ['LDN'],
        'AU': ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'VIC', 'TAS', 'WA']
    };

    const dropDownPleaseSelect: IChoice = { label: '- please select -', value: '', enabled: false };

    //Set the default value for driver licence state dropdown
    let defaultCountry: Partial<IChoice | undefined> = undefined
    let preselectedHub: Partial<IChoice | undefined> = undefined

    // Pass in the subscription ID that was selected in the previous view and stored in the context to get the additional driver for that subscription
    const [loading, error, errMessage, results] = useServiceHook(SubscriptionService.getAdditionalDriverSubscriptionById, [additionalDriver.subsID, country]);

    useEffect(() => {
        if (loading === false && checkAllRequiredFields() === false) {
            updateFormState(UserFormStates.additionalDriver, {
                firstName: results.firstName, middleName: results.middleName,
                lastName: results.lastName, licenceNumber: results.licenceNumber, licenceState: results.licenceState,
                expiryDate: results.expiryDate, dob: results.dob
            })
            updateFormState(UserFormStates.driverDetails, {country: country})
        }
    }, [loading]);

    // This useffect checks for any changes to the additional driver object in the context and ensures that all values are
    // filled in before the user can progress with the journey

    useEffect(() => {
        setAllRequiredFieldsFilled(checkAllRequiredFields());
    }, [additionalDriver]);

    const handlePersonalInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch (e.currentTarget.name) {
            case 'firstName':
                updateFormState(UserFormStates.additionalDriver, { firstName: e.currentTarget.value });
                break;
            case 'lastName':
                updateFormState(UserFormStates.additionalDriver, { lastName: e.currentTarget.value });
                break;
            case 'licenceNumber':
                updateFormState(UserFormStates.additionalDriver, { licenceNumber: e.currentTarget.value });
                break;
            case 'stateDropDownMenu':
                updateFormState(UserFormStates.additionalDriver, { licenceState: e.currentTarget.value });
                break;
            case 'expiryDate':
                updateFormState(UserFormStates.additionalDriver, { expiryDate: e.currentTarget.value });
                break;
            case 'dob':
                updateFormState(UserFormStates.additionalDriver, { dob: e.currentTarget.value });
                break;
        }
    };

    // This method is used to avoid manipulating the DOM and triggering an infinite rerender.
    if (results) {
        defaultCountry = { label: results.licenceState, value: results.licenceState, enabled: true }
        preselectedHub = {label: additionalDriver.licenceState, value: additionalDriver.licenceState, enabled: true}
    }

    const checkAllRequiredFields = () => {
        const requiredFields = [
            additionalDriver.firstName,
            additionalDriver.lastName,
            additionalDriver.licenceNumber,
            additionalDriver.licenceState,
            additionalDriver.expiryDate,
            additionalDriver.dob
        ];

        const allFilled = requiredFields.every(field => field && field.trim() !== '');

        return allFilled;
    };

    const getDateString = function (date: Date) {
        const year = date.getFullYear().toString();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return (`${year}-${month}-${day}`)
    };

    // The minimum age a driver can be is 23 years old. This applies to both AU & UK
    const getMinimumAge = function () {
        const minDate = new Date(
            currentDate.getFullYear() - 23,
            currentDate.getMonth(),
            currentDate.getDate()
        );
        const date = getDateString(minDate)
        setMinimumAge(date);
    };

    // The maximum age a driver can be is 100 years old. This applies to both AU & UK
    const getMaximumAge = function () {
        const maxDate = new Date(
            currentDate.getFullYear() - 100,
            currentDate.getMonth(),
            currentDate.getDate()
        );
        const date = getDateString(maxDate)
        setMaximumAge(date);
    };

    // The maximum length of a driver's licence before it expires is 10 years, 11 is
    // used for safety. Applies to both AU & UK
    const getMaximumDriverLicenceExpiry = function () {
        const maxDate = new Date(
            currentDate.getFullYear() + 11,
            currentDate.getMonth(),
            currentDate.getDate()
        );
        const date = getDateString(maxDate)
        setMaxDriverLicence(date);
    };

    const todaysDate = dateToISOFormat(new Date());

    useEffect(() => {
        getMinimumAge();
        getMaximumAge();
        getMaximumDriverLicenceExpiry();
    }, []);

    const onButtonClick = () => {
        // TODO: navigate to the image upload component
        navigate(`/update-customer-details/${country}/${custId}/documents`)
    }

    return (<>
        {loading === false ? (
            <>
                <NavBar pageTitle="Additional driver personal details" />
                <div style={{ margin: '2.5vh 0vw' }}>
                    <p><strong>Step 2</strong></p>
                </div>

                <div style={{ margin: '2.5vh' }}>
                    <Label
                        text={'First name'}
                        styleCfg={{ largeFont: false, bold: false }}
                        requiredFieldInd={true}
                    />
                    <input name={'firstName'} style={textBoxStyle} required defaultValue={additionalDriver.firstName} onChange={handlePersonalInfoChange} />
                </div>

                <div style={{ margin: '2.5vh' }}>
                    <Label
                        text={'Last Name'}
                        styleCfg={{ largeFont: false, bold: false }}
                        requiredFieldInd={true}
                    />
                    <input name={'lastName'} style={textBoxStyle} required defaultValue={additionalDriver.lastName} onChange={handlePersonalInfoChange} />
                </div>

                <div style={{ margin: '2.5vh' }}>
                    <Label
                        text={'Driver licence number'}
                        styleCfg={{ largeFont: false, bold: false }}
                        requiredFieldInd={true}
                    />
                    <input name={'licenceNumber'} style={textBoxStyle} required defaultValue={additionalDriver.licenceNumber} onChange={handlePersonalInfoChange} />
                </div>

                <div style={{ margin: '2.5vh' }}>
                    <Label
                        text={'Driver licence state'}
                        styleCfg={{ largeFont: false, bold: false }}
                        requiredFieldInd={true}
                    />
                    <DropDownMenu
                        menuName='stateDropDownMenu'
                        defaultVal={ additionalDriver.licenceState !== "" ? preselectedHub : defaultCountry}
                        required={true}
                        choices={[
                            dropDownPleaseSelect,
                            ...(provinceByCountry[driverDetails.country] || []).map((item: any) => ({
                                label: item,
                                value: item,
                                enabled: true,
                            }))
                        ]}
                        onSelect={handlePersonalInfoChange}
                    />

                </div>

                <div style={{ margin: '2.5vh' }}>
                    <Label text={'Expiry date'} requiredFieldInd={true} />
                    <DatePicker
                        name='expiryDate'
                        max={maxDriverLicence}
                        min={todaysDate}
                        defaultVal={additionalDriver.expiryDate}
                        required={true}
                        onChange={handlePersonalInfoChange} />
                </div>
                <div style={{ margin: '2.5vh' }}>
                    <Label text={'Date of birth'} requiredFieldInd={true} />
                    <DatePicker
                        name='dob'
                        defaultVal={additionalDriver.dob}
                        required={true}
                        max={minimumAge}
                        min={maximumAge}
                        onChange={handlePersonalInfoChange} />
                </div>

                <div style={{
                    bottom: '5vh',
                    textAlign: 'center',
                    opacity: allRequiredFieldsFilled ? 1 : 0.5,
                    pointerEvents: allRequiredFieldsFilled ? 'auto' : 'none',
                    margin: '2.5vh 2vw'
                }}>
                    <Button label='Next' onClickFunc={onButtonClick} disabled={!allRequiredFieldsFilled}></Button>
                </div>
            </>)
            : (
                <div style={{ display: 'flex', justifyContent: 'center', margin: '15vh' }}>
                    <Circular />
                </div>
            )
        }
    </>)
};