import React, { MouseEventHandler } from 'react';
import UnderlinedBtn from '@components/buttons/UnderlinedBtn';

export interface FileRemoveControlProps {
    fileName?: string;
    onRemove?: MouseEventHandler;
}

export default function FileRemoveControl(props: FileRemoveControlProps) {
    return (
        <div style={{ display: 'flex', flexWrap: 'nowrap', gap: '0.5vw' }}>
            <p>{props.fileName}</p>
            <UnderlinedBtn text='Remove' onClickFn={props.onRemove} />
        </div>
    );
}
