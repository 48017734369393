import React, { CSSProperties, ChangeEvent } from "react";
import * as Sentry from "@sentry/react";

export interface IChoice {
    value: string
    label?: string
    enabled: boolean
};


interface IDropDownMenu {
    menuName: string
    defaultVal?: Partial<IChoice>
    required: boolean
    choices: IChoice[]
    onSelect?: Function
    testId?: string
    height?: string
};


export default function DropDownMenu({ menuName, defaultVal, required, choices, onSelect, testId, height }: IDropDownMenu) {

    const onSelectOption = (event: ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault();
        onSelect && onSelect(event);
    };

    const dropDownListStyles: CSSProperties = {
        height: height ? height :'5vh',
        border: '1px solid #C4C4C4',
        borderRadius: '10px',
        minWidth: '50%',
    };

    if (defaultVal && defaultVal.value && !choices.map(item => item.value).includes(defaultVal.value)) {
        Sentry.addBreadcrumb({
            category: 'breadcrumb_change',
            message: `'Default value is not within the choices values'`,
            data: {
                value: defaultVal,
            },
        });
    }


    return (
        <select
            style={dropDownListStyles}
            name={menuName}
            required={required}
            defaultValue={defaultVal != null ? defaultVal.value : undefined}
            onChange={event => onSelectOption(event)}
            data-testid={testId || 'drop-down-menu-testid'}
        >
            {/* If there's no default value provided, then add an empty option */}
            {defaultVal == null && <option />}
            
            {choices.map((item: any, index: number) => (
                <option key={`dropDownList-${index}`} value={item.value}> {item.label ? item.label : item.value} </option>
            ))}

        </select>
    );
};
