import React from 'react';

import Label from '@components/inputs/text/Label';
import { UserFormStates, useUserJourneyContext } from '@context/UserJourneyContext';
import DropDownMenu, { IChoice } from '@components/inputs/DropDownMenu';
import { Country } from '@utils/constants/localisation';
import { textBoxStyle } from '@utils/styles/textboxStyle';

/**
 * View intended to be integrated into the Flexi Own onboarding form.
 * 
 * All inputs are stored into the corresponding section of the User context.
 * 
 * All inputs default values are taken from the context. All information 
 * related to the driver should've been loaded by the time the user gets
 * to this page. If not, it is not a critical issue, but the user has 
 * to manually write all of them.
 */
export default function AddressDetails() {

    const streetNumberAndName = 'Street number and name';
    const city = 'City';
    const state = 'state';
    const postcode = 'Postcode';
    const dropDownPleaseSelect: IChoice = { label: '- please select -', value: '', enabled: false };

    const { formState, updateFormState } = useUserJourneyContext();
    const { addressInfo, driverDetails: { country } } = formState;

    const provinceByCountry: { [key in Country]: string[] } = {
        'GB': ['LDN'],
        'AU': ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'VIC', 'TAS', 'WA']
    };

    const handleAddressInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch (e.currentTarget.name) {
            case streetNumberAndName:
                updateFormState(UserFormStates.addressInfo, { street: e.currentTarget.value });
                break;
            case city:
                updateFormState(UserFormStates.addressInfo, { city: e.currentTarget.value });
                break;
            case state:
                updateFormState(UserFormStates.addressInfo, { state: e.currentTarget.value });
                break;
            case postcode:
                updateFormState(UserFormStates.addressInfo, { postcode: e.currentTarget.value });
                break;
        }
    };

    return (<>
        <p style={{ margin: '2.5vh 0' }}>Please review the customer's <strong>Residential address</strong> ensuring 
        that each field is populated with the correct data.</p>
        <div style={{ margin: '2.5vh 0' }}>
            <Label
                text={'Unit/Street number and Street name'}
                styleCfg={{ largeFont: false, bold: false }}
                requiredFieldInd={true}
            />
            <input name={streetNumberAndName} style={textBoxStyle} required defaultValue={addressInfo.street} onChange={handleAddressInfoChange} />
        </div>

        <div style={{ margin: '2.5vh 0' }}>
            <Label
                text={country === 'AU' ? 'City' : 'Town/City'}
                styleCfg={{ largeFont: false, bold: false }}
                requiredFieldInd={true}
            />
            <input name={city} style={textBoxStyle} required defaultValue={addressInfo.city} onChange={handleAddressInfoChange} />
        </div>

        {country === 'AU' && (
            <>
                <div style={{ margin: '2.5vh 0' }}>
                    <Label
                        text={'State'}
                        styleCfg={{ largeFont: false, bold: false }}
                        requiredFieldInd={true}
                    />
                    <DropDownMenu
                        menuName={state}
                        defaultVal={addressInfo.state
                            ? { label: addressInfo.state, value: addressInfo.state, enabled: true }
                            : dropDownPleaseSelect}
                        required={true}
                        choices={[dropDownPleaseSelect].concat(provinceByCountry[Country[country]].map((item: any) => ({ label: item, value: item, enabled: true })))}
                        onSelect={handleAddressInfoChange}
                    />
                </div>
            </>
        )}

        <div style={{ margin: '2.5vh 0' }}>
            <Label
                text={'Postcode'}
                styleCfg={{ largeFont: false, bold: false }}
                requiredFieldInd={true}
            />
            <input name={postcode} style={textBoxStyle} required defaultValue={addressInfo.postcode} onChange={handleAddressInfoChange} />
        </div>

    </>);
};
