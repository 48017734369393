import React from 'react';

import { NavBar } from '@components/navigation/NavBar/NavBar';
import { LogoutButtonMsal } from '@components/buttons';



export default function SettingsMenu() {

    //Forsee this menu using the SimpleMenu component similar to the other menus however the logout will always be seperate from the menu items and need to be styled differently

    return (<>

        <NavBar pageTitle={"Settings"} />

        <div style={{ paddingTop: "30px" }}>
            <LogoutButtonMsal />
        </div>
    </>
    );
};