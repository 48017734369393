import React, { CSSProperties } from "react"
import { Link, useNavigate, useLocation } from "react-router-dom";

import BackArrow from '@assets/images/back_arrow.svg';
import SplendLogo from '@assets/images/splend_logo.svg';
import SettingsIcon from '@assets/images/settings_icon.svg';



const LinkButton = ({ image, target }: { image: string, target: string }) => {
    const styles: { [item: string]: CSSProperties } = {

        image: {
            verticalAlign: 'middle',
        },
        link: {
            flex: 1,
        },
    }
    return (
        <div>
            <Link to={target} style={styles.link}>
                <img style={styles.image} src={image}></img>
            </Link>
        </div>
    )
}

//The back button component has been implemented in order to create a way to navigate through each journey and allow the user to return to their previous page
//This has been separated from the LinkButton as the Link component requires a hardcoded target to navigate to when clicked
//The idea was to create a way to navigate to the previous destination on the user journey without having to hardcode any routes
const BackButton = ({ image }: { image: string }) => {
    const styles: { [item: string]: CSSProperties } = {

        image: {
            verticalAlign: 'middle',
        },
        button: {
            flex: 1,
            backgroundColor: 'rgba(0,0,0,0)',
            border: '0px',
            cursor: "pointer"
        },
    }
    return (
        <div>
            <button style={styles.button}>
                <img style={styles.image} src={image}></img>
            </button>
        </div>
    )
}

// The NavBar component is visually outside of the views and part of the site more generally, however, it is the
// responsibility of each individual view to render its own NavBar despite this, rather than there being a single,
// centralised NavBar.

// In the design of this component, consideration was given to creating a Context that would store state hooks for
// the NavBar, with the NavBar title, backTarget, and visibility being rendered based on that state- and the NavBar
// component existing only in the index. In such a case, each View would make use of a useEffect hook to alter
// the state of the NavBar when it is loaded, meaning that the NavBar would only be rerendered sparingly and the
// logic would match the visual appearance of the site.

// However, this process would impede the creation of new views- for example, if you are making a view that does not
// contain a NavBar, you may well overlook that you need to explicitly turn off the navbar's visibility, rather than
// simply not include it- consequently, making it the responsibility of the view to render the navbar better fits
// the developer's mental model of the structure of the site.

// Additionally, this way around it is not stateful- we can't end up in a situation where the navbar gets desynchronised
// and the component is itself functionally pure, leading to less issues from side-effects on loading and generally
// keeping all of the information relevant to a view ringfenced within that view.

// Short version: If you are making a new view in which the NavBar should be visible, include this component at the top.

/**
 *
 * @param pageTitle: text to be displayed on the NavBar, generally used to reflect the location within the app
 * @returns
 */


export const NavBar = ({ pageTitle, backAction, showButton=true }: { pageTitle: string, backAction?: Function, showButton?: boolean }) => {
    const styles: { [item: string]: CSSProperties } = {
        navBar: {
            width: '100%',
            display: 'flex',
            height: '45px',
            whiteSpace: 'nowrap',
            userSelect: 'none',
            cursor: 'default',
            borderBottom: '1px solid #C4C4C4',
            position: 'sticky',
            top: 0,
            backgroundColor: '#fff'
        },
        leftButtons: {
            flex: 0,
            display: 'flex',
            alignItems: 'center',
            margin: '5px',
        },
        rightButtons: {
            flex: 0,
            display: 'flex',
            marginRight: '10px',
            alignItems: 'center',
        },
        textHelper: {
            flex: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        titleText: {
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: `24px`,
            color: '#41004F',
            textAlign: 'center',
        }
    }

    //useLocation is used to determine whether or not the user is at the homepage as this affects the styling/functionality of the back button.
    const location = useLocation();

    const navigate = useNavigate();

    // If custom back action is provided via prop
    // then assign it to the back button; otherwise
    // keep the initial functionality
    const handleBackClick = () => (
        backAction
            ? backAction()
            : navigate(-1)
    );

    //As the navbar is loaded into each view, in order to achieve a change in navBar depending on the view some conditional logic has been added
    //If the user is on the homepage the icon should be the Splend logo and should navigate them back to the SmartOps homepage endpoint
    //Furthermore, when on this view the icon should not have the same functionality as the back button

    return (
        <div style={styles.navBar}>
            {location.pathname === "/" ? (
                <div style={styles.leftButtons} >
                    <LinkButton image={SplendLogo} target='/' />
                </div>
            ) : (
                <div style={styles.leftButtons} onClick={handleBackClick}>
                    {showButton && <BackButton image={BackArrow} />}
                </div>
            )}

            <div style={styles.textHelper}>
                <div style={styles.titleText}>
                    {pageTitle}
                </div>
            </div>
            <div style={styles.rightButtons} >
                <LinkButton image={SettingsIcon} target='/settings' />
            </div>
        </div>
    )
};
