import React from 'react';
import { useParams } from 'react-router-dom';

import { NavBar } from '@components/navigation/NavBar/NavBar';
import { SubscriptionDetailsPanel } from '@components/panels/SubscriptionDetailsPanel';
import useServiceHook from '@hooks/useServiceHook';
import { SubscriptionService } from '@services/index';
import EllipticalSpinner from "@components/spinners/Elliptical/Elliptical";


export default function SubscriptionDetails() {

    const { subsId, country } = useParams();

    const [subsLoading, subsError, subsErrMsg, subsDetails] = useServiceHook(
        SubscriptionService.getSubscriptionById,
        [subsId, country]
    );

    return (<>
        <NavBar pageTitle="Subscription overview" />
        {<SubscriptionDetailsPanel
            rentalAgreement={subsLoading !== false ? <EllipticalSpinner /> : subsDetails.rentalAgreement}
            startDate={subsLoading !== false ? <EllipticalSpinner /> : subsDetails.startDate}
            registration={subsLoading !== false ? <EllipticalSpinner /> : subsDetails.registration}
            plan={subsLoading !== false ? <EllipticalSpinner /> : subsDetails.plan}
            termLength={subsLoading !== false ? <EllipticalSpinner /> : subsDetails.termLength ? `${subsDetails.termLength} years` : '-'}
            make={subsLoading !== false ? <EllipticalSpinner /> : subsDetails.make}
            model={subsLoading !== false ? <EllipticalSpinner /> : subsDetails.model}
            email={subsLoading !== false ? <EllipticalSpinner /> : subsDetails.email}
            noticeGiven={subsLoading !== false ? <EllipticalSpinner /> : subsDetails.noticeGiven}
        />}
    </>);
};
