import React from "react";
import { useNavigate } from "react-router-dom";


interface props {
    custName: string,
    custId: string | number,
    email: string,
    country: string,
};


export default React.forwardRef(({ custName, custId, email, country }: props, ref) => {
    const navigate = useNavigate();

    // No border provided, leaving up to who'll be using this to configure it.
    // Content spaced in between the items and grows horisontally as allowed.
    const content = (<>
        <div>
            <div style={{ marginBottom: '5px' }}>{custName}</div>
            <div style={{ marginBottom: '5px', color: '#AAAAAA' }}>{custId}</div>
        </div>
        <div>
            <div>{email}</div>
        </div>
    </>);

    const handleClick = (custId: string | number, country: string) => (
        navigate(`/customer/${country}/${custId}/overview`)
    );

    return (
        ref
            ? <div ref={ref as React.RefObject<HTMLDivElement>} style={{ display: 'flex', justifyContent: 'space-between', flexGrow: 1 }} onClick={() => handleClick(custId, country)}>{content}</div>
            : <div style={{ display: 'flex', justifyContent: 'space-between', flexGrow: 1 }} onClick={() => handleClick(custId, country)}>{content}</div>
    )
});
