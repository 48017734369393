import React, { CSSProperties } from 'react';
import { PanelItem, IPanelItem } from './PanelItems';


export interface IVehicleDetails {
    make: string
    model: string
    year: string
    color: string
    licensePlate: string
    vin: string
    bodyType: string
    startingMiles: number
    testId?: string
};


export default function VehicleDetails(props: IVehicleDetails) {

    const carDetailsFields: IPanelItem[] = [
        { header: 'Make', headerTextStyleCfg: { bold: true }, value: props.make },
        { header: 'Model', headerTextStyleCfg: { bold: true }, value: props.model },
        { header: 'Year', headerTextStyleCfg: { bold: true }, value: props.year },
        { header: 'Colour', headerTextStyleCfg: { bold: true }, value: props.color },
        { header: 'Registration plate', headerTextStyleCfg: { bold: true }, value: props.licensePlate },
        { header: 'VIN', headerTextStyleCfg: { bold: true }, value: props.vin },
        { header: 'Body type', headerTextStyleCfg: { bold: true }, value: props.bodyType },
    ];

    const style: CSSProperties = {
        display: 'flex',
        flexDirection: 'column'
    };

    return (
        <div style={style} data-testid={props.testId || 'carDetailsPanel'}>
            {carDetailsFields.map((itemProps, index) => <PanelItem key={`carDetailsPanelItem-${index}`} {...itemProps} />)}
        </div>
    );
};
