import React, {CSSProperties} from 'react';
import {useNavigate, useParams} from 'react-router-dom';


import {NavBar} from '@components/navigation/NavBar/NavBar';
import Button from '@components/buttons/Button/Button';
import {ConfirmationBox} from '@components/cards/messageBox';
import {Country} from '@utils/constants/localisation';

export default function AdditionalDriverResult() {

    const { custId, country } = useParams() as { custId: string, country: Country, key: string };

    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate(`/customer/${country}/${custId}/overview`);
    };

    const pageStyle: CSSProperties = {
        margin: "2vh 2.5vw",
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: 'calc(100vh - 100px)'
    };

    const buttonContainerStyle = {
        marginTop: '5vh',
    };

    return (<>
        <NavBar pageTitle={"Confirmation"} showButton={false} />

        <div style={pageStyle}>

            <ConfirmationBox message="Additional driver details have been added"></ConfirmationBox>

            <div style={{marginTop: '5vh'}}>
                <p>The <strong>Subscription record</strong> now contains the additional driver details for the customer and any uploaded attachements will be visible in the attachment sections of the customer's <strong>Contact record</strong>.</p>
            </div>

            <div style={buttonContainerStyle}>

                <Button label={"Return to customer landing page"} onClickFunc={handleButtonClick}></Button>

            </div>
        </div>
    </>);
};
