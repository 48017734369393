import React from 'react';

interface WarningModalProps {
    message: string;
    additionalInfo: string;
    onClickClose: React.MouseEventHandler;
}

const WarningModal: React.FC<WarningModalProps> = ({ message, additionalInfo, onClickClose }) => (
    <div className="warning-modal">
        <div className="warning-modal-content">
            <h2>{message}</h2>
            <p>{additionalInfo}</p>
            <button onClick={onClickClose}>Close</button>
        </div>
    </div>
);

export default WarningModal;