import { IChoice } from "@components/inputs/DropDownMenu";

const nationalities: IChoice[] = [
    { value: 'Afghan', label: 'Afghan', enabled: true },
    { value: 'Albanian', label: 'Albanian', enabled: true },
    { value: 'Algerian', label: 'Algerian', enabled: true },
    { value: 'American', label: 'American', enabled: true },
    { value: 'Andorran', label: 'Andorran', enabled: true },
    { value: 'Angolan', label: 'Angolan', enabled: true },
    { value: 'Antiguan or Barbudan', label: 'Antiguan or Barbudan', enabled: true },
    { value: 'Argentine', label: 'Argentine', enabled: true },
    { value: 'Armenian', label: 'Armenian', enabled: true },
    { value: 'Australian', label: 'Australian', enabled: true },
    { value: 'Austrian', label: 'Austrian', enabled: true },
    { value: 'Azerbaijani', label: 'Azerbaijani', enabled: true },
    { value: 'Bahamian', label: 'Bahamian', enabled: true },
    { value: 'Bahraini', label: 'Bahraini', enabled: true },
    { value: 'Bangladeshi', label: 'Bangladeshi', enabled: true },
    { value: 'Batswana', label: 'Batswana', enabled: true },
    { value: 'Belarusian', label: 'Belarusian', enabled: true },
    { value: 'Bengali', label: 'Bengali', enabled: true },
    { value: 'Barbadian', label: 'Barbadian', enabled: true },
    { value: 'Belarusian', label: 'Belarusian', enabled: true },
    { value: 'Belgian', label: 'Belgian', enabled: true },
    { value: 'Belizean', label: 'Belizean', enabled: true },
    { value: 'Beninese', label: 'Beninese', enabled: true },
    { value: 'Bhutanese', label: 'Bhutanese', enabled: true },
    { value: 'Bissau-Guinean', label: 'Bissau-Guinean', enabled: true },
    { value: 'Bolivian', label: 'Bolivian', enabled: true },
    { value: 'Bosnian or Herzegovinian', label: 'Bosnian or Herzegovinian', enabled: true },
    { value: 'Brazilian', label: 'Brazilian', enabled: true },
    { value: 'British', label: 'British', enabled: true },
    { value: 'Bruneian', label: 'Bruneian', enabled: true },
    { value: 'Bulgarian', label: 'Bulgarian', enabled: true },
    { value: 'Burkinabé', label: 'Burkinabé', enabled: true },
    { value: 'Burmese', label: 'Burmese', enabled: true },
    { value: 'Burundian', label: 'Burundian', enabled: true },
    { value: 'Cabo Verdean', label: 'Cabo Verdean', enabled: true },
    { value: 'Cambodian', label: 'Cambodian', enabled: true },
    { value: 'Cameroonian', label: 'Cameroonian', enabled: true },
    { value: 'Canadian', label: 'Canadian', enabled: true },
    { value: 'Central African', label: 'Central African', enabled: true },
    { value: 'Chadian', label: 'Chadian', enabled: true },
    { value: 'Chilean', label: 'Chilean', enabled: true },
    { value: 'Chinese', label: 'Chinese', enabled: true },
    { value: 'Colombian', label: 'Colombian', enabled: true },
    { value: 'Comoran', label: 'Comoran', enabled: true },
    { value: 'Congolese', label: 'Congolese', enabled: true },
    { value: 'Costa Rican', label: 'Costa Rican', enabled: true },
    { value: 'Ivorian', label: 'Ivorian', enabled: true },
    { value: 'Croatian', label: 'Croatian', enabled: true },
    { value: 'Cuban', label: 'Cuban', enabled: true },
    { value: 'Cypriot', label: 'Cypriot', enabled: true },
    { value: 'Czech', label: 'Czech', enabled: true },
    { value: 'Danish', label: 'Danish', enabled: true },
    { value: 'Djiboutian', label: 'Djiboutian', enabled: true },
    { value: 'Dominican', label: 'Dominican', enabled: true },
    { value: 'Dutchman', label: 'Dutchman', enabled: true },
    { value: 'Dutchwoman', label: 'Dutchwoman', enabled: true },
    { value: 'East-Timorese', label: 'East Timorese', enabled: true },
    { value: 'Ecuadorean', label: 'Ecuadorean', enabled: true },
    { value: 'Guinea-Bissauan', label: 'Guinea-Bissauan', enabled: true },
    { value: 'Ecuadorian', label: 'Ecuadorian', enabled: true },
    { value: 'Egyptian', label: 'Egyptian', enabled: true },
    { value: 'Emirati, Emirian, Emiri', label: 'Emirati, Emirian, Emiri', enabled: true },
    { value: 'Equatorial Guinean', label: 'Equatorial Guinean', enabled: true },
    { value: 'Eritrean', label: 'Eritrean', enabled: true },
    { value: 'Estonian', label: 'Estonian', enabled: true },
    { value: 'Ethiopian', label: 'Ethiopian', enabled: true },
    { value: 'Fijian', label: 'Fijian', enabled: true },
    { value: 'Finnish', label: 'Finnish', enabled: true },
    { value: 'French', label: 'French', enabled: true },
    { value: 'Gabonese', label: 'Gabonese', enabled: true },
    { value: 'Gambian', label: 'Gambian', enabled: true },
    { value: 'Georgian', label: 'Georgian', enabled: true },
    { value: 'German', label: 'German', enabled: true },
    { value: 'Ghanaian', label: 'Ghanaian', enabled: true },
    { value: 'Gibraltar', label: 'Gibraltar', enabled: true },
    { value: 'Greek, Hellenic', label: 'Greek, Hellenic', enabled: true },
    { value: 'Grenadian', label: 'Grenadian', enabled: true },
    { value: 'Guatemalan', label: 'Guatemalan', enabled: true },
    { value: 'Guinean', label: 'Guinean', enabled: true },
    { value: 'Guyanese', label: 'Guyanese', enabled: true },
    { value: 'Haitian', label: 'Haitian', enabled: true },
    { value: 'Honduran', label: 'Honduran', enabled: true },
    { value: 'Hungarian, Magyar', label: 'Hungarian, Magyar', enabled: true },
    { value: 'Icelandic', label: 'Icelandic', enabled: true },
    { value: 'Indian', label: 'Indian', enabled: true },
    { value: 'Indonesian', label: 'Indonesian', enabled: true },
    { value: 'Iranian, Persian', label: 'Iranian, Persian', enabled: true },
    { value: 'Iraqi', label: 'Iraqi', enabled: true },
    { value: 'Irish', label: 'Irish', enabled: true },
    { value: 'Israeli', label: 'Israeli', enabled: true },
    { value: 'Italian', label: 'Italian', enabled: true },
    { value: 'Ivorian', label: 'Ivorian', enabled: true },
    { value: 'Jamaican', label: 'Jamaican', enabled: true },
    { value: 'Japanese', label: 'Japanese', enabled: true },
    { value: 'Jordanian', label: 'Jordanian', enabled: true },
    { value: 'Kazakhstani, Kazakh', label: 'Kazakhstani, Kazakh', enabled: true },
    { value: 'Kenyan', label: 'Kenyan', enabled: true },
    { value: 'Kittian-and-Nevisian', label: 'Kittian and Nevisian', enabled: true },
    { value: 'Luxembourger', label: 'Luxembourger', enabled: true },
    { value: 'I-Kiribati', label: 'I-Kiribati', enabled: true },
    { value: 'North Korean', label: 'North Korean', enabled: true },
    { value: 'South Korean', label: 'South Korean', enabled: true },
    { value: 'Kuwaiti', label: 'Kuwaiti', enabled: true },
    { value: 'Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz', label: 'Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz', enabled: true },
    { value: 'Lao, Laotian', label: 'Lao, Laotian', enabled: true },
    { value: 'Latvian, Lettish', label: 'Latvian, Lettish', enabled: true },
    { value: 'Lebanese', label: 'Lebanese', enabled: true },
    { value: 'Basotho', label: 'Basotho', enabled: true },
    { value: 'Liberian', label: 'Liberian', enabled: true },
    { value: 'Libyan', label: 'Libyan', enabled: true },
    { value: 'Liechtensteiner', label: 'Liechtensteiner', enabled: true },
    { value: 'Lithuanian', label: 'Lithuanian', enabled: true },
    { value: 'Luxembourg, Luxembourgish', label: 'Luxembourg, Luxembourgish', enabled: true },
    { value: 'Macedonian', label: 'Macedonian', enabled: true },
    { value: 'Malagasy', label: 'Malagasy', enabled: true },
    { value: 'Malawian', label: 'Malawian', enabled: true },
    { value: 'Malaysian', label: 'Malaysian', enabled: true },
    { value: 'Maldivian', label: 'Maldivian', enabled: true },
    { value: 'Malian, Malinese', label: 'Malian, Malinese', enabled: true },
    { value: 'Maldivan', label: 'Maldivan', enabled: true },
    { value: 'Malian', label: 'Malian', enabled: true },
    { value: 'Maltese', label: 'Maltese', enabled: true },
    { value: 'Marshallese', label: 'Marshallese', enabled: true },
    { value: 'Martiniquais, Martinican', label: 'Martiniquais, Martinican', enabled: true },
    { value: 'Mauritanian', label: 'Mauritanian', enabled: true },
    { value: 'Mauritian', label: 'Mauritian', enabled: true },
    { value: 'Mexican', label: 'Mexican', enabled: true },
    { value: 'Micronesian', label: 'Micronesian', enabled: true },
    { value: 'Moldovan', label: 'Moldovan', enabled: true },
    { value: 'Monégasque, Monacan', label: 'Monégasque, Monacan', enabled: true },
    { value: 'Mongolian', label: 'Mongolian', enabled: true },
    { value: 'Montenegrin', label: 'Montenegrin', enabled: true },
    { value: 'Moroccan', label: 'Moroccan', enabled: true },
    { value: 'Motswana', label: 'Motswana', enabled: true },
    { value: 'Mosotho', label: 'Mosotho', enabled: true },
    { value: 'Mozambican', label: 'Mozambican', enabled: true },
    { value: 'Namibian', label: 'Namibian', enabled: true },
    { value: 'Nauruan', label: 'Nauruan', enabled: true },
    { value: 'Nepali, Nepalese', label: 'Nepali, Nepalese', enabled: true },
    { value: 'Dutch, Netherlandic', label: 'Dutch, Netherlandic', enabled: true },
    { value: 'New Zealand, NZ, Zelanian', label: 'New Zealand, NZ, Zelanian', enabled: true },
    { value: 'Nicaraguan', label: 'Nicaraguan', enabled: true },
    { value: 'Nigerien', label: 'Nigerien', enabled: true },
    { value: 'Nigerian', label: 'Nigerian', enabled: true },
    { value: 'New-Zealander', label: 'New Zealander', enabled: true },
    { value: 'Northern-Irish', label: 'Northern Irish', enabled: true },
    { value: 'Norwegian', label: 'Norwegian', enabled: true },
    { value: 'Northern Marianan', label: 'Northern Marianan', enabled: true },
    { value: 'Norwegian', label: 'Norwegian', enabled: true },
    { value: 'Omani', label: 'Omani', enabled: true },
    { value: 'Pakistani', label: 'Pakistani', enabled: true },
    { value: 'Palauan', label: 'Palauan', enabled: true },
    { value: 'Palestinian', label: 'Palestinian', enabled: true },
    { value: 'Panamanian', label: 'Panamanian', enabled: true },
    { value: 'Papua New Guinean, Papuan', label: 'Papua New Guinean, Papuan', enabled: true },
    { value: 'Paraguayan', label: 'Paraguayan', enabled: true },
    { value: 'Peruvian', label: 'Peruvian', enabled: true },
    { value: 'Filipino, Philippine', label: 'Filipino, Philippine', enabled: true },
    { value: 'Polish', label: 'Polish', enabled: true },
    { value: 'Portuguese', label: 'Portuguese', enabled: true },
    { value: 'Puerto Rican', label: 'Puerto Rican', enabled: true },
    { value: 'Qatari', label: 'Qatari', enabled: true },
    { value: 'Romanian', label: 'Romanian', enabled: true },
    { value: 'Russian', label: 'Russian', enabled: true },
    { value: 'Rwandan', label: 'Rwandan', enabled: true },
    { value: 'Kittitian or Nevisian', label: 'Kittitian or Nevisian', enabled: true },
    { value: 'Saint Lucian', label: 'Saint Lucian', enabled: true },
    { value: 'Saint Vincentian, Vincentian', label: 'Saint Vincentian, Vincentian', enabled: true },
    { value: 'Salvadoran', label: 'Salvadoran', enabled: true },
    { value: 'Samoan', label: 'Samoan', enabled: true },
    { value: 'Sammarinese', label: 'Sammarinese', enabled: true },
    { value: 'São Toméan', label: 'São Toméan', enabled: true },
    { value: 'Saudi, Saudi Arabian', label: 'Saudi, Saudi Arabian', enabled: true },
    { value: 'San-Marinese', label: 'San Marinese', enabled: true },
    { value: 'Sao-Tomean', label: 'Sao Tomean', enabled: true },
    { value: 'Solomon-Islander', label: 'Solomon Islander', enabled: true },
    { value: 'Surinamer', label: 'Surinamer', enabled: true },
    { value: 'Senegalese', label: 'Senegalese', enabled: true },
    { value: 'Serbian', label: 'Serbian', enabled: true },
    { value: 'Seychellois', label: 'Seychellois', enabled: true },
    { value: 'Sierra Leonean', label: 'Sierra Leonean', enabled: true },
    { value: 'Singapore, Singaporean', label: 'Singapore, Singaporean', enabled: true },
    { value: 'Slovak', label: 'Slovak', enabled: true },
    { value: 'Slovenian, Slovene', label: 'Slovenian, Slovene', enabled: true },
    { value: 'Solomon Island', label: 'Solomon Island', enabled: true },
    { value: 'Somali', label: 'Somali', enabled: true },
    { value: 'South African', label: 'South African', enabled: true },
    { value: 'South Sudanese', label: 'South Sudanese', enabled: true },
    { value: 'Spanish', label: 'Spanish', enabled: true },
    { value: 'Sri Lankan', label: 'Sri Lankan', enabled: true },
    { value: 'Sudanese', label: 'Sudanese', enabled: true },
    { value: 'Surinamese', label: 'Surinamese', enabled: true },
    { value: 'Swazi', label: 'Swazi', enabled: true },
    { value: 'Swedish', label: 'Swedish', enabled: true },
    { value: 'Swiss', label: 'Swiss', enabled: true },
    { value: 'Syrian', label: 'Syrian', enabled: true },    { value: 'Taiwanese', label: 'Taiwanese', enabled: true },
    { value: 'Taiwanese', label: 'Taiwanese', enabled: true },
    { value: 'Tajikistani', label: 'Tajikistani', enabled: true },
    { value: 'Tanzanian', label: 'Tanzanian', enabled: true },
    { value: 'Thai', label: 'Thai', enabled: true },
    { value: 'Timorese', label: 'Timorese', enabled: true },
    { value: 'Togolese', label: 'Togolese', enabled: true },
    { value: 'Tokelauan', label: 'Tokelauan', enabled: true },
    { value: 'Tongan', label: 'Tongan', enabled: true },
    { value: 'Trinidadian or Tobagonian', label: 'Trinidadian or Tobagonian', enabled: true },
    { value: 'Tunisian', label: 'Tunisian', enabled: true },
    { value: 'Turkish', label: 'Turkish', enabled: true },
    { value: 'Turkmen', label: 'Turkmen', enabled: true },
    { value: 'Tuvaluan', label: 'Tuvaluan', enabled: true },
    { value: 'Ugandan', label: 'Ugandan', enabled: true },
    { value: 'Ukrainian', label: 'Ukrainian', enabled: true },
    { value: 'Uruguayan', label: 'Uruguayan', enabled: true },
    { value: 'Uzbekistani, Uzbek', label: 'Uzbekistani, Uzbek', enabled: true },
    { value: 'Ni-Vanuatu, Vanuatuan', label: 'Ni-Vanuatu, Vanuatuan', enabled: true },
    { value: 'Vatican', label: 'Vatican', enabled: true },
    { value: 'Venezuelan', label: 'Venezuelan', enabled: true },
    { value: 'Vietnamese', label: 'Vietnamese', enabled: true },
    { value: 'Yemenite', label: 'Yemenite', enabled: true },
    { value: 'Yemeni', label: 'Yemeni', enabled: true },
    { value: 'Zambian', label: 'Zambian', enabled: true },
    { value: 'Zimbabwean', label: 'Zimbabwean', enabled: true },
      ];

      export default nationalities;