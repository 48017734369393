import React, {useEffect, useState} from 'react';

import DatePicker from '@components/inputs/DateInput';
import DropDownMenu, {IChoice} from '@components/inputs/DropDownMenu';
import Label from '@components/inputs/text/Label';
import {UserFormStates, useUserJourneyContext} from '@context/UserJourneyContext';
import {Country} from '@utils/constants/localisation';
import { dateToISOFormat } from '@utils/misc/functions';
import { textBoxStyle } from '@utils/styles/textboxStyle';
import { BreadcrumbProps } from 'SmartOpsHome/breadcrumbObject';


/**
 * View intended to be integrated into the RAV onboarding form.
 *
 * All inputs are stored into the corresponding section of the RAV context.
 *
 * All inputs default values are taken from the context. All information
 * related to the driver should've been loaded by the time the user gets
 * to this page. If not, it is not a critical issue, but the user has
 * to manually write all of them.
 */
export default function FlexiOwnDriverLicence({ breadcrumbObject }: BreadcrumbProps) {

    const dropDownPleaseSelect: IChoice = { label: '- please select -', value: '', enabled: false };
    const provinceByCountry: { [key in Country]: string[] } = {
        'GB': ['LDN'],
        'AU': ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'VIC', 'TAS', 'WA']
    };

    const { formState: { driverDetails }, updateFormState } = useUserJourneyContext();

    const [minimumAge, setMinimumAge] = useState('');
    const [maximumAge, setMaximumAge] = useState('');
    const [maxDriverLicence, setMaxDriverLicence] = useState('');

    const currentDate = new Date();

    const onDriverLicenceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateFormState(UserFormStates.driverDetails, { driversLicenceNumber: e.target.value });
    };
    const onStateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateFormState(UserFormStates.driverDetails, { province: e.target.value });
    };
    const onExpiryDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateFormState(UserFormStates.driverDetails, { driversLicenceExpiry: e.target.value });
    };
    const onDateOfBirthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateFormState(UserFormStates.driverDetails, { dateOfBirth: e.target.value });
    };

    const getDateString = function (date: Date) {
        const year = date.getFullYear().toString();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return (`${year}-${month}-${day}`)
    };

    // The minimum age a driver can be is 23 years old. This applies to both AU & UK
    const getMinimumAge = function () {
        const minDate = new Date(
            currentDate.getFullYear() - 23,
            currentDate.getMonth(),
            currentDate.getDate()
        );
        const date = getDateString(minDate)
        setMinimumAge(date);
    };

    // The maximum age a driver can be is 100 years old. This applies to both AU & UK
    const getMaximumAge = function () {
        const maxDate = new Date(
            currentDate.getFullYear() - 100,
            currentDate.getMonth(),
            currentDate.getDate()
        );
        const date = getDateString(maxDate)
        setMaximumAge(date);
    };

    // The maximum length of a driver's licence before it expires is 10 years, 11 is
    // used for safety. Applies to both AU & UK
    const getMaximumDriverLicenceExpiry = function () {
        const maxDate = new Date(
            currentDate.getFullYear() + 11,
            currentDate.getMonth(),
            currentDate.getDate()
        );
        const date = getDateString(maxDate)
        setMaxDriverLicence(date);
    };

    const todaysDate = dateToISOFormat(new Date());

    useEffect(() => {
        getMinimumAge();
        getMaximumAge();
        getMaximumDriverLicenceExpiry();
    }, []);

    useEffect(() => {
        breadcrumbObject['Driver licence']['Driver Licence Number'] = driverDetails.driversLicenceNumber
        breadcrumbObject['Driver licence']['Province'] = driverDetails.province
        breadcrumbObject['Driver licence']['Driver Licence Expiry'] = driverDetails.driversLicenceExpiry
        breadcrumbObject['Driver licence']['Date of Birth'] = driverDetails.dateOfBirth
    },[driverDetails])

    return (<>
        <div style={{ margin: '2.5vh 0vw' }}>
            <p>Please review the customer’s <strong>driver licence</strong> details, ensuring that each field is populated with the correct data. </p>
        </div>

        <div style={{ margin: '2.5vh 0vw' }}>
            <Label text={'Driver licence number'}
                requiredFieldInd={true} />
            <input
                style={textBoxStyle}
                name='driverLicenceNumber'
                defaultValue={driverDetails.driversLicenceNumber}
                required={true}
                onChange={onDriverLicenceChange}
            />
        </div>

        {driverDetails.country === Country.AU &&  (<div style={{ margin: '2.5vh 0vw' }}>
            <Label text={'State'} requiredFieldInd={true} />
            <DropDownMenu
                menuName='stateDropMenu'
                
                defaultVal={
                    provinceByCountry[driverDetails.country]?.includes(driverDetails.province)
                        ? { label: driverDetails.province, value: driverDetails.province }
                        : dropDownPleaseSelect
                }
                required={true}
                choices={[
                    dropDownPleaseSelect,
                    ...(provinceByCountry[driverDetails.country] || []).map((item: any) => ({
                        label: item,
                        value: item,
                        enabled: true,
                    }))
                ]}
                onSelect={onStateChange}
            />
        </div>)
        }


        <div style={{ width: '100%', margin: '2.5vh 0vw' }}>
            <Label text={'Expiry date'} requiredFieldInd={true} />
            <DatePicker
                name='expiryDate'
                max={maxDriverLicence}
                min={todaysDate }
                defaultVal={driverDetails.driversLicenceExpiry}
                required={true}
                onChange={onExpiryDateChange} />

            <div style={{ margin: '2.5vh 0vw' }}>
                <Label text={'Date of birth'} requiredFieldInd={true} />
                <DatePicker
                    name='dateOfBirth'
                    defaultVal={driverDetails.dateOfBirth}
                    required={true}
                    max={minimumAge}
                    min={maximumAge}
                    onChange={onDateOfBirthChange} />
            </div>
        </div>
    </>
    );
};
