import React, { CSSProperties, MouseEventHandler, MouseEvent } from 'react';
import arrowRight from '@assets/images/arrow_right.svg';


export interface ISubscriptionSummaryItem {
    subsId: string,
    startDate: string,
    carDetails: string,
    registration: string,
    status: string,
    plan: string,
    noticeGiven?: string,
    vehicleId?: string,
    onClickFn?: MouseEventHandler<HTMLDivElement>,
};


export const SubscriptionSummaryCard = (props: ISubscriptionSummaryItem) => {

    // taking mandatory items out of props
    const { startDate, carDetails, registration, status } = props;

    const itemClickable = props.onClickFn !== null && props.onClickFn !== undefined;

    const defaultTextColour = '#000000'

    const onItemClick = (event: MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (props.onClickFn) {
            props.onClickFn(event);
        }
    };

    const styles: CSSProperties = {
        display: 'flex',
        justifyContent: 'space-between',
        cursor: itemClickable ? 'pointer' : 'default',
        padding: '0.5vh 2vw',
        backgroundColor: '#FFFFFF',
        borderBottom: 'thin solid lightgrey',
    };

    const statusColours: { [key:string]: string} = {
        'Active': '#01E4D3',
        'Inactive': '#FB9494',
        'Notice Given': '#FFD700'
    }

    const setStatusColour = (status: string) => {
        return (statusColours[status]) ? statusColours[status] : defaultTextColour
    }

    return (
        <div style={styles} onClick={onItemClick}>
            <div style={{ textAlign: 'left' }}>
                <p style={{ color: '#C4C4C4' }}>Start date: {startDate}</p>
                <p style={{ color: '#2C2A2D' }}>{carDetails} | {props.plan}</p>
            </div>
            <div style={{ display: 'flex', textAlign: 'right', alignItems: 'center' }}>
                <div>
                    <p style={{ color: setStatusColour(status) }}>{status}</p>
                    <p style={{ color: '#2C2A2D' }}>{registration}</p>
                </div>
                <div style={{ marginLeft: '3vw' }}>
                    <img src={arrowRight} />
                </div>
            </div>
        </div>
    )
};
