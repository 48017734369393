import { Country } from "@utils/constants/localisation";
import { IMSTokenParams, getWithIDToken } from "@utils/http/MSAuthAPI";


export interface IVehicle {
    id: string
    make: string
    model: string
    year: string
    colour: string
    license_plate: string
    vin: string
    body_type: string
    odometer: number
    noticeGiven?: string
};


interface IVehicleService {
    getVehicles(qParams: object, country: Country, IDTokenParams: IMSTokenParams): Promise<IVehicle[]>,
    getVehicleStatuses(filter: string, country: Country, IDTokenParams: IMSTokenParams): Promise<IVehicle[]>,
    getVehicleById(vehicleId: string, country: Country, IDTokenParams: IMSTokenParams): Promise<IVehicle[]>,
};


export const VehicleService: IVehicleService = {
    async getVehicles(qParams: object, country: Country, IDTokenParams: IMSTokenParams): Promise<IVehicle[]> {
        const resp = await getWithIDToken(
            '/v1/vehicles/available',
            IDTokenParams,
            { ...qParams, country }
        );
        return resp.data;
    },
    async getVehicleStatuses(filter: string, country: Country, IDTokenParams: IMSTokenParams): Promise<IVehicle[]> {
        const resp = await getWithIDToken(
            '/v1/vehicles/statuses',
            IDTokenParams,
            { filter, country }
        );
        return resp.data;
    },
    async getVehicleById(vehicleId: string, country: Country, IDTokenParams: IMSTokenParams): Promise<IVehicle[]> {
        const resp = await getWithIDToken(
            `/v1/vehicles/${vehicleId}`,
            IDTokenParams,
            { country: country }
        );
        return resp.data;
    },
};