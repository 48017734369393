import React, { useState, CSSProperties, MouseEventHandler } from "react";
import { ButtonStyle } from "../Button/Button";
import { BreadcrumbObject } from "SmartOpsHome/breadcrumbObject";
import * as Sentry from "@sentry/react";


interface ISubmitBtn {
    text: string,
    disabled?: boolean,
    btnStyle?: ButtonStyle,
    breadcrumbObject?: BreadcrumbObject;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    componentName?: string[] | string | undefined,
};


const SubmitBtn = (props: ISubmitBtn) => {
    const [hover, setHover] = useState(false);
    const colors = {
        primaryBtn: {
            bkg: {
                primary: '#6F217B',
                secondary: '#5E1C69'
            },
            text: '#FFFFFF'
        },
        secondaryBtn: {
            bkg: {
                primary: '#FFFFFF',
                secondary: '#F4EBF5'
            },
            text: '#6F217B'
        },
        tertiaryBtn: {
            bkg: {
                primary: '#FFFFFF',
                secondary: '#F4EBF5'
            },
            text: '#6F217B'
        },
    };

    const onButtonHoverStart = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        setHover(true);
    };

    const onButtonHoverEnd = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        setHover(false);
    };


    const onButtonClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
          if (props.breadcrumbObject) {
            sendBreadcrumbs(props.breadcrumbObject, props.componentName);
          }
      };

    // This function takes in the component name and breadcrumb object so that it only sends the object
    // relevant to the component name the form is currently on. i.e. Driver details page sends only driver details
    const sendBreadcrumbs = (breadcrumbObject: BreadcrumbObject, componentName:string[] | string | undefined) => {
        let componentValues = breadcrumbObject[componentName as keyof typeof breadcrumbObject]
            Sentry.setTag("component",componentName?.toString())
                Sentry.addBreadcrumb({
                    category: 'breadcrumb_change',
                    message: `User entered properties for ${componentName}`,
                    data: {
                        value: componentValues,
                    },
                });
                if (componentName?.toString() == "Agreement schedule"){
                    Sentry.setTag(`timed`, true) //add a tag to make timed values easier to find in Sentry
                    Sentry.captureMessage(`Total time taken for onboard: ${componentValues}`);
                }
      };

    const style: CSSProperties = {
        alignItems: 'center',
        backgroundColor: hover
            ? colors[props.btnStyle || ButtonStyle.primary].bkg.secondary
            : colors[props.btnStyle || ButtonStyle.primary].bkg.primary,
        border: 'hidden',
        borderRadius: '20px',
        color: colors[props.btnStyle || ButtonStyle.primary].text,
        cursor: props.disabled !== true ? 'pointer' : 'default',
        display: 'inline-flex',
        gap: '10px',
        justifyContent: 'center',
        padding: '10px 15px',
        width: '100%',
    };

    return (
        <button
            type='submit'
            style={style}
            onMouseEnter={onButtonHoverStart}
            onMouseLeave={onButtonHoverEnd}
            onClick={onButtonClick}
            disabled={props.disabled}
        >
            <span>
                {props.text}
            </span>
        </button>
    )
};


export default SubmitBtn;
