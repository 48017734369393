import React, { useState, useEffect, CSSProperties } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react'

import { NavBar } from '@components/navigation/NavBar/NavBar';
import Button from '@components/buttons/Button/Button';
import { ConfirmationBox, WarningBox } from '@components/cards/messageBox';
import LoadingSpinner from '@components/spinners/Circular/Circular';
import { OnboardingService } from '@services/journeys/Onboarding';
import { Country } from '@utils/constants/localisation';
import * as Sentry from "@sentry/react";
import { UserFormStates, useUserJourneyContext } from "@context/UserJourneyContext";

export default function TestDriveEndConfirmation() {

    const { custId, country } = useParams() as { custId: string, country: Country };

    const { formState } = useUserJourneyContext();
    const { carInfo, driverDetails, testDriveDetails } = formState;

    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate(`/customer/${country}/${custId}/overview`);
    };

    const pageStyle: CSSProperties = {
        margin: "2vh 2.5vw",
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: 'calc(100vh - 100px)'
    };

    const buttonContainerStyle = {
        marginTop: 'auto',
    };

    const paragraphStyle = {
        marginTop: '2.5vh',
    };

    return (<>
        <NavBar pageTitle={"Confirmation"} showButton={false} />

        <div style={pageStyle}>

        <ConfirmationBox message="Test drive has been successfully completed"></ConfirmationBox>

            <p style={paragraphStyle}>
                The following vehicle has been unassigned to the member in Fleetio: <strong>{testDriveDetails.vehicle}</strong> <a href={testDriveDetails.fleetioUrl} target="_blank">View in Fleetio</a>
            </p>
            <p style={paragraphStyle}>
                Potential record has ben updated with time out. <a href={testDriveDetails.zohoUrl} target="_blank">View Potential in Zoho</a>
            </p>
            <div style={buttonContainerStyle}>
                <Button label="Customer overview" onClickFunc={handleButtonClick}></Button>
            </div>
        </div>
    </>);

};
