import React, { useEffect } from 'react';
import { Outlet, useParams, useLocation, useNavigate } from "react-router-dom";

import useCustomerDetails from "./CustomerDetailsHook";
import { NavBar } from '@components/navigation/NavBar/NavBar';
import CustomerDetails from "@components/panels/CustomerDetailsPanel";
import { Country } from '@utils/constants/localisation';
import { UserFormStates, useUserJourneyContext } from 'context/UserJourneyContext';

export default function CustomerDetailsView() {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const { custId, country } = useParams() as { custId: string; country: Country; };

    const { updateFormState } = useUserJourneyContext();

    
    const countryString = country.toString();

    const { loading, error, errMessage, customer } = useCustomerDetails(custId, countryString);

    const paramsMapping: { [key: string]: string } = {
        'overview': 'Customer overview',
        'payments-menu': 'Billing and payments menu',
        'onboarding': 'Onboarding',
    };

    // This useEffect is used to assign the country to the context early on in the journey AND should a journey fail 
    // when the user is taken back to the customerDetails screen the country is reset. This was previously done once 
    // within each journey but by having it here it applies to all journeys.
    useEffect(() => {
        updateFormState(UserFormStates.driverDetails, {country: country})
    }, []);

    const detectMenu = (path: string) => {
        //@ts-ignore
        const menu: string = path.split('/').at(-1);
        return paramsMapping[menu];
    };

    return (<>
        <NavBar pageTitle={detectMenu(pathname)}
            backAction={detectMenu(pathname) === 'Customer overview' ? () => navigate('/') : undefined} />
        <CustomerDetails {...customer} />
        <Outlet />
    </>);
};

