// The dropdown component can only take items of type IChoice, therefore a list of options is shown
// and the value of the option is used to obtain values from the evIncentives list. All evIncentive info
// can be obtained with the plans value which links to term in evIncentives and plan label which links to 
// name in evIncentives

export interface IEVIncentives {
    model: string;
    name: string;
    make: string;
    term: string;
    withoutIncentive: number;
    withIncentive: number;
    incentive: number;
};

const evIncentives: IEVIncentives[] = [
    { name: 'Tesla Model Y', make: 'Tesla', model: 'Model Y', term: '4', withoutIncentive: 627, withIncentive: 569, incentive: 12000 },
    { name: 'Polestar 2 - Standard Range', make: 'Polestar', model: '2', term: "4", withoutIncentive: 507, withIncentive: 459, incentive: 10000 },
    { name: 'Polestar 2 - Long Range', make: 'Polestar', model: '2',term: "4", withoutIncentive: 587, withIncentive: 539, incentive: 10000 },
    { name: 'MG4 Excite', make: 'MG', model: 'MG4',term: "4", withoutIncentive: 430, withIncentive: 399, incentive: 6500 },
    { name: 'Tesla Model 3', make: 'Tesla', model: 'Model 3',term: "4", withoutIncentive: 542, withIncentive: 494, incentive: 10000 },
    { name: 'Tesla Model Y', make: 'Tesla', model: 'Model Y',term: "5", withoutIncentive: 557, withIncentive: 499, incentive: 12000 },
    { name: 'Polestar 2 - Standard Range', make: 'Polestar', model: '2',term: "5", withoutIncentive: 467, withIncentive: 419, incentive: 10000 },
    { name: 'Polestar 2 - Long Range', make: 'Polestar', model: '2',term: "5", withoutIncentive: 527, withIncentive: 479, incentive: 10000 },
    { name: 'MG4 Excite', term: "5", make: 'MG', model: 'MG4',withoutIncentive: 400, withIncentive: 369, incentive: 6500 },
    { name: 'Tesla Model 3', term: "5", make: 'Tesla', model: 'Model 3',withoutIncentive: 487, withIncentive: 439, incentive: 10000 },
];

export default evIncentives;
