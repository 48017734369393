import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import customerSearch from "@services/customer/CustomerSearch";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useAPIError } from "context/ServerErrorContext";
import Warning from '@components/modal/info/Warning';


export interface ICustomer {
    id: string,
    Last_Name: string,
    First_Name: string,
    Email: string,
};


interface result {
    loading: boolean,
    error: boolean,
    errMessage: string,
    customers: ICustomer[],
    hasMore: boolean | null,
};


export default function (query: string, country: string, offset: number, itemsPerPage: number): result {
    const navigate = useNavigate();

    // hasMore flag is used with the pagination mechanism and as an indicator to show if more
    // results can be retrieved (so a suggestive message could be displayed); the initial state
    // of 'null' has the meaning of 'no request made yet' so cannot say if there are any
    // results at all, whereas an initial state of false could be misleading. Its value will be
    // set to true/false depending on the results of each request and in relation with offset.
    const [hasMore, setHasMore] = useState<boolean | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errMessage, setErrMessage] = useState<string>('');
    const [customers, setCustomers] = useState<ICustomer[]>([]);

    const { setErr, removeErr } = useAPIError();

    // Get MSAL context and authentication status for Smart Ops API Auth
    const msalContext = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const treat5xxServerErr = () => {
        setCustomers([]);
        setLoading(false);
        setError(true);
        setErrMessage('Server error! Please contact administrator')
    }

    useEffect(() => {
        // when query or country changes, new results are needed
        // so starting from empty list and initial hasMore state
        setCustomers([]);
        setHasMore(null);
    }, [query, country])


    useEffect(() => {
        // each time the query, offset, country changes,
        // launch new requests according to their values

        if (query === '' || !query.trim().length) {
            setLoading(false);
            setError(false);
            return;
        }

        setLoading(true);
        setError(false);
        setErrMessage('');

        customerSearch(
            { search_string: query, country: country },
            itemsPerPage,
            offset,
            { msalContext: msalContext, isAuthenticated: isAuthenticated }
        ).then((resp) => {
            // TODO: 4xx statuses handling if necessary
            if (resp?.response?.status >= 500) {
                treat5xxServerErr();
                return
            } else if (resp?.status === 204) {
                setCustomers([]);
                setHasMore(false);
                setLoading(false);
                return
            }
            setCustomers((prevCustomers: ICustomer[]) => ([...prevCustomers, ...resp.data]));
            setHasMore(resp.data.length === itemsPerPage ? true : false);
            setLoading(false);
        }).catch((err) => {
            setCustomers([]);
            setLoading(false);
            setError(true);
            setErr(
                true,
                {
                    msg: 'Error in CustomerDetails',
                    // If there is an error from the backend, upon click reset err state and redirect user to homepage 
                    UI: <WarningModal onClickClose={() => { removeErr(); navigate('/'); }} />
                });

        });
    }, [query, offset]);

    return { loading, error, errMessage, customers, hasMore };
};


const WarningModal = ({ onClickClose }: { onClickClose: React.MouseEventHandler }) => (
    <Warning
        message="Something went wrong"
        additionalInfo="We’re sorry, we were unable to process your request. Please try again later and if the issue persists, please log a ticket with IT Helpdesk"
        onClickClose={onClickClose}
    />
);
