import { Country } from "@utils/constants/localisation";
import { IMSTokenParams, getWithIDToken } from "@utils/http/MSAuthAPI";
import { IChoice } from "@components/inputs/DropDownMenu";

interface IContactOwnerService {
    getContactOwners(country: Country, IDTokenParams: IMSTokenParams): Promise<IChoice[]>;
};


export const ContactOwnersService: IContactOwnerService = {
    async getContactOwners(country: Country, IDTokenParams: IMSTokenParams): Promise<any> {
        const resp = await getWithIDToken(
            `/v1/zoho_users`,
            IDTokenParams,
            { country: country}
        );

        return resp.data.map((item: any) => ({
            email: item.email,
            id: item.id,
            label: item.full_name,
        }));
    },
};
