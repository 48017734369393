/**
 * We have to support 3 possible environments:
 *   Prod    => smart-ops.splend.com
 *   Dev     => smart-ops-dev.splend.com
 *   Local   => localhost
 *   Invalid => default path to force updates in the
 *              future when adding new environments
 * 
 * In the future, we want to expand the list and include
 * native application environments.
 */
export enum EnvTag {
    Invalid,
    Local,
    Development,
    Production
}

export const prodHostname = 'smart-ops.splend.com';
export const devHostname = 'smart-ops-dev.splend.com';

/**
 * Encapsulate the method of environment detection in
 * one place so that we can swap out for different methods
 * if we change method of deployment or add a different
 * method to support native application environments.
 * 
 * We are deploying the client side code to AWS S3 as static
 * files, so, do not have access to environment variables.
 * As such, we are using the browser DOM window object to
 * detect the hostname and use that as the basis to decide
 * the deployment environment for the app.
 * 
 * @return {EnvTag}
 */
export function getEnvTag(): EnvTag {
    if (window && window.location && window.location.hostname) {
        if (window.location.hostname == prodHostname)
            return EnvTag.Production;
        else if (window.location.hostname == devHostname)
            return EnvTag.Development;
        else
            return EnvTag.Local;
    } else return EnvTag.Invalid;
}
