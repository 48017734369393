import AdditionalDriverService from "./AdditionalDriver";
import {
    LicenseFilePayload,
    AdditionalDriverPayload,
} from "./AdditionalDriver.types";

export default AdditionalDriverService;
export {
    LicenseFilePayload,
    AdditionalDriverPayload,
};
