import React, { useEffect, useState } from 'react';

import CustomerSearchBar from '@components/inputs/CustomerSearchBar/CustomerSearchBar';
import { NavBar } from '@components/navigation/NavBar/NavBar';
import { OnboardingService } from '@services/journeys/Onboarding';
import { Country } from '@utils/constants/localisation';
import { Payload } from "@utils/http/AxiosAPI";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useNavigate } from 'react-router';
import { useAPIError } from 'context/ServerErrorContext';
import Warning from '@components/modal/info/Warning';
import ModalContainer from '@components/modal/Container';
import CircularSpinner from "@components/spinners/Circular/Circular";


export default () => {

    const [loading, setLoading] = useState<Boolean>(false);

    const url = new URL(window.location.href);

    // Create a new instance of URLSearchParams
    const searchParams = new URLSearchParams(url.search);

    // Get the value of the 'param' query parameter
    const successKeyValue = searchParams.get('success_key');
    const completedKeyValue = searchParams.get('completed_key');
    const countryValue = searchParams.get('country') as Country;
    const contactIdValue = searchParams.get('contact_id');
    const potentialIDValue = searchParams.get('potential_id');
    const singleSign = searchParams.get('single_sign');
    const plan = searchParams.get('journey')

    const msalContext = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const { setErr, removeErr } = useAPIError();
    const navigate = useNavigate();

    const hasSuccessKey = successKeyValue ? successKeyValue : "";

    const planMapping: Payload = {
        success_key: hasSuccessKey,
    };

    // Window.location.replace is used as the customerId and country (as well as the successKey and completedKey as returned from ZohoSign)
    // as passed as query params. Using the useNavigate causes the query params to append twice to the URL. The replace function takes the 
    // necessary values from the query params and  redirects the user to the OnboardingResult page without duplicating the queryparams.

    useEffect(
        () => {
            // redirect to ZohoSign after Customer has filled in the onboarding form so CSR can also sign
            if (countryValue !== null && hasSuccessKey !== "") {
                setLoading(true); // set loading spinner while making request
                OnboardingService.obtainCSRSignUrl(
                    planMapping,
                    Country[countryValue],
                    { msalContext, isAuthenticated }
                ).then((resp) => {
                    // go to signing page
                    window.location.replace(resp.sign_url);
                    setLoading(false);
                }).catch((err) => {
                    // Display an error modal - by acknowledging the error and clicking Ok btn, the user is sent to homepage
                    setErr(
                        true,
                        {
                            msg: 'Error with onboarding',
                            UI: <WarningModal onClickClose={() => { removeErr(); navigate('/'); }} />
                        }
                    );
                    setLoading(false);
                });
            }
            //redirect from ZohoCRM potential page to customer overview page
            else if (potentialIDValue){ 
                setLoading(true); // set loading spinner while making request
                OnboardingService.obtainPotentialRedirectURL(
                    potentialIDValue,
                    Country[countryValue],
                    { msalContext, isAuthenticated }
                ).then((resp) => {
                    // go to signing page
                    window.location.replace(resp.url);
                    setLoading(false);
                }).catch((err) => {
                    // Display an error modal - by acknowledging the error and clicking Ok btn, the user is sent to homepage
                    setErr(
                        true,
                        {
                            msg: 'Error with onboarding',
                            UI: <WarningModal onClickClose={() => { removeErr(); navigate('/'); }} />
                        }
                    );
                    setLoading(false);
                });
            }
            else if (plan == "Test Drive") {
                window.location.replace(`${window.location.origin}/#/test-drive/${countryValue}/${contactIdValue}/book?key=${completedKeyValue}`)
                setLoading(false);
            }
            //redirect to success/failure page after the CSR has signed the contract
            else if (completedKeyValue || singleSign) {
                window.location.replace(`${window.location.origin}/#/onboarding/${countryValue}/${contactIdValue}/result?key=${completedKeyValue}`)
                setLoading(false);
            
            }
        },
        []
    );

    return (<>
        <NavBar pageTitle="Splend Smart Ops" />
        <div style={{ margin: '2.5vh 2.5vw 0 2.5vw' }}>
            <CustomerSearchBar />
        </div>
        {loading && <ForegroundLoadingSpinner />}
    </>);
};

const WarningModal = ({ onClickClose }: { onClickClose: React.MouseEventHandler }) => (
    <Warning
        message="Something went wrong"
        additionalInfo={`We’re sorry, we were unable to process your request.
        We will investigate the issue and in the meantime, please onboard the customer via an alternative method.`}
        onClickClose={onClickClose}
    />
);

const ForegroundLoadingSpinner = () => (
    <ModalContainer stylesCfg={{ windowTransparency: true }} >
        <div style={{ display: 'flex', justifyContent: 'center', margin: '15vh' }}>
            <CircularSpinner />
        </div>
    </ModalContainer>
);
