import React, { CSSProperties } from 'react';
import { LoginButtonMsal } from '@components/buttons';

type ViewProps = {
    splendBanner: string,
    text: string,
};


export default ({splendBanner, text}: ViewProps) => {
    const styles: {[item: string]: CSSProperties} = {
        centeredDiv: {
            position: 'absolute',
            top: '40%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
        logoLayout: {
                textAlign: 'center',
                paddingLeft: '30px',
        },
        logoImg: {
            width: '339px',
            height: '106px',
        },
        welcomeLabelLayout: {
            margin: '85px 0 0 0',
            width: '436px',
            height: '66px',    
        },
        welcomeLabelText: {
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '24px',
                lineHeight: '36px',
                textAlign: 'center',
                color: '#42004F',    
        },
    };

    return (
        <div style={styles.centeredDiv}>
            <div style={styles.logoLayout}>
                <img style={styles.logoImg} src={splendBanner} data-testid='banner'></img>
            </div>
            <div style={styles.welcomeLabelLayout}>
                <p style={styles.welcomeLabelText} data-testid='welcome-text'>{text}</p>
            </div>
            <LoginButtonMsal />
        </div>
    );
};
