import React, { CSSProperties, MouseEventHandler, useState } from 'react';
import Icon from './Icon';
import Label from './Label';


export enum ButtonStyle {
    primary = 'primaryBtn',
    secondary = 'secondaryBtn',
    tertiary = 'tertiaryBtn' // no shadow and change text color to default
};


export type ButtonProps = {
    altTag?: string,
    className?: string,
    iconSrc?: string,
    iconTestId?: string,
    label: string,
    disabled?: boolean,
    onClickFunc?: MouseEventHandler,
    btnType?: ButtonStyle,
    testId?: string,
};


export default (props: ButtonProps) => {
    const [hover, setHover] = useState(false);

    const onButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();

        if (props.onClickFunc) {
            props.onClickFunc(event);
        }
    };

    const onButtonHoverStart = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        setHover(true);
    };

    const onButtonHoverEnd = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        setHover(false);
    };

    const colors = {
        primaryBtn: {
            bkg: { primary: '#6F217B', secondary: '#5E1C69' },
            text: '#FFFFFF'
        },
        secondaryBtn: {
            bkg: { primary: '#FFFFFF', secondary: '#F4EBF5' },
            text: '#6F217B'
        },
        tertiaryBtn: {
            bkg: { primary: '#FFFFFF', secondary: '#FFFFFF' },
            text: '#00000'
        },
    };

    const style: CSSProperties = {
        alignItems: 'center',
        backgroundColor: hover
            ? colors[props.btnType || ButtonStyle.primary].bkg.secondary
            : colors[props.btnType || ButtonStyle.primary].bkg.primary,
        border: ButtonStyle.tertiary ? '1px solid #6F217B':'hidden',
        borderRadius: '20px',
        borderColor: ButtonStyle.tertiary ? '#6F217B' : '',
        color: colors[props.btnType || ButtonStyle.primary].text,
        cursor: props.disabled !== true ? 'pointer' : 'default',
        display: 'inline-flex',
        gap: '10px',
        justifyContent: 'center',
        padding: '10px 15px',
        width: '100%',
    };

    return (
        <button
            style={style}
            className={props.className}
            disabled={props.disabled}
            onClick={onButtonClick}
            onMouseEnter={onButtonHoverStart}
            onMouseLeave={onButtonHoverEnd}
            data-testid={props.testId || 'button'}
        >
            {props.iconSrc && <Icon altTag={props.altTag || 'Button Icon'} iconSrc={props.iconSrc} />}
            <Label label={props.label} />
        </button>
    );
};
