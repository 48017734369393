import React, { CSSProperties } from 'react';


interface TextStyleCfg {
    bold?: boolean,
    largeFont?: boolean  // large meaning 20px; otherwise 18px
}


export interface IPanelItem {
    header: string
    headerTextStyleCfg?: TextStyleCfg
    value: string | React.ReactNode
    valueTextStyleCfg?: TextStyleCfg
    valueTestId?: string
    testId?: string
};


export const PanelItem = (props: IPanelItem) => {
    const itemStyle: CSSProperties = {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0.2vh 0',
    };

    const headerStyle: CSSProperties = {
        cursor: 'default',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: props?.headerTextStyleCfg?.bold ? 'bold' : 'normal',
        userSelect: 'none',
        whiteSpace: 'nowrap',
        margin: '0',
        padding: '0'
    };

    const valueStyle: CSSProperties = {
        margin: 0,
        overflow: 'hidden',
        textOverflow: 'nowrap',
        fontWeight: props.valueTextStyleCfg?.bold ? 'bold' : 'normal',
        fontSize: props.valueTextStyleCfg?.largeFont ? '20px' : '18px',
    };

    return (
        <div style={itemStyle} data-testid={props.testId || 'infoItem'}>
            <p style={headerStyle}>{props.header || 'HEADER-NOT-SET'}</p>
            {/* checking for value type because it's not a good practice to hold a <div/> inside a <p/> tag, so a component/ReactNode would be standalone. */}
            {typeof props.value === 'object' ? props.value : <p style={valueStyle}>{props.value !== null && props.value !== undefined ? props.value : 'VALUE-NOT-SET'}</p>}
        </div>
    );
};
