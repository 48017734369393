import React, { CSSProperties, ChangeEvent } from 'react';

interface CheckboxProps {
  label?: string;
  id?: string;
  customStyle?: CSSProperties;
  onChange: (checked: boolean) => void;
}

export default function Checkbox({ label, id, customStyle, onChange }: CheckboxProps) {
  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  };

  return (
    <div style={customStyle}>
      <input type="checkbox" onChange={handleCheckboxChange} id={id}/>
      {label && <label htmlFor={id}>{ label }</label>}
    </div>
  );
};
