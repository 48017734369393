import React, { CSSProperties, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import useServiceHook from "hooks/useServiceHook";
import { SubscriptionService } from "@services/index";

import { NavBar } from "@components/navigation/NavBar/NavBar";
import CircularSpinner from "@components/spinners/Circular/Circular";
import { InfoBox } from '@components/cards/messageBox';
import Button from "@components/buttons/Button/Button";
import { UserFormStates, useUserJourneyContext } from "@context/UserJourneyContext";
import { ITestDriveSummaryItem } from "@components/cards/TestDriveSummary/TestDriveSummary";
import TestDriveList from "@components/panels/TestDrive/TestDriveList";


export default function TestDriveHistoryList() {
    const navigate = useNavigate();
    const { custId, country } = useParams();

    const { formState, updateFormState, resetFormState } = useUserJourneyContext();

    resetFormState(UserFormStates.carInfo);

    // loading and error SHALL NOT be true in the same time
    const [loading, error, errMessage, results] = useServiceHook(SubscriptionService.getTestDrives, [custId, country]);

    const parseTestDrives = (subs: ITestDriveSummaryItem[]) => {
        return subs
            .filter(sub => sub.startDatetime) // Filter out items with non-null startDatetime
            .map(sub => {
                sub.plan = "Test Drive";
                sub.status = testDriveIsActive(sub.endDatetime);
                return sub;
            });
    };

    const parsePotentials = (subs: ITestDriveSummaryItem[]) => {
        return subs
            .map(sub => {
                sub.plan = "testdrive";
                sub.status = testDriveIsActive(sub.endDatetime);
                return sub;
            });
    };

    const testDriveIsActive = (endDate: string) => {
        return endDate == null ? "In progress" : "Completed"
    }

    const checkTestDriveInProgress = (subs: any) => {
        for (const subscription of subs) {
            // Check if the subscription does not have a test drive start date
            if (!subscription.startDatetime) {
                return true;
            }
        }
        return false;
    };
    

    const selectSubscriptionLabelStyle: CSSProperties = {
        margin: '0',  // set to 0 to mitigate p tag's default margin value
        padding: '2vh 0 2vh 2.5vw',
        color: '#2C2A2D',
        fontWeight: '700',
        borderBottom: '1px solid #C4C4C4'
    };

    // testDrives have open potentials filtered out as we need do not display open potentials
    // but need to check if there are any open potentials to display the book a test drive button
    const testDrives = loading === false ? parseTestDrives(results) : [];
    const potentials = loading === false ? parsePotentials(results) : [];

    // checks for in progress test drives as only one test drive can be booked at a time
    const displayCreateBtn = checkTestDriveInProgress(potentials);

    return (<>
        <NavBar pageTitle="Book a test drive" />
        <p style={selectSubscriptionLabelStyle}>
            Test drive history
        </p>
        {loading === true
            ? <LoadingSpinner />
            :
            <TestDriveList
                keyName='test-drives'
                items={testDrives.map((testdrives: ITestDriveSummaryItem) => {
                    return {
                        startDatetime: testdrives.startDatetime,
                        endDatetime: testdrives.endDatetime,
                        registration: testdrives.registration,
                        plan: testdrives.plan,
                        vehicle: testdrives.vehicle,
                        status: testdrives.status,
                        id: testdrives.id,
                        vehicleId: testdrives.vehicleId,
                        onClickFn: testdrives.status == "In progress"
                            ? () => {
                                updateFormState(UserFormStates.testDriveDetails, { potentialId: testdrives.id, vehicle: testdrives.vehicle, contactId: custId })
                                updateFormState(UserFormStates.driverDetails, { plan: 'Test Drive' });
                                updateFormState(UserFormStates.carInfo, { registrationPlate: testdrives.registration, id: testdrives.vehicleId })
                                navigate(`end`)
                            }
                            : undefined
                    }
                })}
            />
        }
        {loading === false &&
            <div style={{ margin: '2.5vh 3.5vw' }}>
                {displayCreateBtn ?
                    <Button
                        label='Book a test drive'
                        onClickFunc={() => {navigate('vehicle');
                        updateFormState(UserFormStates.driverDetails, { plan: 'Test Drive' });
                        updateFormState(UserFormStates.testDriveDetails, { contactId: custId })
                    }}
                    />
                    :
                    <NoActivePotentialsInfoNote />
                }
            </div>
        }
    </>);
};

const LoadingSpinner = () => (
    <div style={{ display: 'flex', justifyContent: 'center', margin: '15vh' }}>
        <CircularSpinner />
    </div>
);


const NoActivePotentialsInfoNote = () => (
    <div style={{ margin: '1.5vh 3.5vw' }}>
        <InfoBox message='Please note that there are either no active Potential records for this customer or if one exists, it already contains past test drive data. 
        Please open a new Potential record in Zoho CRM and try again.' />
    </div>
);
