import React from 'react';
import {useMsal} from "@azure/msal-react";
import MSIcon from '@assets/images/ms_icon.png';
import Button from './Button/Button';

export type LoginButtonMsalProps = {
    buttonTestId?: string,
    iconTestId?: string,
    testId?: string,
};

export default (props: LoginButtonMsalProps) => {
    const msalContext = useMsal();

    const onLoginClick = async () => {
        // Ensure no interaction is in progress before logging in
        // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md
        await msalContext.instance.handleRedirectPromise();

        const accounts = msalContext.instance.getAllAccounts();
        if (accounts.length === 0) {
            // No user signed in
            msalContext.instance.loginRedirect();
        }
    };

    return (
        <Button
            altTag={'Microsoft'}
            iconSrc={MSIcon}
            label={'Log in with Microsoft'}
            onClickFunc={onLoginClick}
            testId={props.testId}
        />
    );
};
