import React, { CSSProperties } from 'react';

export type IconProps = {
    altTag: string,
    iconSrc: string,
    testId?: {[item: string]: string},
};

export default (props: IconProps) => {
    const styles: {[item: string]: CSSProperties} = {
        icon: {
            alignSelf: 'center',
            margin: '0 2px',
            display: 'flex',
        },
        image: {
            height: '16px',
            width: '16px',
        },
    };

    return (
        <span style={styles.icon} data-testid={props.testId?.icon || 'icon'}>
            <img
                style={styles.image}
                src={props.iconSrc}
                alt={props.altTag}
            />
        </span>
    );
};
