import React, { useEffect, useRef } from 'react';

/**
 * Custom hook based on https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 * A react-friendly wrapper around setInterval
 * usage:
 *  setInterval(callback, delay);
 *
 * @param callback: the function to call after every tick
 * @param delay: the delay for each interval
 *
 */

export default function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}