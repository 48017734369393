import React from 'react';
import { useParams, useNavigate } from "react-router-dom";

import SimpleMenu from '@components/navigation/SimpleMenu/SimpleMenu';


export default function OnboardingMenuView() {
    const { custId, country } = useParams() as { custId: string; country: string; };
    const navigate = useNavigate();

    return (
        <SimpleMenu
            name='onboarding'
            menuItems={[
                {
                    label: 'Bill membership / establishment fee',
                    isDisabled: true
                },
                {
                    label: 'Onboarding - vehicle assignment and contract',
                    onClickFunc: () => navigate(`/onboarding/${country}/${custId}`)
                },
                {
                    label: 'Onboarding - create Chargebee subscription',
                    isDisabled: true
                },
                {
                    label: 'Book vehicle collection',
                    isDisabled: true
                },
                {
                    label: 'Create Chargebee customer',
                    isDisabled: true
                },
            ]}
        />
    );
};
