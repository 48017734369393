import React from 'react';
import { useMsal } from '@azure/msal-react';

import Button from './Button/Button';

export type LogoutButtonMsalProps = {
    testId?: string,
};

export default (props: LogoutButtonMsalProps) => {
    const msalContext = useMsal();

    const onLogoutClick = async () => {
        await msalContext.instance.handleRedirectPromise();

        // Local logout only (not directed to logout from MS Auth, server side)
        // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/logout.md
        msalContext.instance.logoutRedirect({
            onRedirectNavigate: (url) => {
                return false;
            }
        });
    };

    return (
        <Button
            onClickFunc={onLogoutClick}
            label={'Sign out'}
            testId={props.testId}
        />
    );
};
