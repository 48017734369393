import React from "react";

import Box from "./Box";
import InfoIcon from '@assets/images/info_icon.svg';


interface IInfoBox {
    message: string
};


export default function InfoBox({ message }: IInfoBox) {
    return <Box message={message} icon={InfoIcon} bgColor={"#CCFFFB"} />
};
