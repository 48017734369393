import {AuthenticationResult, InteractionStatus} from "@azure/msal-browser";
import { loginRequest } from "@utils/constants/msalConfig";
import { get, put, Headers, QueryParam, Payload, delete_, post } from "@utils/http/AxiosAPI";
import { IMsalContext } from "@azure/msal-react";
import { PlanPayload } from "@views/journeys/OnboardingFormPayloadFactory";

/**
 * These objects are required to get a Microsoft ID token the recommended way
 * They must be retrieved from react hooks, so must be passed into this function
 * This interface groups them for convenience
 */
export interface IMSTokenParams {
    msalContext: IMsalContext,
    isAuthenticated: boolean
}


/**
 * This function gets the ID token from the cache
 *
 * acquireTokenSilent checks the cache for a valid Access Token, otherwise will refresh the Access and ID Tokens
 * if the cached Refresh token is still valid. It will NOT check the expiry of the ID Token and because
 * ID Tokens have a 1h TTL while Access tokens are given between 60 and 90 minutes TTL, there are many cases when the
 * ID Token has expired and the Access token hasn't => This function will not refresh the tokens and the
 * requests to our backend will respond with a 403. Because of this, the msalconfig is set up to renew tokens
 * starting with 35 minutes before expiry of the Access Token, ensuring that we won't fall into the window
 * between an expired ID Token and a valid Access Token.
 *
 * Using this method we use the ID token between 25 and 55 minutes as opposed to forcing a refresh at each request.
 *
 * This is used in conjunction with the default refresh rules. There are some other rules that can be passed
 * as a parameter to acquireTokenSilent, however, those are not applicable to our case since they are used to further
 * prevent refreshes in certain cases.
 *
 * If the Refresh token is expired, acquireTokenSilent will throw an exception and acquireTokenRedirect will be
 * called which will redirect the user to log in again (automatic re-login if the user checked
 * the "Keep me signed in" box).
 *
 * If no ID token can be got, returns empty string
 * @param idTokenParams
 */
export async function getMSIdToken(
    idTokenParams: IMSTokenParams
): Promise<string> {
    let IdToken = ""
    if (idTokenParams.isAuthenticated && idTokenParams.msalContext.inProgress === InteractionStatus.None) {
        await idTokenParams.msalContext.instance.acquireTokenSilent({
            account: idTokenParams.msalContext.accounts[0],
            scopes: loginRequest.scopes
            }).then((response: AuthenticationResult) => {
                IdToken = response.idToken;
            }).catch((acquireException) => {
                idTokenParams.msalContext.instance.acquireTokenRedirect({
                    account: idTokenParams.msalContext.accounts[0],
                    scopes: loginRequest.scopes
                });
            });
    }
    return IdToken;
}


/**
 * Thin wrapper around the Axios API wrapper - get function. This function adds the ID token header before the request
 * @param path
 * @param IDTokenParams
 * @param params
 * @param headers
 */
export async function getWithIDToken(
    path: string,
    IDTokenParams: IMSTokenParams,
    params?: QueryParam,
    headers?: Headers,
): Promise<any> {
    let idToken = await getMSIdToken(IDTokenParams);
    let newHeaders: Headers = { ...headers, ...{ "idtoken": idToken } };
    return get(
        path,
        params,
        newHeaders
    );
};


export async function putWithIdToken(
    path: string,
    IDTokenParams: IMSTokenParams,
    params?: QueryParam,
    payload?: Payload,
    headers?: Headers,
): Promise<any> {
    let idToken = await getMSIdToken(IDTokenParams);
    let newHeaders: Headers = { ...headers, ...{ "idtoken": idToken } };
    return put(
        path,
        params,
        payload,
        newHeaders
    );
};


export async function postWithIdToken(
    path: string,
    IDTokenParams: IMSTokenParams,
    params?: QueryParam,
    payload?: Payload | PlanPayload,
    headers?: Headers,
): Promise<any> {
    let idToken = await getMSIdToken(IDTokenParams);
    let newHeaders: Headers = { ...headers, ...{ "idtoken": idToken } };
    return post(
        path,
        params,
        payload,
        newHeaders
    );
};


export async function deleteWithIdToken(
    path: string,
    IDTokenParams: IMSTokenParams,
    params?: QueryParam,
    headers?: Headers,
): Promise<any> {
    let idToken = await getMSIdToken(IDTokenParams);
    let newHeaders: Headers = { ...headers, ...{ "idtoken": idToken } };
    return delete_(
        path,
        params,
        newHeaders
    );
};
