import { ReactElement, useState } from "react";


export default function useMultiStepForm(steps: ReactElement[]) {
    const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);

    // incrementing the current step index
    // to go to the next step within de form
    const next = () => setCurrentStepIndex(
        prevIndex => prevIndex === steps.length - 1
            ? prevIndex
            : prevIndex + 1
    );

    // decrementing the current step index 
    // to go to previous step within form
    const back = () => setCurrentStepIndex(
        prevIndex => prevIndex === 0
            ? prevIndex
            : prevIndex - 1
    );
    
    // goTo function used in case it's desired to go to a
    // specific step within the form
    const goTo = (index: number) => setCurrentStepIndex(index);

    return {
        currentStepIndex,
        step: steps[currentStepIndex],
        isFirstStep: currentStepIndex === 0,
        isLastStep: currentStepIndex === steps.length - 1,
        isPenultimateStep: currentStepIndex === steps.length -2,
        goTo,
        next,
        back
    };
};
