import React, { useState, useEffect, CSSProperties } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react'

import { NavBar } from '@components/navigation/NavBar/NavBar';
import Button from '@components/buttons/Button/Button';
import { ConfirmationBox } from '@components/cards/messageBox';
import { Country } from '@utils/constants/localisation';
import * as Sentry from "@sentry/react";
import { useUserJourneyContext } from '@context/UserJourneyContext';
import breadcrumbObject from 'SmartOpsHome/breadcrumbObject';
import { getTimeDifference } from '@utils/misc/functions';
import check_mark from '@assets/images/check_mark.svg'
import warning_mark from '@assets/images/warning_icon.svg'


// Unlike other similar components there is no failure page as the journey will fail
// at the previous step should anything go wrong. The component is for display purposes only.

export default function CancellationConfirmation() {

    const { custId, country } = useParams() as { custId: string, country: Country, key: string };

    useEffect(() => {
        breadcrumbObject['Cancellation']['Cancellation End Time'] = new Date();
        Sentry.captureMessage(`Total time taken for cancellation: ${getTimeDifference(breadcrumbObject['Cancellation']['Cancellation Start Time'], breadcrumbObject['Cancellation']['Cancellation End Time'])}`);
    });

    const navigate = useNavigate();

    const { formState } = useUserJourneyContext();
    const { personalInfo, carInfo, cancellationDetails } = formState;

    const pageStyle: CSSProperties = {
        margin: "2vh 2.5vw",
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: 'calc(100vh - 100px)'
    };

    const paragraphStyle = {
        // marginTop: '2.5vh',
        alignItems: 'center'
    };

    const containerStyle = {
        marginTop: '2.5vh',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
    };    
    
    const imageStyle = {
        marginRight: '10px',
        width: '25px',
        height: '25px',
    };

    //the zohoUrl adn fleetioUrl are added into the context as part of the cancellationReview PR and will be populated when that PR passes and trunk is merged into this branch
    const SuccessPage = () => {
        return (<>
            <div style={containerStyle}>
                <img src={warning_mark} alt="Chargebee" style={imageStyle} />
                <p style={paragraphStyle}>
                    Please proceed to Chargebee to cancel the subscription. Verify that the correct charges and addons have been applied, apply credits as needed, and issue the invoice.
                </p>
            </div>
            <div style={containerStyle}>
                <img src={check_mark} alt="Fleetio unassigned" style={imageStyle} />
                <p style={paragraphStyle}>
                    The following vehicle has been unassigned in Fleetio: <strong> {" " + carInfo.registrationPlate} - {carInfo.year} {carInfo.make} {carInfo.model + ". "} </strong> <a href={cancellationDetails.fleetioUrl} target="_blank">View in Fleetio</a>
                </p>
            </div>
            <div style={containerStyle}>
                <img src={check_mark} alt="Subscription inactive" style={imageStyle} />
                <p style={paragraphStyle}>
                    Customer subscription status has been set to inactive. <a href={cancellationDetails.zohoUrl} target="_blank"> View in Zoho</a>
                </p>
            </div>
            {/*Email is currently disabled on the Backend*/}
            {/*<div style={containerStyle}>*/}
            {/*    <img src={check_mark} alt="Cancellation PDF" style={imageStyle} />*/}
            {/*    <p style={paragraphStyle}>*/}
            {/*        A copy of the Cancellation contract PDF has been emailed to <strong> {" " + personalInfo.email}</strong>.*/}
            {/*    </p>*/}
            {/*</div>*/}
        </>);
    };

    return (<>
        <NavBar pageTitle={"Complete cancellation in Chargebee"} showButton={false} />

        <div style={pageStyle}>
           <SuccessPage/>
            <div style={containerStyle}>
                {/* Redirect the user to chargebee to continue the offboarding journey */}
                <Button label="View in Chargebee" onClickFunc={() => window.location.replace(cancellationDetails.chargebeeUrl)} />
            </div>
        </div>
    </>);

};
