// Used for all textboxes in onboarding journeys, used instead of a CSS properties in each page

import { CSSProperties } from 'react';

export const textBoxStyle: CSSProperties = {
    height: '5vh',
    borderRadius: '5px',
    border: '1px solid #C4C4C4',
    justifyContent: 'left',
    boxSizing: 'border-box',
    left: '28px',
    width: '50%',
};
