import { IChoice } from "@components/inputs/DropDownMenu";
import { Country } from "./localisation";

export const hubs: { [key in Country]: IChoice[] } = {
    GB: [
        { value: 'LDN', label: 'Cricklewood', enabled: true },
        { value: 'GLH', label: 'Greenlight', enabled: true },
    ],
    AU: [
        { value: 'ACT', label: 'ACT', enabled: true },
        { value: 'NSW', label: 'NSW', enabled: true },
        { value: 'QLD', label: 'QLD', enabled: true },
        { value: 'SA', label: 'SA', enabled: true },
        { value: 'VIC', label: 'VIC', enabled: true },
        { value: 'WA', label: 'WA', enabled: true },
    ]
};