import { Country } from "@utils/constants/localisation";

export const createIntList = (start: number, len: number, step = 1) => (
  Array.from({ length: len }, (dummy, i) => start + (i * step))
);


// Abstract comparator covers null and undefined.
export const isNotNullNotUndefined = (variable: any): boolean => (variable != null);


// Return ISO format of date object: yyyy-mm-dd
export const dateToISOFormat = (date: Date): string => date.toLocaleDateString("fr-CA", { year: "numeric", month: "2-digit", day: "2-digit" });


/**
 * Function that takes a start date object and adds the weeks number that the next Y years have.
 * 
 * @param startDate Date object
 * @param numYears number of years, of whose number of weeks will be added to startDate 
 * @returns Date object
 */
export const addNextYearsWeeks = (startDate: Date, numYears: number): Date => {
  const isLeapYear = (year: number): boolean => (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);

  const endDate = new Date(startDate);
  let currentYear = startDate.getFullYear();
  let totalWeeks = 0;

  while (numYears > 0) {
    const daysInYear = isLeapYear(currentYear) ? 366 : 365;
    totalWeeks += daysInYear / 7;

    numYears--;
    currentYear++;
  }

  const daysToAdd = Math.ceil(totalWeeks) * 7;
  endDate.setDate(endDate.getDate() + daysToAdd);

  return endDate;
};

export const getTimeDifference = (startDate: Date, endDate: Date) => {
  const timeDifferenceInMilliseconds: number = endDate.getTime() - startDate.getTime();

  const hours: number = Math.floor(timeDifferenceInMilliseconds / (1000 * 60 * 60));
  const remainingMillisecondsAfterHours: number = timeDifferenceInMilliseconds % (1000 * 60 * 60);

  const minutes: number = Math.floor(remainingMillisecondsAfterHours / (1000 * 60));
  const remainingMillisecondsAfterMinutes: number = remainingMillisecondsAfterHours % (1000 * 60);

  const seconds: number = Math.floor(remainingMillisecondsAfterMinutes / 1000);

  const formattedHours = hours.toString();
  const formattedMinutes = minutes.toString();
  const formattedSeconds = seconds.toString();

  return `${formattedHours} hours, ${formattedMinutes} minutes, ${formattedSeconds} seconds`;
};

export const countrySpecificCurrency = (country: string): String => {
  return country === Country.AU ? "$" : "£"
}

export const countrySpecificDistanceUnit = (country: string): String => {
  return country === Country.AU ? "km" : "miles"
}

//Matches only on the name returned from zoho user email as some users log into smart ops with a .com email
// but have a .com.au email stored in Zoho
export const emailMinusSplendDomain = (email: string) => {
  const emailString = email.toLowerCase()
  if (emailString.includes('splend.com')) {
      let splitEmail = emailString.split('@')[0]
      return splitEmail.replace(/-uk|\.uk/g, '');
  }
  return email
}

//Checks if the ending kms for the cancellation/test drive is greater than or equal to value
//of odometer in fleetio. This is because Fleetio update will fail if the input value is less than
//the odometer value in Fleetio
export const checkIfValidFleetioOdometerUpdate = (minValue: string, inputValue: string) => {
  return parseFloat(inputValue) < parseFloat(minValue) ? false : true 
}