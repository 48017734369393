import React, {useEffect, useRef} from 'react';
import Button, {ButtonStyle} from '@components/buttons/Button/Button';

export type BrowseFilesEvent = {
    file: File | undefined,
};

export type BrowseFilesClickEventHandler = (e: BrowseFilesEvent) => void;

interface BrowseFilesButtonProps {
    /**
     * File extensions to allow a User to select.
     * Example: '.pdf, .png'
     */
    allowedTypes?: string;

    /**
     * Setting this to true will reset the <input /> memory
     * for whatever file is currently selected.
     * When this is completed, 'onCacheCleared()' is called.
     */
    clearCache?: boolean;
    testId?: string;

    /**
     * Called after the <input /> has been cleared. Will only
     * be called if 'clearCache' was set to true.
     * Subscribe to this to know that this action was completed.
     * When this is called, you can clear the variable that
     * set the 'clearCache' property.
     */
    onCacheCleared?: () => void;
    onClick?: BrowseFilesClickEventHandler;
}

export default function BrowseFilesButton(props: BrowseFilesButtonProps) {
    const browseFilesRef = useRef<HTMLInputElement>(null);

    const onClickHandler = () => {
        browseFilesRef.current?.click();
    };

    const onChangeHandler = () => {
        /** 
         * Because the <input /> may remove the file it's holding
         * in memory when you click cancel in the dialog, always
         * send the result, even if undefined to imply the file has
         * been removed. Does this matter? Affects form HTML
         * elements when associating inputs with form data.
         * From a User perspective, it would seem odd
         * to cancel and remove a file and not keep the existing
         * one.
         * So, left it up to the callee to decide what to do.
        */
        const newFile = browseFilesRef.current?.files?.[0];
        props.onClick?.({ file: newFile });
    };

    useEffect(() => {
        // Only execute this effect if clear is set to true. This
        // guard may also prevents its execution after the first
        // render.
        if (!props.clearCache) return;

        if (browseFilesRef.current != null) {
            browseFilesRef.current.value = '';
        }

        props.onCacheCleared?.();
    }, [props.clearCache]);

    return (
        <div data-testid={props.testId}>
            <input ref={browseFilesRef} onChange={onChangeHandler} type='file' accept={props.allowedTypes} alt='Browse Files' style={{ display: 'none' }} />
            <Button label='Browse files' onClickFunc={onClickHandler} btnType={ButtonStyle.tertiary} />
        </div>
    );
}
