import React, { CSSProperties } from 'react';


interface IStyles {
    windowTransparency?: true
    display?: string
    flexDirection?: string
    justifyContent?: string
    justifyItems?: string
    textAlign?: string
    width?: string
    height?: string
    padding?: string

};


interface IContainer {
    stylesCfg?: IStyles
    testId?: string
};


/**
 * Container Modal component. It represents a window that pops up in the middle of the
 * screen and has a fixed dimension set in relation with the device's screen size.
 *
 * Window's content is meant to be filled with children components.
 *
 * Designed to accept some styling configuration in order to increase its reusability.
 * Styling config also meant to be extended as the component evolves
 */
const Container = ({ stylesCfg, testId, children }: React.PropsWithChildren<IContainer>) => {

    const styles: CSSProperties = {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        width: '100%',
        height: '100%',
        zIndex: '0',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        position: 'fixed',
    };

    const centeredContent: CSSProperties = {
        backgroundColor: stylesCfg?.windowTransparency ? 'rgba(0, 0, 0, 0)' : 'rgb(255, 255, 255)',
        width: stylesCfg?.width ? stylesCfg?.width : '60vw',
        height: stylesCfg?.height ? stylesCfg?.height : '40vh',
        borderRadius: '30px',

        display: stylesCfg?.display ? stylesCfg?.display : 'flex',
        flexDirection: 'column',
        justifyContent: stylesCfg?.justifyContent ? stylesCfg?.justifyContent : 'center',
        justifyItems: stylesCfg?.justifyItems ? stylesCfg?.justifyItems : 'center',
        textAlign: 'center',
        padding: stylesCfg?.padding ? stylesCfg?.padding : '0 2vw',
        gap: '2vh',

        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    };

    return (
        <div style={styles} data-testid={testId || 'modal-test-id'}>
            <div style={centeredContent}>
                {children}
            </div>
        </div>
    );
};


export default Container;
