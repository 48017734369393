import React, { CSSProperties, useEffect } from 'react';

import Label from '@components/inputs/text/Label';
import { UserFormStates, useUserJourneyContext } from '@context/UserJourneyContext';
import { textBoxStyle } from '@utils/styles/textboxStyle';
import { BreadcrumbProps } from 'SmartOpsHome/breadcrumbObject';

/**
 * View intended to be integrated into the RAV onboarding form.
 * 
 * All inputs are stored into the corresponding section of the RAV context.
 * 
 * All inputs default values are taken from the context. All information 
 * related to the driver should've been loaded by the time the user gets
 * to this page. If not, it is not a critical issue, but the user has 
 * to manually write all of them.
 */

export default function PersonalDetails({ breadcrumbObject }: BreadcrumbProps) {
    
    const firstNameInputName = 'firstName';
    const middleNameInputName = 'middleName';
    const lastNameInputName = 'lastName';
    const emailInputName = 'email';

    const { formState, updateFormState } = useUserJourneyContext();
    const { personalInfo } = formState;

    const handlePersonalInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch (e.currentTarget.name) {
            case firstNameInputName:
                updateFormState(UserFormStates.personalInfo, { firstName: e.currentTarget.value });
                break;
            case middleNameInputName:
                updateFormState(UserFormStates.personalInfo, { middleName: e.currentTarget.value });
                break;
            case lastNameInputName:
                updateFormState(UserFormStates.personalInfo, { lastName: e.currentTarget.value });
                break;
            case emailInputName:
                updateFormState(UserFormStates.personalInfo, { email: e.currentTarget.value });
                break;
        }
    };

    useEffect(() => {
        breadcrumbObject['Personal details']['Customer First Name'] = personalInfo.firstName
        breadcrumbObject['Personal details']['Customer Middle Name'] = personalInfo.middleName
        breadcrumbObject['Personal details']['Customer Last Name'] = personalInfo.lastName
        breadcrumbObject['Personal details']['Customer Email'] = personalInfo.email
    }, [personalInfo])
    
    return (<>
        <p style={{ margin: '2.5vh 0' }}> Please review the customer's <strong>personal details</strong>, ensuring that each field is populated with the correct data.</p>
        <div style={{ display: 'flex', flexDirection: 'column', margin: '2.5vh 0' }}>

            <Label text={'First Name'} requiredFieldInd={true} />
            <input name={firstNameInputName} style={textBoxStyle} required defaultValue={personalInfo.firstName} onChange={handlePersonalInfoChange} />

            <Label text={'Middle Name'} />
            <input name={middleNameInputName} style={textBoxStyle} defaultValue={personalInfo.middleName} onChange={handlePersonalInfoChange} />

            <Label text={'Last Name'} requiredFieldInd={true} />
            <input name={lastNameInputName} style={textBoxStyle} required defaultValue={personalInfo.lastName} onChange={handlePersonalInfoChange} />

            <Label text={'Email'} requiredFieldInd={true} />
            <input name={emailInputName} style={textBoxStyle} type='email' required defaultValue={personalInfo.email} disabled />
            
        </div>
        <p style={{ margin: '2.5vh 0' }}> If you need to change the email address, please do so directly in the CRM.</p>
    </>);
};
