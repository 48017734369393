import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import AdditionalDriverService, {
    AdditionalDriverPayload,
    LicenseFilePayload,
} from '@services/additionalDriver';
import { NavBar } from '@components/navigation/NavBar/NavBar';
import Button from '@components/buttons/Button/Button';
import { FileEvent } from '@components/buttons/BrowseFilesButton';
import UploadFilePanel from '@components/panels/UploadFile/UploadFilePanel';
import CircularSpinner from "@components/spinners/Circular/Circular";
import { useUserJourneyContext } from '@context/UserJourneyContext';
import Warning from '@components/modal/info/Warning';


export default function DriverDocuments() {
    const ALLOWED_FILE_TYPES = '.pdf, .png, .jpeg, .jpg';

    const { custId, country } = useParams() as { custId: string, country: string };

    const navigate = useNavigate();

    const msalContext = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const { formState: {
        additionalDriver,
        driverDetails,
    } } = useUserJourneyContext();

    const [backLicenseFile, setBackLicenseFile] = useState<File>();
    const [frontLicenseFile, setFrontLicenseFile] = useState<File>();
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        if (isUploading) {
            // Due to file reading, and async functions cannot be 'awaited' on
            // in a useEffect(), it is spun off into a series of promises into
            // this function call.
            // As a result, this useEffect() does not wait for this to finish.
            uploadAdditionalDriverInfo();
        }
    }, [isUploading]);

    const onSelectFrontLicenseHandler = (event: FileEvent) => setFrontLicenseFile(event.file);
    const onSelectBackLicenseHandler = (event: FileEvent) => setBackLicenseFile(event.file);
    const onSubmitHandler = () => setIsUploading(true);

    const uploadAdditionalDriverInfo = async () => {
        let backLicenseData;
        let frontLicenseData;

        if (frontLicenseFile) {
            frontLicenseData = await LicenseFilePayload.fromFile(frontLicenseFile);
        }

        if (backLicenseFile) {
            backLicenseData = await LicenseFilePayload.fromFile(backLicenseFile);
        }

        const payload = new AdditionalDriverPayload(
            additionalDriver.firstName,
            additionalDriver.lastName,
            additionalDriver.dob,
            additionalDriver.licenceNumber,
            additionalDriver.licenceState,
            additionalDriver.expiryDate,
            driverDetails.crmId,
            frontLicenseData,
            backLicenseData,
        );

        const data =  await AdditionalDriverService.sendDetails(
            additionalDriver.subsID,
            driverDetails.country,
            payload,
            { msalContext, isAuthenticated },
        );

        if (data) {
            navigate('../confirmation', { relative: 'path' });
        } 
        else {
            setIsUploading(false);
            setError(true)
        }
    };

    const renderUploadLicenseImages = () => {
        return (
            <div style={{ margin: '2.5vh 2.5vw' }}>
                <p><b>Step 3</b></p>
                <br />
                <p>Please upload a copy of the additional driver's <b>driver license (front)</b></p>
                <br />
                <UploadFilePanel allowedTypes={ALLOWED_FILE_TYPES} onSelect={onSelectFrontLicenseHandler} />
                <br />
                <p>Please upload a copy of the additional driver's <b>driver license (back)</b></p>
                <br />
                <UploadFilePanel allowedTypes={ALLOWED_FILE_TYPES} onSelect={onSelectBackLicenseHandler} />
                <br />
                <Button label='Submit' onClickFunc={onSubmitHandler} testId='submit-button' />
            </div>
        );
    };

    const renderUploading = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', margin: '15vh' }}>
                <CircularSpinner />
            </div>
        );
    };

    const onClickClose = () => {
        navigate(`/customer/${country}/${custId}/overview`)
    }

    return (
        <>
            <NavBar pageTitle="Additional driver documents" />
            {isUploading && renderUploading()}
            {!isUploading && renderUploadLicenseImages()}
            {error && 
            <Warning 
            message= 'Something went wrong'
            additionalInfo='We’re sorry, we were unable to process your request. Please try again later and if the issue persists, please log a ticket with IT Helpdesk.' 
            onClickClose={() => onClickClose()}/>}
        </>
    );
}
