import React, {ChangeEvent, useEffect, useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useServiceHook from '@hooks/useServiceHook';
import { VehicleService } from '@services/Vehicles/Vehicles';
import DropDownMenu from '@components/inputs/DropDownMenu';
import Elliptical from '@components/spinners/Elliptical/Elliptical';
import Label from '@components/inputs/text/Label';
import DatePicker from '@components/inputs/DateInput';
import { textBoxStyle } from '@utils/styles/textboxStyle';
import { UserFormStates, useUserJourneyContext } from '@context/UserJourneyContext';
import VehicleDetails from '@components/panels/VehicleDetails';
import { dropDownPleaseSelect } from '@utils/constants/dropdownDefaultSelect';
import { NavBar } from '@components/navigation/NavBar/NavBar';
import Button from '@components/buttons/Button/Button';

interface IFleetioStatus {
    id: string,
    account_id: string,
    name: string,
    color: string,
};

export default function CancellationVehicleDetails() {

    const { vehicleId, custId, subsId, country } = useParams();
    const { formState, updateFormState } = useUserJourneyContext();
    const { cancellationDetails, carInfo } = formState;

    const navigate = useNavigate();

    const statusFilter = 'returning'

    const [vehicleDetailsLoading, vehicleError, vehicleErrMsg, vehicleDetails] = useServiceHook(
        VehicleService.getVehicleById,
        [vehicleId, country]
    );

    const [statusLoading, statusError, statusErrMsg, statuses] = useServiceHook(
        VehicleService.getVehicleStatuses,
        [statusFilter, country]
    );

    useEffect(() => {
        // Check if both vehicleDetails and statuses have been loaded
        if (statusLoading === false && vehicleDetailsLoading === false && statuses.length > 0) {
            // Save default values to the context when the component mounts
            updateFormState(UserFormStates.cancellationDetails, {
                endingKms: vehicleDetails.current_meter_value || 0,
                fleetioStatusId: statuses[0].id,
                fleetioStatusName: statuses[0].name,
                noticeGiven: cancellationDetails.noticeGiven || null,
                cancellationDate: getDateString(new Date()),
                contactId: custId
            }),
                updateFormState(UserFormStates.carInfo, {
                    make: vehicleDetails.make,
                    model: vehicleDetails.model,
                    year: vehicleDetails.year,
                    colour: vehicleDetails.color,
                    registrationPlate: vehicleDetails.license_plate,
                    vin: vehicleDetails.vin,
                    bodyType: vehicleDetails.specs["body_type"],
                    vehicleId: vehicleId
                }),
                updateFormState(UserFormStates.contractDetails, {
                    subscriptionId: subsId,
                    
                });
        }
    }, [vehicleDetailsLoading, statusLoading, statuses, updateFormState]);

    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    useEffect(() => {
        if (statusLoading === false && vehicleDetailsLoading === false) {
            setIsButtonDisabled(false);
        }
    }, [statusLoading, vehicleDetailsLoading]);

    const onEndingKmsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateFormState(UserFormStates.cancellationDetails, { endingKms: e.target.value });
    };
    const onCancellationDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateFormState(UserFormStates.cancellationDetails, { cancellationDate: e.target.value });
    };
    const onSelectStatus = (event: ChangeEvent<HTMLSelectElement>) => {
        const status = event.currentTarget.value;
        const selectedLabel = event.target.options[event.target.selectedIndex].label;
        updateFormState(UserFormStates.cancellationDetails, { fleetioStatusId: status })
        updateFormState(UserFormStates.cancellationDetails, { fleetioStatusName: selectedLabel })
    };
    const getDateString = function (date: Date) {
        const year = date.getFullYear().toString();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return (`${year}-${month}-${day}`)
    };

    function convertDateFormat(inputDate: string) {
        const parts = inputDate.split('-');
    
        const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
    
        return formattedDate;
    }

    const buttonContainerStyle = {
        marginTop: '5vh',
    };

    const handleButtonClick = () => {
        navigate(`/cancellation/${country}/${subsId}/personal`)
    }

    return (<>
        <div style={{ margin: '2.5vh 0vw' }} >
            <NavBar pageTitle="Cancellation" />
        </div>

        <p>Please confirm that vehicle details are correct</p>
        {vehicleDetailsLoading === false || vehicleDetailsLoading === null ? (
            <VehicleDetails
                make={vehicleDetails?.make || ''}
                model={vehicleDetails?.model || ''}
                year={vehicleDetails?.year || ''}
                color={vehicleDetails?.color || ''}
                licensePlate={vehicleDetails?.license_plate || ''}
                vin={vehicleDetails?.vin || ''}
                bodyType={carInfo?.bodyType || ''}
                startingMiles={vehicleDetails?.current_meter_value || ''}
            />
        ) : (
            <div style={{ height: '5vh' }}>
                <Elliptical />
            </div>
        )}
        {vehicleDetailsLoading === false || vehicleDetailsLoading === null ? (
            <>
                <div style={{ margin: '2.5vh 0vw' }}>
                    <Label text={'Ending km'} requiredFieldInd={true} />
                    <input
                        style={textBoxStyle}
                        name='endingKms'
                        data-testid="endingKms"
                        defaultValue={vehicleDetails && vehicleDetails.current_meter_value ? vehicleDetails.current_meter_value : 0}
                        required={true}
                        onChange={onEndingKmsChange}
                    />
                </div>
                <p>Please double check the odometer value in the vehicle as part of the cancellation process.</p>
            </>
        ) : (
            <div style={{ height: '5vh' }}>
                <Elliptical />
            </div>
        )}
        {statusLoading === false || statusLoading === null  // not null and not false means at least 1 req was made
            ? <>
                <div style={{ margin: '2.5vh 0vw' }}>
                    <Label text={'Fleetio status for outgoing vehicle'} requiredFieldInd={true} />
                    <DropDownMenu
                        menuName={'fleetioStatus'}
                        required={true}
                        defaultVal={dropDownPleaseSelect}
                        choices={statuses
                            ? statuses.map((item: IFleetioStatus) => ({
                                label: item.name,
                                value: item.id,
                                enabled: true
                            }))
                            : []}
                        onSelect={(event: ChangeEvent<HTMLSelectElement>) => onSelectStatus(event)}
                    />
                </div>
            </>
            : <div style={{ height: '5vh' }}><Elliptical /></div>
        }

        <div style={{ margin: '2.5vh 0vw', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>
                <Label text={'Notice given'} styleCfg={{ bold: true }} />
            </div>
            <p style={{ margin: 0 }}>{cancellationDetails?.noticeGiven === null ? 'N/A' : convertDateFormat(cancellationDetails?.noticeGiven)}</p>
        </div>


        <div style={{ margin: '2.5vh 0vw' }}>
            <Label text={'Contract end date'} requiredFieldInd={true} />
            <DatePicker
                name='contractEndDate'
                data-testid="contractEndDate"
                defaultVal={getDateString(new Date())}
                required={true}
                onChange={onCancellationDateChange}
            />
        </div>

        <div style={buttonContainerStyle}>

            <Button label={"Next"} onClickFunc={handleButtonClick} disabled={isButtonDisabled}></Button>

        </div>
    </>);
};