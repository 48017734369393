import React, { MouseEventHandler } from "react";

import InfoModalContainer from "../Container";
import WarningBox from "@components/cards/messageBox/WarningBox";
import Button from '@components/buttons/Button/Button';


interface IWarning {
    message: string
    additionalInfo: string
    onClickClose: MouseEventHandler
};


export default function Warning({ message, additionalInfo, onClickClose }: IWarning) {
    return (
        <InfoModalContainer>
            <WarningBox message={message} />
            {additionalInfo}
            <Button label='Ok' onClickFunc={onClickClose} />
        </InfoModalContainer>
    );
};
