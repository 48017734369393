import { UserFormStates, useUserJourneyContext } from '@context/UserJourneyContext';
import React, { ChangeEvent, useEffect } from 'react';
import Label from '@components/inputs/text/Label';
import DropDownMenu, { IChoice } from '@components/inputs/DropDownMenu';
import evIncentives from "../../SmartOpsHome/evIncentives";
import plans from "../../SmartOpsHome/evIncentivePlans";

import { BreadcrumbProps } from 'SmartOpsHome/breadcrumbObject';


/**
 * View intended to be integrated into the Flexi Own onboarding form.
 * 
 * All inputs are stored into the corresponding section of the User context.
 * 
 * All inputs default values are taken from the context. All information 
 * related to the driver should've been loaded by the time the user gets
 * to this page. If not, it is not a critical issue, but the user has 
 * to manually write all of them.
 */
export default function NSWEVSubscriptions({ breadcrumbObject }: BreadcrumbProps) {

  const { formState, updateFormState } = useUserJourneyContext();
  const { contractDetails } = formState;

  const dropDownPleaseSelect: IChoice = { label: '- please select -', value: '', enabled: false };


    const containerStyle: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        height: '50vh', // need to set dynamically
      };
      
      const rowStyle: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
      };
      
      const itemStyle: React.CSSProperties = {
        flex: 1,
        padding: '10px',
        boxSizing: 'border-box',
      };
      
      const textStyle: React.CSSProperties = {
        ...itemStyle,
        fontWeight: 'bold',
      };
      
      const numberStyle: React.CSSProperties = {
        ...itemStyle,
        textAlign: 'right',
      };

      function calculateIncentiveDiscount(withoutIncentive: number, withIncentive: number): number {
        return withoutIncentive - withIncentive
      }

      function setIncentiveValues(selectedLabel: string, selectedValue: string) {
    
        const selectedIncentives = evIncentives.find(item => item.name === selectedLabel && item.term == selectedValue);
    
        if (selectedIncentives) {
            const { withoutIncentive, withIncentive, incentive } = selectedIncentives;
            updateFormState(UserFormStates.contractDetails, {
              hasNSWAddendum: true, 
              weeklySubWithoutIncentive: withoutIncentive, 
              incentiveDiscount: incentive, 
              weeklySubWithIncentive: withIncentive}, )
            return { withoutIncentive, withIncentive, incentive };
        }
    
        return null
    }

    useEffect(() => {
      breadcrumbObject['NSW EV Subscriptions']['Weekly Subs without Incentive'] = contractDetails.weeklySubWithoutIncentive;
      breadcrumbObject['NSW EV Subscriptions']['NSW Goverment Incentive'] = contractDetails.incentiveDiscount;
      breadcrumbObject['NSW EV Subscriptions']['Weekly Subs with Incentive'] = contractDetails.weeklySubWithIncentive;
    }, [contractDetails])

        return (
          <><p style={{ margin: '2.5vh 0' }}> NSW EV Subscription Only: As a successful recipient of the 'Round 2 NSW EV Fleet Incentive', Splend is able to offer our customers a discounted rate on their weekly EV subscription. Please confirm the following details of the incentive.</p>
          <div style={{ margin: '2.5vh 0' }}>
          <Label
                text={'Select Incentive Plan'}
                requiredFieldInd={true}
            />
            <DropDownMenu
                    menuName='incentives'
                    defaultVal={dropDownPleaseSelect}
                    required={true}
                    choices={[
                      dropDownPleaseSelect,
                      ...plans
                          .filter(item => item.value.includes(contractDetails.planLen.toString())) // filter to only show incentives with matching plan length
                          .map((item: any) => ({ label: item.label, value: item.value, enabled: true }))
                  ]}
                    onSelect={(e: ChangeEvent<HTMLSelectElement>) => {
                      const selectedLabel = e.target.options[e.target.selectedIndex].text;
                      const selectedValue = e.target.value;
                      setIncentiveValues(selectedLabel, selectedValue);
                    }
                  }
                />
              
            <div style={rowStyle}>
              <div style={textStyle}>Weekly subscription without incentive ($)</div>
              <div style={numberStyle}>{contractDetails.weeklySubWithoutIncentive}</div>
            </div>
      
            <div style={rowStyle}>
              <div style={textStyle}>NSW government EV Fleet incentive ($)</div>
              <div style={numberStyle}>{calculateIncentiveDiscount(contractDetails.weeklySubWithoutIncentive, contractDetails.weeklySubWithIncentive)}</div>
            </div>
      
            <div style={rowStyle}>
              <div style={textStyle}>Weekly subscription with incentive ($)</div>
              <div style={numberStyle}>{contractDetails.weeklySubWithIncentive}</div>
            </div>
          </div>
        
        </>
      );
    }
