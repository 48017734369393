import React, { CSSProperties, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import useServiceHook from "hooks/useServiceHook";
import { SubscriptionService } from "@services/index";

import SubscriptionsListPanel from '@components/panels/SubscriptionsList/SubscriptionsList';
import { flexiOwnUsedPlanNames, flexiOwnPlanNames, SubscriptionPlan, ravCreditHirePlanNames, ravShareCoverPlanNames, ravShortTermPlanNames } from "@utils/constants/products";
import { NavBar } from "@components/navigation/NavBar/NavBar";
import { ISubscriptionSummaryItem } from "@components/cards/SubscriptionSummary/SubscriptionSummary";
import CircularSpinner from "@components/spinners/Circular/Circular";
import { InfoBox } from '@components/cards/messageBox';
import * as Sentry from "@sentry/react";
import breadcrumbObject from "SmartOpsHome/breadcrumbObject";


export default function SubscriptionsList() {
    const navigate = useNavigate();
    const { custId, country } = useParams();

    Sentry.setTag("Journey", "Payment Holiday")
    Sentry.setTag("timed", true)
    
    useEffect(() => {
        breadcrumbObject['Payment Holidays']['Payment Holiday Start Time'] = new Date();
    })

    // loading and error SHALL NOT be true in the same time
    const [loading, error, errMessage, results] = useServiceHook(SubscriptionService.getSubscriptions, [custId, country]);

    const parseSubsPlan = (subs: ISubscriptionSummaryItem[]) => {  // assign standard plan name to each subscription item
        return subs.map((subs: ISubscriptionSummaryItem) => {
            if (flexiOwnPlanNames.includes(subs.plan.toLowerCase())) { subs.plan = SubscriptionPlan.flexiOwn; }
            else if (flexiOwnUsedPlanNames.includes(subs.plan)) {subs.plan = SubscriptionPlan.flexiOwnUsed; }
            else if (ravCreditHirePlanNames.includes(subs.plan)) {subs.plan = SubscriptionPlan.ravCreditHire; }
            else if (ravShareCoverPlanNames.includes(subs.plan)) {subs.plan = SubscriptionPlan.ravShareCover; }
            else if (ravShortTermPlanNames.includes(subs.plan)) {subs.plan = SubscriptionPlan.ravShortTerm; }
            else { subs.plan = SubscriptionPlan.flexi; }
            return subs;
        });
    }

    const selectSubscriptionLabelStyle: CSSProperties = {
        margin: '0',  // set to 0 to mitigate p tag's default margin value
        padding: '2vh 0 2vh 2.5vw',
        color: '#2C2A2D',
        fontWeight: '700',
        borderBottom: '1px solid #C4C4C4'
    };

    const subscriptions = loading === false ? parseSubsPlan(results) : [];

    return (<>
        <NavBar pageTitle="Payment holiday" />
        <p style={selectSubscriptionLabelStyle}>
            Select subscription
        </p>
        {loading === true
            ? <LoadingSpinner />
            : subscriptions.length > 0
                ? <SubscriptionsListPanel
                    keyName='payment-holidays-subscriptions'
                    items={subscriptions.map((subs: ISubscriptionSummaryItem) => {
                        return {
                            subsId: subs.subsId,
                            startDate: subs.startDate,
                            carDetails: subs.carDetails,
                            registration: subs.registration,
                            plan: subs.plan,
                            status: subs.status,
                            onClickFn: subs.status
                                ? () => navigate(`${subs.subsId}`)
                                : undefined
                        }
                    })}
                />
                : <NoSubscriptionsInfoNote />
        }
        {error && <div>{errMessage}</div>}
    </>);
};


const LoadingSpinner = () => (
    <div style={{ display: 'flex', justifyContent: 'center', margin: '15vh' }}>
        <CircularSpinner />
    </div>
);


const NoSubscriptionsInfoNote = () => (
    <div style={{ margin: '1.5vh 3.5vw' }}>
        <InfoBox message='Please note that the customer does not have any subscriptions on their record' />
    </div>
);
