import {getWithIDToken, IMSTokenParams} from "@utils/http/MSAuthAPI";

export default async (criteria: queryCriteria,
                      itemsPerPage: number,
                      offset: number,
                      IDTokenParams: IMSTokenParams): Promise<any> => {
    return await getWithIDToken(
        '/v1/customers/search',
        IDTokenParams,
        {...criteria, limit: itemsPerPage, offset},
    )
};


type queryCriteria = {
    search_string: string,
    country: string
}
