import React, {useState} from 'react';

import BrowseFilesButton, {FileEvent} from '@components/buttons/BrowseFilesButton';
import FileRemoveControl from './FileRemoveControl';

interface UploadFilePanelProps {
    /**
     * File extensions to allow a User to select.
     * Example: '.pdf, .png'
     */
    allowedTypes?: string;

    /**
     * Raised when a file is selected or deselected.
     * @param e FileEvent containing file property (either File or undefined)
     */
    onSelect?: (e: FileEvent) => void;
}

export default function UploadFilePanel(props: UploadFilePanelProps) {
    const [fileSelected, setFileSelected] = useState<File>();
    const [clearBrowseCache, setClearBrowseCache] = useState<boolean>();

    const onSelectFrontLicenseHandler = (event: FileEvent) => {
        setFileSelected(event.file); // now render the file (or not if undefined)
        props.onSelect?.(event); // call the parent, file selected or deselected
    };

    const onRemoveFrontLicenseHandler = () => {
        setFileSelected(undefined);
        setClearBrowseCache(true);
        props.onSelect?.({ file: undefined }); // call the parent, file deselected
    };

    const onClearBrowseFilesHandler = () => {
        setClearBrowseCache(false);
    };

    const renderFileControl = () => {
        return (
            <>
                <br />
                <FileRemoveControl fileName={fileSelected?.name} onRemove={onRemoveFrontLicenseHandler} />
            </>
        );
    };

    return (
        <>
            <BrowseFilesButton
                allowedTypes={props.allowedTypes}
                clearCache={clearBrowseCache}
                onCacheCleared={onClearBrowseFilesHandler}
                onClick={onSelectFrontLicenseHandler}
            />
            {fileSelected && renderFileControl()}
        </>
    );
}
