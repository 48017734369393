export interface IChoice {
    value: string
    label?: string
    enabled: boolean
};

export const discountedWeeks: IChoice[] = Array.from({ length: 208 }, (_, i) => ({
    value: (i + 1).toString(),
    label: (i + 1).toString(),
    enabled: true,
}));