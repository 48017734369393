import React from 'react';
import {useNavigate, useParams} from "react-router-dom";

import SimpleMenu from "@components/navigation/SimpleMenu/SimpleMenu";
import PersonIcon from "@assets/images/icon_person_plus.svg";
import HomeIcon from "@assets/images/home.svg";
import CreditCardIcon from "@assets/images/credit_card.svg";
import CarIcon from '@assets/images/car_icon.png';
import ReturnIcon from "@assets/images/return.svg";
import OffboardIcon from "@assets/images/offboarding.svg";
import {Country} from '@utils/constants/localisation';


export default function CustomerMenu() {
    const { custId, country } = useParams() as { custId: string; country: string; };
    const navigate = useNavigate();

    return (
        <SimpleMenu
            name='member-overview'
            menuItems={[
                {
                    label: 'Book and manage test drives',
                    onClickFunc: () => navigate(`/test-drive/${country}/${custId}`),
                    iconSrc: CarIcon,
                },
                {
                    label: 'Onboarding',
                    onClickFunc: () => navigate(`/customer/${country}/${custId}/onboarding`),
                    iconSrc: PersonIcon,
                },
                {
                    label: 'Update personal details',
                    onClickFunc: () => navigate(`/customer/${country}/${custId}/update-customer-details`),
                    iconSrc: HomeIcon,
                },
                {
                    label: 'Billing and payments',
                    onClickFunc: () => navigate(`/customer/${country}/${custId}/payments-menu`),
                    iconSrc: CreditCardIcon
                },
                {
                    label: 'Vehicle exchange',
                    onClickFunc: () => console.log('Go to vehicle exchange'),
                    iconSrc: ReturnIcon,
                    isDisabled: true
                },
                {
                    label: 'Offboarding',
                    onClickFunc: () => navigate(`/cancellation/${country}/${custId}`),
                    iconSrc: OffboardIcon,
                     isDisabled: country === Country.AU ? false : true
                },
            ]}
        />
    )
};
