import React, { MouseEventHandler, ChangeEvent, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import useServiceHook from 'hooks/useServiceHook';
import { SubscriptionDetailsPanel } from '@components/panels/SubscriptionDetailsPanel';
import { NavBar } from '@components/navigation/NavBar/NavBar';
import { SubscriptionService } from '@services/index';
import { InfoBox, WarningBox } from '@components/cards/messageBox';
import CircularSpinner from "@components/spinners/Circular/Circular";
import EllipticalSpinner from "@components/spinners/Elliptical/Elliptical";
import { PaymentHoliday } from '@components/panels/PaymentHoliday/PaymentHoliday';
import { Country } from '@utils/constants/localisation';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import ModalContainer from '@components/modal/Container';
import WarningModal from '@components/modal/info/Warning';
import ConfirmationModal from '@components/modal/info/Confirmation';
import PaymentHolidayService, { IStartDate, IPaymentHolidayEntitlement, IPaymentHolidayBooking, IPaymentHolidaySummary, IBooking } from '@services/subscription/PaymentHolidayService';
import { PanelItem } from '@components/panels/PanelItems';
import DropDownMenu, { IChoice } from '@components/inputs/DropDownMenu';
import { createIntList, getTimeDifference } from '@utils/misc/functions';
import Label from '@components/inputs/text/Label';
import SubmitBtn from '@components/buttons/FormButtons/SubmitBtn';
import InfoModalContainer from "@components/modal/Container";
import Button from '@components/buttons/Button/Button';
import {msalInstance} from 'SmartOpsHome';
import * as Sentry from "@sentry/react";
import breadcrumbObject from "SmartOpsHome/breadcrumbObject";
import UserAccessService from '@services/userAccess/userAccessService';

const ForegroundLoadingSpinner = () => (
    <ModalContainer stylesCfg={{ windowTransparency: true }} >
        <LoadingSpinner />
    </ModalContainer>
)

const LoadingSpinner = () => (
    <div style={{ display: 'flex', justifyContent: 'center', margin: '15vh' }}>
        {<CircularSpinner />}
    </div>
)


const PaymentHolidayBalance = ({ entitlements } : { entitlements: IPaymentHolidayEntitlement[]}) => {
    const noEntitlementMsg = 'No holiday entitlements available';

    return (
        <div style={{ margin: '4vh 2vw' }}>
            <p style={{ fontWeight: '700' }}>
                Balance
            </p>
            {
                entitlements.length > 0
                ?   
                    entitlements.map((entitlement : IPaymentHolidayEntitlement, index : number) => (
                        <div style={{ paddingTop: '1.5vh' }} key={`paymentHolidayEntitlement-${index}`}>
                            <PanelItem key={`paymentHolidayEntitlement-${index}-1`} header='Entitlement Date' value={entitlement.entitlementDate} />
                            <PanelItem key={`paymentHolidayEntitlement-${index}-2`} header='Unused payment holiday balance' value={entitlement.availableWeeks} />
                        </div>
                        )
    
                    )
                
                : <div style={{ marginTop: '2vh' }}><InfoBox message={noEntitlementMsg} /></div>
            }
        </div>
    )
}

// Copied from YearOverview
const PaymentHolidayHistory = ({ holidays } : any) => {
    const noHolidaysHistoryMsg = 'No payment holiday history';
        
    return (
        <div style={{ margin: '4vh 2vw' }}>
            <p style={{ fontWeight: '700' }}>
                Payment holiday history
            </p>
            {holidays.length > 0
                ? <PaymentHoliday holidays={ holidays } />
                : <div style={{ marginTop: '2vh' }}><InfoBox message={noHolidaysHistoryMsg} /></div>
            }
        </div>
    );
}

// Copied from YearOverview
const PaymentHolidaySchedule = ({ holidays, summary, allowedAccess } : {holidays: IPaymentHolidayBooking[], summary: IPaymentHolidaySummary, allowedAccess: boolean}) => {
    const noScheduledPHMsg = 'No payment holidays scheduled';
    const cannotDeleteMsg = 'Cannot delete due to time restrictions';

    // null: server request not issued
    // true: server request issued and successful
    // false: server request issued and failed
    const [successResp, setSuccessResp] = useState<boolean | null>(null);

    // ongoingReq - true: a request has been sent to the backend and waiting for response
    // ongoingReq - false: there's no ongoing backend request
    const [ongoingReq, setOngoingReq] = useState<boolean>(false);

    // used here to avoid changing the useServiceHook; temporary usage until better implementation
    const msalContext = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const modalOnClick = (event: any) => {
        event.preventDefault();
        setSuccessResp(null);
        window.location.reload();
    };

    const onClickDelete = async (subsId: string, country: string, startDate: string, noWeeks: number) => {
        setOngoingReq(true);
        
        await PaymentHolidayService.deletePaymentHolidayBooking(
            subsId, country, startDate, noWeeks, 
            { msalContext, isAuthenticated }
        ).then((res) => {   
                            console.log(res);
                            res?.deleted.length === 0 ? setSuccessResp(false) : setSuccessResp(true) ;
                        }
        ).catch(() => {
                              setSuccessResp(false)
                        });

        setOngoingReq(false);
    }

    const enhancedHolidays = holidays.map((item) => ({
        id: item.id,
        startDate: item.startDate,
        noWeeks: item.noWeeks.toString(),
        deletable: allowedAccess ? item.delete : false,
        onDeleteClick: (
            item.delete
                ? () => onClickDelete(summary.subscriptionId, summary.country, item.startDate, item.noWeeks)
                : () => { }
        ),
        warningMsg: item.delete ? '' : allowedAccess ? cannotDeleteMsg : ''
    }));

    return (
        <>
            <div style={{ margin: '4vh 2vw' }}>
                <p style={{ fontWeight: '700' }}>
                    Scheduled payment holidays
                </p>
                {
                    holidays.length > 0
                    ? <PaymentHoliday holidays={enhancedHolidays} />
                    : <div style={{ marginTop: '2vh' }}><InfoBox message={noScheduledPHMsg} /></div>
                }
            </div>
            {ongoingReq === true && <ForegroundLoadingSpinner />}
            {successResp !== null &&
                (
                    successResp === true
                    ? <ConfirmationModal 
                        message={'Your request has been processed.'}
                        onClickClose={modalOnClick}
                    />
                    : <WarningModal
                        message={'Something went wrong'}
                        additionalInfo={'We’re sorry, we were unable to process your request. Try refreshing the page or contact IT Support for assistance'}
                        onClickClose={modalOnClick}
                    />
                )
            }
        </>
    );
}


const ValidationMessage = ({ message, conflicts, maxWeeksAllowed, maxWeeksAllowedTimePeriod, noWeeks, nextAvailableDate, onClickClose }: {message: string, conflicts: any, maxWeeksAllowed: number, maxWeeksAllowedTimePeriod: number, noWeeks?: number, nextAvailableDate?: string, onClickClose: MouseEventHandler}) => {
    return (
        <InfoModalContainer stylesCfg={{height: 'auto', padding: '2vh 1vw'}}>
            <WarningBox message={message} />
            <p>Customers cannot take more than {maxWeeksAllowed} weeks payment holiday within any {maxWeeksAllowedTimePeriod} week period.</p>
            <p>The following payment holiday(s) conflict with the requested dates:</p>

            <div style={{ margin: '1vh 10vw' }}>
                {
                    conflicts.length > 0
                    ? <PaymentHoliday holidays={ conflicts } />
                    : undefined
                }
            </div>
            
            {
                nextAvailableDate 
                ? <p>The next available slot for taking a {noWeeks} week payment holiday is {nextAvailableDate} </p> 
                : undefined
            }

            <Button label='Ok' onClickFunc={onClickClose} />
        </InfoModalContainer>
    );
}

// Copied from YearOverview
const BookingForm = ({ subsId, country, startDates, subscriptionStartDate, subscriptionTermLength, allowedAccess }: {subsId: string, country: string, startDates: IChoice[], subscriptionStartDate?: string, subscriptionTermLength?: number, allowedAccess: boolean}) => {
    console.log(`allowedAccess = ${allowedAccess}`)
    // used here to avoid changing the useServiceHook; temporary usage until better implementation
    const msalContext = useMsal();
    const isAuthenticated = useIsAuthenticated();

    // null: server request not issued
    // true: server request issued and successful
    // false: server request issued and failed
    const [httpResp, setHttpResp] = useState<any | null>(null);
    const [successResp, setSuccessResp] = useState<boolean | null>(null);

    // ongoingReq - true: a request has been sent to the backend and waiting for response
    // ongoingReq - false: there's no ongoing backend request
    const [ongoingReq, setOngoingReq] = useState<boolean>(false);

    // The value has been mutated here as the select option in the dropdown component was unable to distinguish between identical values. i.e. if
    // there were four values that had a value of 4 ph weeks available then it would keep selecting the first value with the incorrect date. Here
    // we assign each week a unique value so it can distinguish and pick the selected choice.
    const uniqueValueStartDateList = startDates.map(item => ({ label: item.label, value: `${item.value}_${item.label}`, enabled: item.enabled }));
    // The start date chosen from the dropdown
    const [selectedStartDate, setSelectedStartDate] = useState<IChoice | undefined>(undefined);

    const defaultNumberOfWeeksAvailable = createIntList(1, 0).map(item => ({ value: `${item}`, enabled: true })); // have a blank list initially
    const [numberOfWeeksAvailable, setNumberOfWeeksAvailable] = useState<IChoice[]>(defaultNumberOfWeeksAvailable)

    const modalOnClick = (event: any) => {
        event.preventDefault();
        setSuccessResp(null);
        window.location.reload();
    };

    // Checks the selected date from the dropdown and populates the number of weeks dropdown with
    // the corresponding weeks available
    const onStartDateChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const string = event.target.options[event.target.selectedIndex].text;
        const selectedChoiceLabel = uniqueValueStartDateList.find(choice => choice.label === string);

        if (selectedChoiceLabel) {
            const maxWeeks = Number(selectedChoiceLabel.value.split('_')[0]); // split the number of weeks from the date to create a unique identifier
            const updatedChoices = createIntList(1, maxWeeks).map(item => ({ value: `${item}`, enabled: true }));
            setSelectedStartDate(selectedChoiceLabel);
            setNumberOfWeeksAvailable(updatedChoices);
        }
    };

    const MenuOption = ({ labelTxt, menuName, choices, value, onSelect }: { labelTxt: string, menuName: string, choices: IChoice[], value?: IChoice, onSelect?: (selectedValue: ChangeEvent<HTMLSelectElement>) => void }) => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Label text={labelTxt} styleCfg={{ largeFont: false, bold: false }} />
            <DropDownMenu
                menuName={menuName}
                defaultVal={value}
                required={true}
                choices={choices.filter(choice => choice.enabled)} // filter out "False" or disabled PHs so they are not available in the dropdown
                onSelect={onSelect}
            />
        </div>
    );

    const SubmitButton = ({disabled} : { disabled?: boolean}) => (
        <div style={{ height: '5vh' }}>
            <SubmitBtn text='Submit' disabled={disabled} />
        </div>
    );
    const selectedStartDateLabel = selectedStartDate?.label || "01-01-2000";

    const onSubmitAction = async (event: any) => {
        event.preventDefault();
        setOngoingReq(true);

        breadcrumbObject['Payment Holidays']['Payment Holiday End Time'] = new Date();
        Sentry.captureMessage(`Total time taken for add payment holidays: ${getTimeDifference(breadcrumbObject['Payment Holidays']['Payment Holiday Start Time'], breadcrumbObject['Payment Holidays']['Payment Holiday End Time'])}`);

        const userDetails = msalInstance.getActiveAccount();
        console.log(userDetails?.name)

        await PaymentHolidayService.createPaymentHolidayBooking(
            subsId, country,
            selectedStartDateLabel,
            event.currentTarget.PHWeeksNo.value,
            { msalContext, isAuthenticated },
            //subscriptionStartDate,
            //subscriptionTermLength,
            userDetails?.name
        ).then((res) => {
                            console.log(res);
                            setHttpResp(res);
                            res?.created.length === 0 ? setSuccessResp(false) : setSuccessResp(true) ;
                        }
        ).catch(() => setSuccessResp(false));  // request sent and unsuccessful => set resp false => triggering Warning Modal

        setOngoingReq(false);
    };

    return (<>
        <form onSubmit={onSubmitAction}>
            <div style={{ marginTop: '1.5vh', display: 'flex', flexDirection: 'column', gap: '2.5vh' }}>
                <MenuOption
                    labelTxt='Start date'
                    menuName='PHStartDate'
                    choices={uniqueValueStartDateList}
                    value={selectedStartDate}
                    onSelect={(e: ChangeEvent<HTMLSelectElement>) => onStartDateChange(e)}
                />
                <MenuOption
                    labelTxt='Number of weeks'
                    menuName='PHWeeksNo'
                    choices={numberOfWeeksAvailable}
                />
                <SubmitButton disabled={!allowedAccess} />
            </div>
        </form>
        {successResp !== null &&
            (
                successResp === true
                ? <ConfirmationModal
                    message={'Your request has been processed.'}
                    additionalInfo={'Confirmation email has been sent to the customer.'}
                    onClickClose={modalOnClick}
                />
                :   
                    httpResp?.conflicts
                    ? <ValidationMessage
                        message={'Please try again'}
                        conflicts={httpResp.conflicts}
                        noWeeks={httpResp.input.noWeeks}
                        nextAvailableDate={httpResp.nextAvailableDate}
                        maxWeeksAllowed={httpResp.scheme.maxWeeksAllowed}
                        maxWeeksAllowedTimePeriod={httpResp.scheme.maxWeeksAllowedTimePeriod}
                        onClickClose={modalOnClick}
                    />
                    : <WarningModal
                        message={'Something went wrong'}
                        additionalInfo={'We’re sorry, we were unable to process your request. Try refreshing the page or contact IT Support for assistance'}
                        onClickClose={modalOnClick}
                    />
            )
        }
        {ongoingReq === true && <ForegroundLoadingSpinner />}
    </>
    );
}



// Copied from YearOverview
const PaymentHolidayBookingForm = ({ subsId, country, weeksAllowed, startDates, eligibilityReportUrl, warningMsg, infoMsg, subscriptionStartDate, subscriptionTermLength, allowedAccess }: 
    { subsId: string, country: string, weeksAllowed: number, startDates: IStartDate[], eligibilityReportUrl: string, warningMsg?: string, infoMsg?: string, subscriptionStartDate?: string, subscriptionTermLength?: number, allowedAccess: boolean } ) => {

    const parsedStartDates = startDates.map(date => ({ label: date.startDate, enabled: date.enabled, value: `${date.weeksAllowed}` }));

    // if number of weeks allowed < 4 then limit number of ph allowed the further down the list you go i.e 2 dates if select first then get two weeks if select second get 1
    const displayWarningMsg = warningMsg !== undefined;
    const displayInfoMsg = infoMsg !== undefined;
    const displayBookingForm = weeksAllowed !== 0;
    console.log(weeksAllowed)
    console.log(displayBookingForm)

    const eligibilityMessage =  <>
                                Before booking a payment holiday for this customer, please confirm they are eligible for payment holidays 
                                by checking <a href={eligibilityReportUrl} target="_blank" rel="noreferrer">the eligibility report</a>
                                </>

    return (
        <div style={{ margin: '4vh 2vw' }}>
            <p style={{ fontWeight: '700' }}>
                Book payment holiday
            </p>
            {<div style={{ marginTop: '2vh' }}><WarningBox message={eligibilityMessage} /></div>}
            {displayWarningMsg && <div style={{ marginTop: '2vh' }}><WarningBox message={warningMsg} /></div>}
            {displayInfoMsg && <div style={{ marginTop: '2vh' }}><InfoBox message={infoMsg} /></div>}

            {displayBookingForm &&
                <BookingForm
                    subsId={subsId}
                    country={country}
                    startDates={parsedStartDates}
                    subscriptionStartDate={subscriptionStartDate}
                    subscriptionTermLength={subscriptionTermLength}
                    allowedAccess={allowedAccess}
                />
            }
        </div>
    );
}

export default function PaymentHolidayOverview() {
    const { subsId, country } = useParams() as { subsId: string, country: Country };

    //const [subscriptionStartDate, setSubscriptionStartDate] = useState<string | undefined>(undefined);
    //const [subscriptionTermLength, setSubscriptionTermLength] = useState<number | undefined>(undefined);
    const [userAccessLoading, userAccessError, userAccessErrMsg, allowedAccess] = useServiceHook(
        UserAccessService.paymentHolidayUserAccess,
        []
    );
            
    const [subsLoading, subsError, subsErrMsg, subsDetails] = useServiceHook(
        SubscriptionService.getSubscriptionById,
        [subsId, country]
    );

    /*
    useEffect(() => {
        if (subsDetails) {
            setSubscriptionStartDate(subsDetails.startDate);
            setSubscriptionTermLength(subsDetails.termLength);
        }
    },
    [subsDetails]);
    */

    const [loading, error, errMsg, result] = useServiceHook(
        PaymentHolidayService.getPaymentHolidayDetails,
        [ subsId, country ]
        /*
        ,
        //subscriptionStartDate && subscriptionTermLength  ? true : false,  // only run the API call when subscriptionStartDate and subscriptionTermLength are available
        subsDetails ? true : false,  // only run the API call when subscriptionStartDate and subscriptionTermLength are available
        [ subscriptionStartDate, subscriptionTermLength ], // dependent on subscriptionStartDate and subscriptionTermLength
        
        //    Optional params, the below values are available from another API call (SubscriptionService.getSubscriptionById) 
        //    but if in the future it's not available any more than we can remove them. The backend should have code to check if 
        //    they are passed in otherwise it needs to make the API call to get the values.
        undefined,
        [ subscriptionStartDate, subscriptionTermLength ]
         */
    );
   
    
    const PaymentHolidaySections = ({ entitlements, holidays, summary, booking, allowedAccess }:
        { entitlements: IPaymentHolidayEntitlement[], holidays: IPaymentHolidayBooking[], summary: IPaymentHolidaySummary, booking: IBooking, allowedAccess: boolean }
    ) => {
            
        return (<>
                    <hr style={{ color: '#C4C4C4' }} />
                    {
                        !allowedAccess ?
                        <div style={{ margin: '4vh 2vw' }}>
                            <InfoBox message="You only have read access to Payment Holidays." />
                        </div>
                        : undefined
                    }
                    
                    <div style={{ margin: '4vh 2vw' }}>
                        <InfoBox message={`Customer accumulates ${summary.holidayEarnRateInWeeks.toString()} weeks payment holiday every ${summary.holidayEarnFrequencyInWeeks.toString()} weeks`} />
                    </div>
                    
                    <PaymentHolidayBalance entitlements={entitlements}/>

                    <PaymentHolidayHistory
                        holidays={holidays.filter((item: any) => item.inFuture === false)}
                    />

                    <PaymentHolidaySchedule
                        holidays={holidays.filter((item: any) => item.inFuture === true)}
                        summary={summary}
                        allowedAccess={allowedAccess}
                    />

                    <PaymentHolidayBookingForm
                        subsId={subsId}
                        country={Country[country]}
                        weeksAllowed={booking.weeksAllowed}
                        startDates={booking.startDates}
                        eligibilityReportUrl={booking.eligibilityReportUrl}
                        warningMsg={booking.warningMsg}
                        infoMsg={booking.infoMsg}
                        //subscriptionStartDate={subscriptionStartDate}
                        //subscriptionTermLength={subscriptionTermLength}
                        allowedAccess={allowedAccess}
                    />
                </>
            );
        }

    return (
        <>
            <NavBar pageTitle="Subscription overview" />

            {
                <SubscriptionDetailsPanel
                    rentalAgreement={subsLoading !== false ? <EllipticalSpinner /> : subsDetails.rentalAgreement}
                    startDate={subsLoading !== false ? <EllipticalSpinner /> : subsDetails.startDate}
                    registration={subsLoading !== false ? <EllipticalSpinner /> : subsDetails.registration}
                    plan={subsLoading !== false ? <EllipticalSpinner /> : subsDetails.plan}
                    termLength={subsLoading !== false ? <EllipticalSpinner /> : subsDetails.termLength ? `${subsDetails.termLength} years` : '-'}
                    make={subsLoading !== false ? <EllipticalSpinner /> : subsDetails.make}
                    model={subsLoading !== false ? <EllipticalSpinner /> : subsDetails.model}
                    email={subsLoading !== false ? <EllipticalSpinner /> : subsDetails.email}
                />
            }    

            {
                (loading !== false || userAccessLoading !== false)
                ? <LoadingSpinner />
                // nothing to show if there's an error since the SrvErrContext will display a UI info msg
                : error !== true && PaymentHolidaySections({ ...result, allowedAccess })
            }
        </>
    )
   
}