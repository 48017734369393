import Label from "@components/inputs/text/Label";
import { UserFormStates, useUserJourneyContext } from "@context/UserJourneyContext";
import useServiceHook from "@hooks/useServiceHook";
import SubscriptionService from "@services/subscription/SubscriptionService";
import { countrySpecificCurrency } from "@utils/misc/functions";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import CircularSpinner from "@components/spinners/Circular/Circular";
import InfoBox from "@components/cards/messageBox/InfoBox";
import Button, { ButtonStyle } from "@components/buttons/Button/Button";
import DropDownModal from "./DropdownModal";
import DynamicTable, { TableRow } from "@components/Tables/addAncillaryTable";
import { textBoxStyle } from '@utils/styles/textboxStyle';
import { NavBar } from "@components/navigation/NavBar/NavBar";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { PlanOptions, splendInitiativesOptions } from "@utils/planOptions";
import NumericInput from "@components/inputs/NumericInput";

export default function CancellationCharges() {
    const { formState, updateFormState } = useUserJourneyContext();
    const { cancellationDetails, driverDetails, contractDetails, splendInitiatives, carInfo } = formState;

    const [tableData, setTableData] = useState<TableRow[]>([]);
    const [ancillaryCharges, setAncillaryCharges] = useState<number>(0);
    const [totalCharges, setTotalCharges] = useState<number>(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const navigate = useNavigate();

    const msalContext = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const [addonsError, setAddonsError] = useState(false);
    const [addonsErrMsg, setAddonsErrMsg] = useState("");
    const [addonsDetails, setAddonsDetails] = useState<any>(null)
    const [addonsLoading, setAddonsLoading] = useState(true);

    const cancellationRounding = 2

    // Define the types for chargesDetails and driverDetails
    interface ChargesDetails {
        noticePeriodCharges: number;
        dailyFee: number;
        weeklyFee: number;
        minimumTermCharges: number;
        phTotalCharge: number;
        usageCharge: number;
        adminFee: number;
        state: string;
        acceptanceAndRemarketing?: number;
        startOdometer?: number;
        finishOdometer?: number;
        drivenDistance?: number;
        phWeeksTaken?: number;
        phTotalContribution?: number;
        minimumDaysToCharge?: number;
        noticeDaysToCharge?: number;
    }

    interface DriverDetails {
        country: string;
    }

    interface NoticePeriodChargesProps {
        chargesDetails: ChargesDetails;
        driverDetails: DriverDetails;
        handleNoticePeriodChargeChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    }

    interface MinimumPeriodChargesProps {
        chargesDetails: ChargesDetails;
        driverDetails: DriverDetails;
        handleMinimumPeriodChargeChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    }

    interface AcceptanceAndRemarketingChargesProps {
        chargesDetails: ChargesDetails;
        driverDetails: DriverDetails;
        handleAcceptanceRemarketingChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    }

    interface PaymentHolidayChargesProps {
        chargesDetails: ChargesDetails;
        driverDetails: DriverDetails;
    }


    interface QParamObject {
        country: string;
        odometer: string;
        minimum_period_weeks?: string;
        fleetio_vehicle_id?: string;
    }

    // Initialize the qParamObject
    let qParamObject: QParamObject = {
        country: driverDetails.country,
        odometer: cancellationDetails.endingKms,
        fleetio_vehicle_id: carInfo.vehicleId
    };

    // Conditionally update qParamObject
    if (splendInitiatives.splendInitiative === splendInitiativesOptions.DynamicPricing) {
        qParamObject = {
            ...qParamObject,
            minimum_period_weeks: splendInitiatives.minimumPeriodWeeks,
        };
    }

    const [chargesLoading, chargesError, chargesErrMsg, chargesDetails] = useServiceHook(
        SubscriptionService.getCancellationCharges,
        [qParamObject, contractDetails.subscriptionId, carInfo.vehicleId],
        true,
        [],
        "Please check that all the required fields are present on the Chargebee plan: minimum period, notice period, price, plan type and state."
    );



    // As we need to get the state from the getCancellationCharges call this addons call needs to be wrapped in a 
    // useEffect so the chargesDetails are loaded from the getCancellationCharges call before this call is made
    useEffect(() => {
        if (chargesDetails) {

            if (chargesDetails.state) {

                const fetchAddons = async () => {
                    try {

                        const addonsData = await SubscriptionService.getAddons(
                            { country: driverDetails.country, state: chargesDetails.state, accounting_prefix: "208" },
                            { msalContext: msalContext, isAuthenticated: isAuthenticated }
                        );

                        setAddonsDetails(addonsData);
                        setAddonsLoading(false);
                    } catch (error) {
                        setAddonsError(true);
                        setAddonsErrMsg("error");
                        setAddonsLoading(false);
                    }
                };

                fetchAddons();

            }
        }
    }, [chargesDetails]);


    const LoadingSpinner = () => (
        <div style={{ display: 'flex', justifyContent: 'center', margin: '15vh' }}>
            <CircularSpinner />
        </div>
    );

    const UsageCappedInfoNote = () => (
        <div style={{ margin: '1.5vh 3.5vw' }}>
            <InfoBox message='Usage is charged $1,000 per 5,000km’s and capped at a maximum $5,000, leading to a total maximum fee of $6,500 ' />
        </div>
    );

    const calculateTotal = (tableData: any) => tableData.reduce((total: any, row: any) => total + row.total, 0);


    const renderChargeSection = (label: any, value: any) => (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>
                <Label text={label} styleCfg={{ bold: true }} />
            </div>
            <p style={{ margin: 0 }} >{value || ''}</p>
        </div>
    );

    const ravPlanTypes = [PlanOptions.RAVCreditHire, PlanOptions.CancellationShareCover, PlanOptions.RAVShortTermRental];

    const calculateEarlyCancellationCharge = () => {
        // A string is used instead of the PlanOptions Enum as when reading from the subscription card Flexi Own is used and not Splend Flexi-Own
        if (cancellationDetails.cancellationPlanType == PlanOptions.CancellationFlexiOwn) { return cancellationDetails.noticePeriodCharge + cancellationDetails.totalAcceptanceRemarketingFee }
        return cancellationDetails.minimumPeriodCharge + cancellationDetails.noticePeriodCharge
    }

    const validateAndSetDefault = (value: any, defaultValue: number = 0): number => {
        let newValue = parseFloat(value);
        if (isNaN(newValue) || newValue < 0 || newValue === null || newValue === undefined) {
            return defaultValue;
        }
        return newValue;
    };

    const handleNoticePeriodChargeChange = (e: any) => {
        const newValue = validateAndSetDefault(e.target.value);
        updateFormState(UserFormStates.cancellationDetails, { noticePeriodCharge: newValue });
    };

    const handleMinimumPeriodChargeChange = (e: any) => {
        let newValue = validateAndSetDefault(e.target.value);
        updateFormState(UserFormStates.cancellationDetails, { minimumPeriodCharge: newValue });
    };

    const handleAcceptanceRemarketingChange = (e: any) => {
        let newValue = validateAndSetDefault(e.target.value);
        updateFormState(UserFormStates.cancellationDetails, { acceptanceAndRemarketing: newValue });
        updateFormState(UserFormStates.cancellationDetails, { totalAcceptanceRemarketingFee: newValue });

    };

    useEffect(() => {
        const newAncillaryCharges = calculateTotal(tableData);
        setAncillaryCharges(newAncillaryCharges);

        const newEarlyCancellationCharges = calculateEarlyCancellationCharge();
        updateFormState(UserFormStates.cancellationDetails, { totalEarlyCancellationCharges: newEarlyCancellationCharges });
        let newTotalCancellationCharges = (newAncillaryCharges + newEarlyCancellationCharges);
        if (cancellationDetails.cancellationPlanType == PlanOptions.CancellationFlexiOwn && chargesDetails) {
            newTotalCancellationCharges = Number(newTotalCancellationCharges) + (Number(chargesDetails?.phTotalCharge))
        }
        setTotalCharges(newTotalCancellationCharges);
        updateFormState(UserFormStates.cancellationDetails, { totalCancellationCharges: newTotalCancellationCharges })
        updateFormState(UserFormStates.cancellationDetails, { totalAncillaryCharges: newAncillaryCharges.toFixed(cancellationRounding) });
        updateFormState(UserFormStates.cancellationDetails, { totalExtraCharges: newTotalCancellationCharges });

        // RAV Credit Hire and Share Cover do not have notice period, minimum term charges or payment holiday charges. However some customers who
        // were previously on Flexi Own subscriptions could still have ph charges on their CHB subscription so this may return a value. This is why these values
        // are manually overwritten.
        if (cancellationDetails.cancellationPlanType === PlanOptions.RAVCreditHire || cancellationDetails.cancellationPlanType === PlanOptions.CancellationShareCover) {
            updateFormState(UserFormStates.cancellationDetails, { noticePeriodCharge: 0 })
            updateFormState(UserFormStates.cancellationDetails, { totalAcceptanceRemarketingFee: 0 })
            updateFormState(UserFormStates.cancellationDetails, { phCharges: 0 })
            updateFormState(UserFormStates.cancellationDetails, { totalCancellationCharges: newAncillaryCharges });

        }
    }, [chargesDetails, cancellationDetails.totalAcceptanceRemarketingFee, cancellationDetails.minimumPeriodCharge, cancellationDetails.noticePeriodCharge, tableData]);

    useEffect(() => {
        if (chargesLoading === false) {
            updateFormState(UserFormStates.cancellationDetails, { noticePeriodCharge: parseFloat(chargesDetails.noticePeriodCharges) })
            updateFormState(UserFormStates.cancellationDetails, { dailySubscriptionFee: parseFloat(chargesDetails.dailyFee) })
            updateFormState(UserFormStates.cancellationDetails, { minimumPeriodCharge: parseFloat(chargesDetails.minimumTermCharges) })
            updateFormState(UserFormStates.cancellationDetails, { totalAcceptanceRemarketingFee: parseFloat(chargesDetails.minimumTermCharges) })
            updateFormState(UserFormStates.cancellationDetails, { weeklySubscriptionFee: parseFloat(chargesDetails.weeklyFee) })
            updateFormState(UserFormStates.cancellationDetails, { vehicleDepreciationFee: chargesDetails.usageCharge })
            updateFormState(UserFormStates.cancellationDetails, { acceptanceAndRemarketing: chargesDetails.adminFee })
            updateFormState(UserFormStates.cancellationDetails, { state: chargesDetails.state })
            if (cancellationDetails.cancellationPlanType == PlanOptions.CancellationFlexiOwn) {
                updateFormState(UserFormStates.cancellationDetails, { phCharges: (chargesDetails.phTotalCharge) })
            }

            // set all notice, minimum period and payment holiday charges to 0 as they do not apply to RAV Credit Hire and Sharecover plans
            if (ravPlanTypes.includes(cancellationDetails.cancellationPlanType as PlanOptions)) {
                updateFormState(UserFormStates.cancellationDetails, { noticePeriodCharge: 0 })
                updateFormState(UserFormStates.cancellationDetails, { phCharges: 0 })
                let ancillaryChargesNumber = ancillaryCharges.toFixed(cancellationRounding)
                updateFormState(UserFormStates.cancellationDetails, { totalCancellationCharges: Number(ancillaryChargesNumber) })
                if (cancellationDetails.cancellationPlanType !== PlanOptions.RAVShortTermRental){
                    updateFormState(UserFormStates.cancellationDetails, { totalAcceptanceRemarketingFee: 0 })
                }
            }
        }
    }, [chargesDetails])

    const handleSelection = (selectedOptions: TableRow) => {
        // Logic to handle the selected options and update tableData
        setTableData((prevData) => [...prevData, selectedOptions]);
    };

    const handleRemoveRow = (index: number) => {
        // Logic to remove a row from the tableData
        setTableData((prevData) => {
            const newData = [...prevData];
            newData.splice(index, 1);
            return newData;
        });
    };

    const buttonContainerStyle = {
        marginTop: '5vh',
    };

    const collectSelectedAddons = (tableData: any) => {
        const extractedData = tableData.map((row: { item: any; quantity: any, unit: any }) => {
            const addon = addonsDetails.find((addon: { name: any; id: any }) => addon.name === row.item);
            const unitPrice = !isNaN(row.unit) && row.unit !== null && row.unit !== undefined
                ? row.unit * 100
                : 0;
            if (addon) {
                return {
                    addon_id: addon.id,
                    addon_description: addon.name,
                    addon_quantity: row.quantity,
                    addon_unit_price: unitPrice,
                    addon_total: unitPrice * row.quantity,
                    addon_pricing_model: addon.pricingModel
                };
            } else {

                return null;
            }
        });

        // Filter out potential null values (addons not found)
        let validData = extractedData.filter((data: any) => data !== null);
        return validData;
    }

    const handleButtonClick = () => {
        updateFormState(UserFormStates.cancellationDetails, { addons: collectSelectedAddons(tableData) })
        navigate(`/cancellation/${driverDetails.country}/${contractDetails.subscriptionId}/review`)
    }

    const cardStyle = {
        card: {
            border: '1px solid #ccc',
            borderRadius: '5px',
            marginBottom: '20px',
            padding: '20px',
            backgroundColor: '#fff',
        },
        header: {
            marginBottom: '10px',
            fontWeight: 'bold',
        },
    };

    const AcceptanceAndRemarketingCharges: React.FC<AcceptanceAndRemarketingChargesProps> = ({ chargesDetails, driverDetails, handleAcceptanceRemarketingChange }) => (
        <>
            <div style={cardStyle.header}> Acceptance and remarketing fee and vehicle deprecation fee charges</div>
            <div style={cardStyle.card}>
                <p>Please confirm <strong>acceptance and remarketing fee and vehicle depreciation fee.</strong></p>

                <UsageCappedInfoNote />
                {renderChargeSection(`Starting km`, chargesDetails.startOdometer)}
                {renderChargeSection(`Ending km`, chargesDetails.finishOdometer)}
                {renderChargeSection(`Distance travelled (km)`, chargesDetails.drivenDistance)}
                {renderChargeSection(`Usage charge (${countrySpecificCurrency(driverDetails.country)})`, chargesDetails.usageCharge)}
                <div style={{ margin: '2.5vh 0vw' }}>
                    {renderChargeSection(`Acceptance and remarketing fee (${countrySpecificCurrency(driverDetails.country)})`, chargesDetails.adminFee)}
                </div>
                <p>Total acceptance and remarketing and vehicle depreciation charges ($)</p>
                <div style={{ margin: '2.5vh 0vw' }}>
                    <NumericInput
                        name="acceptance"
                        defaultVal={cancellationDetails.totalAcceptanceRemarketingFee}
                        required={true}
                        onChange={handleAcceptanceRemarketingChange}
                        dataTestId="acceptanceInput"
                        style={textBoxStyle}
                    />
                </div>
            </div>
        </>
    );

    const PaymentHolidayCharges: React.FC<PaymentHolidayChargesProps> = ({ chargesDetails, driverDetails }) => (
        <>
            <div style={cardStyle.header}> Payment Holiday Charges</div>
            <div style={cardStyle.card}>
                {renderChargeSection(`Total weeks taken`, chargesDetails?.phWeeksTaken)}
                {renderChargeSection(`Total payment holiday contribution`, chargesDetails?.phTotalContribution)}
                <div style={{ margin: '2.5vh 0vw' }}>
                    {renderChargeSection(`Payment holiday charge (${countrySpecificCurrency(driverDetails.country)})`, chargesDetails.phTotalCharge)}
                </div>
            </div>
        </>
    );

    // Define the new component
    const MinimumPeriodCharges: React.FC<MinimumPeriodChargesProps> = ({ chargesDetails, driverDetails, handleMinimumPeriodChargeChange }) => (
        <>
            <div style={cardStyle.header}> Acceptance and remarketing fee and vehicle deprecation fee charges</div>
            <div style={cardStyle.card}>
                <p>Please confirm <strong>acceptance and remarketing fee and vehicle depreciation fee.</strong></p>
                <div style={{ margin: '2.5vh 0vw' }}>
                    <p>Please confirm <strong>minimum period charges.</strong></p>
                </div>
                {renderChargeSection(`Weekly subscription fee (${countrySpecificCurrency(driverDetails.country)})`, chargesDetails.weeklyFee)}
                {renderChargeSection(`Daily subscription fee (${countrySpecificCurrency(driverDetails.country)})`, chargesDetails.dailyFee)}
                {renderChargeSection('# days charged for minimum period breach', chargesDetails.minimumDaysToCharge)}
                <div style={{ margin: '2.5vh 0vw' }}>
                    <div>
                        <Label text={`Minimum period charge (${countrySpecificCurrency(driverDetails.country)})`} styleCfg={{ bold: true }} />
                    </div>
                    <NumericInput
                        name="minimum period"
                        defaultVal={cancellationDetails.acceptanceAndRemarketing}
                        required={true}
                        onChange={handleMinimumPeriodChargeChange}
                        dataTestId="minPeriodInput"
                        style={textBoxStyle}
                    />
                </div>
            </div>
        </>
    );

    const NoticePeriodCharges: React.FC<NoticePeriodChargesProps> = ({ chargesDetails, driverDetails, handleNoticePeriodChargeChange }) => (
        <>
            <div style={cardStyle.header}> Notice period charges</div>
            <div style={cardStyle.card}>
                <div style={{ margin: '2.5vh 0vw' }}>
                    <p>Please confirm <strong>notice period charges.</strong></p>
                </div>
                {renderChargeSection(`Weekly subscription fee (${countrySpecificCurrency(driverDetails.country)})`, chargesDetails.weeklyFee)}
                {renderChargeSection(`Daily subscription fee (${countrySpecificCurrency(driverDetails.country)})`, chargesDetails.dailyFee)}
                {renderChargeSection('# days charged for notice period breach', chargesDetails.noticeDaysToCharge)}
                <div style={{ margin: '2.5vh 0vw' }}>
                    <div>
                        <Label text={`Notice period charge (${countrySpecificCurrency(driverDetails.country)})`} styleCfg={{ bold: true }} />
                    </div>
                    <input
                        style={textBoxStyle}
                        type="number"
                        required
                        data-testid="notice-period-charge-input"
                        value={cancellationDetails.noticePeriodCharge}
                        onChange={handleNoticePeriodChargeChange}
                    />
                </div>
            </div>
        </>
    );


    return (
        <>
            <div style={{ margin: '2.5vh 0vw' }}>
                <NavBar pageTitle="Cancellation charges" />
            </div>
            {addonsLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    {ravPlanTypes.includes(cancellationDetails.cancellationPlanType as PlanOptions) ? (
                        <>
                            <div>
                                <p>Please add any <strong>extra ancillary charges (e.g. damage).</strong></p>

                                <DynamicTable data={tableData} onRemoveRow={handleRemoveRow} />

                                {ancillaryCharges > 0 && (
                                    renderChargeSection(`Total ancillary charges (${countrySpecificCurrency(driverDetails.country)})`, ancillaryCharges.toFixed(cancellationRounding))
                                )}
                                <div style={{ margin: '2.5vh 0vw' }}>
                                    <Button
                                        label="Add ancillary charge"
                                        onClickFunc={() => setIsModalOpen(true)}
                                        btnType={ButtonStyle.secondary}
                                        testId='open-modal-button'
                                    />
                                </div>

                                {isModalOpen && (
                                    <DropDownModal onClose={() => setIsModalOpen(false)} options={addonsDetails} onSelection={(selectedOptions: TableRow) => handleSelection(selectedOptions)} />
                                )}
                            </div>
                            {cancellationDetails.cancellationPlanType === PlanOptions.RAVShortTermRental &&
                                <MinimumPeriodCharges
                                    chargesDetails={chargesDetails}
                                    driverDetails={driverDetails}
                                    handleMinimumPeriodChargeChange={handleMinimumPeriodChargeChange}
                                />
                            }
                        </>
                    ) : (<>
                        <div>
                            <p>Please add any <strong>extra ancillary charges (e.g. damage).</strong></p>

                            <DynamicTable data={tableData} onRemoveRow={handleRemoveRow} />

                            {ancillaryCharges > 0 && (renderChargeSection(`Total ancillary charges (${countrySpecificCurrency(driverDetails.country)})`, ancillaryCharges))}
                            <div style={{ margin: '2.5vh 0vw' }} >
                                <Button
                                    label="Add ancillary charge"
                                    onClickFunc={() => setIsModalOpen(true)}
                                    btnType={ButtonStyle.secondary}
                                    testId='open-modal-button'
                                />
                            </div>

                            {isModalOpen && (
                                <DropDownModal onClose={() => setIsModalOpen(false)} options={addonsDetails} onSelection={(selectedOptions: TableRow) => handleSelection(selectedOptions)} />
                            )}

                            <>
                                <NoticePeriodCharges
                                    chargesDetails={chargesDetails}
                                    driverDetails={driverDetails}
                                    handleNoticePeriodChargeChange={handleNoticePeriodChargeChange}
                                />
                            </>

                            {cancellationDetails.cancellationPlanType !== PlanOptions.CancellationFlexiOwn && (
                                <>
                                    <MinimumPeriodCharges
                                        chargesDetails={chargesDetails}
                                        driverDetails={driverDetails}
                                        handleMinimumPeriodChargeChange={handleMinimumPeriodChargeChange}
                                    />
                                </>
                            )}

                            {cancellationDetails.cancellationPlanType == PlanOptions.CancellationFlexiOwn && (
                                <>
                                    <AcceptanceAndRemarketingCharges
                                        chargesDetails={chargesDetails}
                                        driverDetails={driverDetails}
                                        handleAcceptanceRemarketingChange={handleAcceptanceRemarketingChange}
                                    />

                                    <PaymentHolidayCharges
                                        chargesDetails={chargesDetails}
                                        driverDetails={driverDetails}
                                    />
                                </>

                            )}

                            <div style={{ margin: '2.5vh 0vw', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div>
                                    <Label text={`Early cancellation charges (${countrySpecificCurrency(driverDetails.country)})`} styleCfg={{ bold: true }} />
                                </div>
                                <p style={{ margin: 0 }}>{cancellationDetails.totalEarlyCancellationCharges.toFixed(cancellationRounding) || 0}</p>
                            </div>

                            <div style={{ borderTop: '2px solid #000', padding: '10px 0', margin: '2.5vh 0vw' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>
                                        <Label text={`Total extra charges (${countrySpecificCurrency(driverDetails.country)})`} styleCfg={{ bold: true }} />
                                    </div>
                                    <p style={{ margin: 0 }}>{totalCharges || 0}</p>
                                </div>
                            </div>

                        </div ></>
                    )}

                    <div style={buttonContainerStyle}>
                        <Button label={"Next"} onClickFunc={handleButtonClick}></Button>
                    </div>
                </>
            )}
        </>
    );

}



