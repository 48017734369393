import React, { createContext, useContext, useState, useCallback } from "react";


/**
 * ErrorDetails interface. Allowing customisation depending on the needs.
 * - msg: text message set upon error; could be used to be displayed to the
 * user or as debug
 * - UI: React component that can be used to display an already configured
 * UI item.
 */
interface IErrDetails {
  msg: string,
  UI: React.ReactNode
};


/**
 * Err interface. Storing a the error state separately so a boolean flag would
 * indicate if there is an error and the details will provide additional info.
 * - details: see type definition above
 * - showDetails: boolean, meant to be used to whether show or not what's in
 * details
 * - state: boolean, storing the error state. True means there is an error that
 * has not been acknowledged yet; False means there is no error
 */
interface IErr {
  details: IErrDetails,
  showDetails: boolean,
  state: boolean,
};


/**
 * Interface defining the Server Error Context.
 * - errDetails: see interfaces above for details
 * - setErr: function that sets the details of the error (and implicitly the error state to true);
 *           shall be used in those locations where the server calls failures are caught
 *           and handled
 * - removeErr: function that resets the error (in other words clears the error state); shall
 *              be used in those location where the error has been treated/acknowledged
 */
interface ISrvErrContext {
  err: IErr
  setErr: (showDetails: boolean, errDetails: IErrDetails) => void
  removeErr: () => void
};


/**
 * Default values should always indicate a no error state.
 * - state: boolean state to indicate whether there is an error or not
 * - msg: text details about the error; could be the cause, user indications, etc.
 *     - could be developed into something more detailed
 *
 * IMPORTANT: Keep in mind that the messages are intended to be shown to the user
 */
const defaultErrContext: IErr = {
  state: false,
  showDetails: false,
  details: { msg: '', UI: null },
};


export const SrvErrContext = createContext<ISrvErrContext>({
  err: defaultErrContext,
  setErr: (showDetails: boolean, errDetails: IErrDetails) => { },
  removeErr: () => { }
});


interface ISrvErrorCtxProvider {
  children: React.ReactNode
};


/**
 * Context provider which wraps a child component, so the error state and info getter and setter
 * are accessible from it.
 *
 * The error state is evaluated to whether display or not the UI component that's added at the
 * stage of setting the error state. So if the state is True, denoting some server request has
 * failed, then the UI is shown. Otherwise, nothing happens. The UI component is rendered as is,
 * so it's the responsibility of the one that sets the error state to provide a pre-configured
 * UI component (especially from a styling perspective). The removeErr function shall be made
 * accessible to the user or called automatically, depending on the use case, so the UI component
 * will be removed/hidden.
 *
 * The 'value' provided by the context exposes 3 items:
 * - the error state holding information about whether there has been or not an error that
 *   wasn't acknowledged yet and some details about the error (cause or future steps for the user)
 * - setErr function to set the error state and details; by default sets the boolean state to true
 *   since it's meant to be used only in those cases when there has been an error. Besides this,
 *   it saves the details of the error (received as params).
 * - removeErr function to reset the error state to the default; meant to be used from those locations
 *   where the error has been acknowledged
 *
 * @param param0 children component to which the context is provided
 * @returns
 */
export default function SrvErrorCtxProvider({ children }: ISrvErrorCtxProvider) {
  const [err, setErr] = useState<IErr>(defaultErrContext);

  const contextValue = {
    err,
    setErr: useCallback((showDetails: boolean, details: IErrDetails) => { setErr({ state: true, showDetails, details }); }, []),
    removeErr: useCallback(() => { setErr(defaultErrContext); }, []),
  };

  return (
    <SrvErrContext.Provider value={contextValue}>
      {children}
      {err.state && err.showDetails && err.details.UI}
    </SrvErrContext.Provider>
  );
};


// Small hook wrapper to expose the ServerErrorContext
// under a more suggestive name
export const useAPIError = () => {
  return useContext(SrvErrContext);
};
