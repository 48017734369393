import { Country } from "@utils/constants/localisation";
import { IMSTokenParams, getWithIDToken } from "@utils/http/MSAuthAPI";


export interface IPlan {
    id: string
    name: string
    weeklyHireFee: number,
    minimumPeriodWeeks: number,
    weeklyDistAllowance: number,
    additionalDistCostPerUnit: number,
    setupFee: number
};


interface IPlanService {
    getPlans(qParams: object, country: Country, IDTokenParams: IMSTokenParams): Promise<IPlan[]>;
};

export const PlanService: IPlanService = {
    async getPlans(qParams: object, country: Country, IDTokenParams: IMSTokenParams): Promise<IPlan[]> {
        const resp = await getWithIDToken(
            `/v1/plans`,
            IDTokenParams,
            { ...qParams, country}
        );

        return resp.data.map((item: any) => ({
            id: item.id,
            name: item.name,
            weeklyHireFee: item.weekly_hire_fee,
            minimumPeriodWeeks: item.minimum_period_weeks,
            weeklyDistAllowance: item.weekly_distance_allowance,
            additionalDistCostPerUnit: item.additional_dist_cost_per_unit,
            setupFee: item.setup_fee
        }));
    },
};
