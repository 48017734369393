import { EnvTag, getEnvTag } from '@utils/AppEnv';

/**
 * requestTimeout: variable which indicates how many seconds to wait for the backend's response, until
 *  a timeout error is raised.
 */
type seconds = number;
export const requestTimeout: seconds = 30.1;  // seconds; backend's default timeout is 30sec

/**
 * Keeping the URL constants here rather than in AppEnv because the constants
 * themselves are not being re-used anywhere else.
 * Not exporting them either because the backendURL is being re-used elsewhere
 * rather than these constants.
 */
const prodServerURL = 'https://bdi93iz8a4.execute-api.eu-west-1.amazonaws.com/production';
const devServerURL = 'https://bo9zodzds6.execute-api.eu-west-1.amazonaws.com/dev';

let backendURL = '';
switch (getEnvTag()) {
    case EnvTag.Production:
        backendURL = prodServerURL;
        break;
    case EnvTag.Development:
    case EnvTag.Local:
        backendURL = devServerURL;
}

export { backendURL };
