import React from "react";

import Box from "./Box";
import CheckIcon from '@assets/images/check_mark.svg';


interface IConfirmationBox {
    message: string
    testId?: string
};


export default function ConfirmationBox({ message, testId }: IConfirmationBox) {
    return <Box message={message} icon={CheckIcon} bgColor={"#BEF8BD"} testId={testId} />
};
