import React from "react";

import Box from "./Box";
import WarningIcon from '@assets/images/warning_icon.svg';


interface IWarningBox {
    message: string|JSX.Element
};


export default function WarningBox({ message }: IWarningBox) {
    return <Box message={message} icon={WarningIcon} bgColor={"#FFF4D4"} />
};
