import React from 'react';
import { useParams, useNavigate } from "react-router-dom";

import SimpleMenu from '@components/navigation/SimpleMenu/SimpleMenu';


export default function BillingAndPaymentsMenuView() {
    const { custId, country } = useParams() as { custId: string; country: string; };
    const navigate = useNavigate();

    return (
        <SimpleMenu
            name='billing-and-payments'
            menuItems={[
                {
                    label: 'Payment holiday',
                    onClickFunc: () => navigate(`/payment-holiday/${country}/${custId}`)
                },
                {
                    label: 'Payment plan',
                    isDisabled: true
                },
                {
                    label: 'Update payment method',
                    isDisabled: true
                },
                {
                    label: 'Collect payment on invoices',
                    isDisabled: true
                },
            ]}
        />
    );
};
