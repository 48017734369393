import React, { CSSProperties } from 'react';


export type LabelProps = {
    label?: string,
    testId?: {[item: string]: string},
};


export default (props: LabelProps) => {
    const style: CSSProperties = {
        margin: '0 2px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    };

    return (
        <span style={style} data-testid={props.testId?.label || 'label'}>
            {props.label || 'NOT SET'}
        </span>
    );
};
